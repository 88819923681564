@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&amp&display=swap;subset=vietnamese);
@import url(https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap;);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
.react-datepicker-wrapper input {
    width: 100%;
}
.rxselectbox-wrap {
    position: relative;
    width: calc(100% - 150px);
    display: inline-block;
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #f8f8f8;
}
.rx-form__item .rxselectbox-wrap {
    background-color: #fff;
}

.rxselectbox-filterinput {
    padding: 0 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: 90% !important;
}

.rxselectbox-toggleholder {
    position: relative;
    width: 100%;
    height: 235px;
}

.rxselectbox-toggle {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.2);
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    /*min-height: 150px;*/
    z-index: 10001;
    /*display: none;*/
    max-height: 300px;
    overflow-y: auto;
}

.rxselectbox-wrap:hover .rxselectbox-toggle {
    /*display: block;*/
}

.rxselectbox-toggle>div {
    padding: 10px 10px;
    cursor: pointer;
}

.rxselectbox-toggle>div:first-child {
    cursor: pointer;
}

.rxselectbox-filter input {
    width: 100%;
    margin: 0px;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: 100% -21px !important;
}

.rxselectbox-toggle>div:hover {
    background-color: #2196F3;
    color: #fff;
}

.rxselectbox-optionholder {
    height: 230px;
    overflow-y: scroll;
}

.rxselectbox-optionitem {
    padding: 10px;
    width: 100%;
    border-bottom: solid 1px #dfdfdf;
}

.rxselectbox-toggle>div:first-child:hover {
    background-color: #ffffff;
    color: #000;
}

.rxselectbox-filter {
    /*position: absolute;*/
    left: 0px;
    /*top: -37px;*/
    width: 100%;
    /*display: none;*/
    position: relative;
}

.rxselectbox-result {
    min-height: 33px;
    /*background-color: #fff;*/
    /*border: 1px solid #eee;*/
    /*margin-top: 5px;*/
    margin-bottom: 0;
    /* padding: 10px 5px; */
    padding-bottom: 0px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
}

.rxselectbox-result>div, span.tags {
    /*margin-right: 5px;*/
    /* margin-bottom: 5px; */
    padding: 13px 10px;
    padding-right: 30px;
    float: left;
    /*border: 1px solid #eee;*/
    border-radius: 3px;
    cursor: pointer;
    /*background-color: #fff;*/
    position: relative;
}

.rxselectbox-result>div>b, a.tagsremove {
    padding: 1px 4px;
    margin-left: 3px;
    border-radius: 15px;
    background-color: #000000;
    color: #fff;
    font-weight: 100;
    display: inline-block;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    text-align: center;
    margin-top: 0px;
    position: absolute;
    top: 3px;
    right: 3px;
    line-height: 10px;
    font-size: 9px;
}

.rxselectbox-resultempty {
    position: absolute;
    top: 13px;
    left: 15px;
    font-style: italic;
    color: #aaa;
}
.rx-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.rx-dropdown {
    /*float: right;*/
    position: absolute;
    right: 0;
    margin: 11px;
    font-size: 20px;
}
.rx-dropdown:before {
    color: #000 !important;
}
.rx-search-icon {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #cccccc;
}
.rx-dropdown>span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: 0px 5px;
}
.rx-dropdown.up>span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: -18px 3px;
}
.rx-no-result {
    background-color: #fcf8e3;
    color: #8a6d3b;
}
.rxselectbox-result>div, .rxselectbox-toggle>div {
    color: #000;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}
.margin-15 {
    margin: 0 -15px;
}
.margin-10 {
    margin: 0 -10px;
}
.betable__filter .rxselectbox-wrap {
    min-width: 100%;
}

.rxcheckbox-wrap {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
}
.checkbox__toggle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.checkbox__item {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.checkbox__item input {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.rs-checkbox-payment-small {
  width: 20px;
  height: 20px;
  border: 1px solid #aaa;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}
.rs-checkbox-payment-small.active {
  background-color: #2869b5;
}
.rs-checkbox-payment-small.unactive {
    background-color: #be3e3f;
}
.rs-checkbox-payment-small.active>span, .rs-checkbox-payment-small.unactive>span {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}
.rx--box__search {
    position: relative;
}
.checkbox__filter {
  margin-bottom: 20px;
}
.checkbox__filter input {
  width: 200px;
}
.checkbox__filter>span {
  left: 250px;
}
.checkbox__item:last-child {
    border-right: 1px solid #ddd;
}
.rx--box__header {
    display: flex;
    align-items: center;
}
.rx--box__import {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.rx--box__import input {
    margin-top: 0 !important;
}
.rx--box__search .searchbox__toggle-icon {
    right: 10px;
    left: unset;
}
.addetail_card {
    padding-top: 70px;
    position: absolute;
    width: calc(100% - 270px);
    /* height: 100%; */
    z-index: 10001;
}
.addetail_card .adform__footer .adform__btns {
    width: calc(100% - 330px);
}
.rx-inline {
    float: left;
}
.rxSelectbox__addbtn {
    color: #fff;
    text-align: center !important;
    margin: 0 10px;
    justify-content: center;
}
.rxtoggle__phase.rx-fixed {
    background-color: rgba(0,0,0,.8);
    z-index: 10001;
    display: flex;
    align-items: center;
}
.rxtoggle__phase--body {
    height: 70%;
    width: 70%;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    max-width: 90%;
    margin: auto;
    padding: 30px;
}
.rxtoggle__phase--body .actions {
    position: absolute;
    bottom: 50px;
    right: 30px;
}
.rxtoggle__phase--body .rx-crud__form>div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.rxtable__card {
    padding: 20px;
    background-color: #f8f8f8;
    display: block !important;
}
.rxtable__card span:first-child {
    padding-right: 15px;
}
.rxtable__card table {
    border: 1px solid #eee;
}
.rxtable__card table tbody {
    border-bottom: unset;
}


.rx-cursor.rxactive {
    background-color: #03A9F4 !important;
    color: #fff;
}
.text-left-fix-with {
  width: 400px;
  display: inline-block;
}
.button_right {
  float: right !important;
}
.rxnote { color: red; font-style: italic;}
.rxform--province {
  width: 100%;
  display: flex;
  align-items: center;
}
.adform.top-0 {
  top: 0;
}
.proges-text {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 500;
}
.proges-total {
  font-size: 15px;
  padding-top: 10px;
}
.adform__btn--save:last-child {
    margin-right: 0px;
}
.adform__btn--save {
    margin-right: 10px;
    font-size: 15px;
}
.betable__print {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.betable__print>div {
  margin-right: 15px;
}
/* .betable__print>div:last-child {
  margin-left: 15px;
} */
.betable__print>div {
  /*display: flex;
  align-items: center;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 30%;
  padding-top: 10px;
}
.betable__print .react-datepicker-wrapper {
    width: 200px;
    margin: auto;
}
.betable__print>div>div {
  margin-right: 10px;
  font-size: 15px;
}
.select--province {
  margin-left: 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  min-height: 42px;
  border-radius: 4px;
}
.toast-container {
    position: fixed;
    z-index: 999999;
}
.toast-top-right {
    top: 70px;
    right: 12px;
}
.betable__inner td.active {
    /* background-color: #4CAF50; */
    text-align: left;
    vertical-align: middle;
    color: #4CAF50;
    font-weight: bold;

}
.icon__download {
    position: absolute;
    right: 50px;
    top: 10px;
    font-size: 28px;
    cursor: pointer;
    color: #ffffff;
    z-index: 2;
    border-radius: 3px;
}
.img__multi--content {
  display: flex;
  justify-content: center;
  position: relative;
}
.img__multi--content img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.betable__inner td.unactive {
    text-align: left;
    vertical-align: middle;
    color: red;
    font-weight: bold;
    /* background-color: #FFEB3B; */
}
.rxtext__history {
  cursor: pointer;
}
.rxcrud__toggle--item {
    border-bottom: 1px solid #eee;
    position: relative;
    width: 100%;
}
.toggle__item--title {
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #eee;
    padding: 5px 10px;
}
.toggle__item--title span {
    right: 10px;
    position: absolute;
}
.rxcrud__toggle {
    display: block !important;
    max-height: 500px;
    overflow-y: auto;
}
.toggle__item--desc {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: left;
    padding-bottom: 10px;
}
th.rx-th-width-220 {
    min-width: 100px;
}
.toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 2px;
    background-position: 15px;
    background-repeat: no-repeat;
    color: #fff;
    opacity: .9;
    filter: alpha(opacity=90);
}
.toast {
    background-color: #20a8d8;
    max-width: 350px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
}
.toast-title {
    font-weight: 700;
    padding: 10px 0;
}
.toast-message {
    word-wrap: break-word;
}
.adform__btnedit.adform__btnedit--raw {
  min-height: 42px;
  padding: 15px 30px;
  border-radius: 5px;
}
span.icon-bellnum {
    background-color: #e91e63;
    border-radius: 50%;
    font-size: 20px;
    width: 35px;
    height: 35px;
    position: absolute;
    line-height: 0;
    padding: 18px 0px;
    top: 0;
    color: #fff;
}
.member {
    min-width: 200px;
}
.noti__menu__tab .title {
    padding: 10px 10px;
    background-color: #f0f3f5;
}
.noti__menu__name {
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    text-align: center;
    display: initial !important;
    /*border-bottom: 1px solid #eee;
    padding-bottom: 10px;*/
}
.noti__menu__tab {
    font-weight: bold;
    padding: 0 15px !important;
}
.noti__menu__tab span {
    text-align: right;
    float: right;
    color: red;
}
.dropitem {
    align-items: center;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    color: #333;
    padding: 5px 0;
    position: relative;
}
.dropitem>span {
    color: #333;
    float: unset;
}
.dropitem>span {
    text-align: left;
}
.dropitem .datetime {
  position: absolute;
  right: 0;
}
.dropitem>span {
    color: #333;
}
.noti__menu__tab__item {
    border: 1px solid #eee;
    padding: 10px 10px;
    max-height: 150px;
    overflow: scroll;
}
.dropitem:hover {
  background-color: #f0f3f5;
  font-weight: bold;

}

.meter__body__ {
  padding: 15px;
  background: #fff;
}
.meter__icon {
  display: inline-block;
  max-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  padding: 10px;
  font-size: 25px;
}
.meter__icon.icon1 {
  border: solid 1px #6658dd;
  background-color: rgba(102, 88, 221, 0.25);
}
.meter__icon.icon2 {
  border: solid 1px #1abc9c;
  background-color: rgba(26, 188, 156, 0.25);
}
.meter__icon.icon3 {
  border: solid 1px #4fc6e1;
  background-color: rgba(79, 198, 225, 0.25);
}
.meter__icon.icon4 {
  border: solid 1px red;
  background-color: #fc7b7b;
}
.meter__icon.icon5 {
  border: solid 1px #f7b84b;
  background-color: rgba(247, 184, 75, 0.25);
}
.meter__content {
  display: inline-block;
  text-align: right;
  padding-left: 15px;
  float: right;
}
.meter__footer {
  padding: 12px 25px;
  color: #000;
  font-size: 22px;
  vertical-align: middle;
  align-items: center;
}

.adnav__itemname.adnav__itemname_lv2>a {
  padding: 15px 75px;
}
.adnav__itemname.adnav__itemname_lv3>a {
  padding: 15px 100px;
}
.col-md-2.col-cus-md2 {
  width: 20%;
}
input.betable__findinput.col-md-3 {
  min-height: 42px;
}
select.betable__findinput.col-md-3 {
  min-height: 42px;
}
span.news_inputcheck_radio input {
  width: 50px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
}
.betable__status.betable__status_6 {
  background-color: #bc2b41;
  color: #fff;
}
.betable.adblock__inner .fixed {
  position: fixed;
  top: 70px;
  left: 275px;
  z-index: 1111;
  width: calc(100% - 275px);
  bottom: 0;
}
/*.betable.adblock__inner .fixed .modal {
  margin-left: -200px;
}*/
/* adblock */
.adblock {
  /*background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);*/
  position: relative;
  padding-top: 70px;
}
.form-header-title {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.text-center {
  text-align: center !important;
  /*padding: 8px;*/
  line-height: 1.4;
  font-weight: normal;
  font-size: 16px;
}
.text-left {
  text-align: left;
  line-height: 1.4;
  font-weight: normal;
}
.text-left b {
  font-size: 18px;
  font-weight: bold;
}
.custom-search .fullwidth-input {
  height: 40px;
}
input#import-excel {
  /* margin-top: 15px; */
  padding: 5px;
  width: 100%;
}
span#buttonSubmitCsv {
  padding: 5px 11px;
  margin-left: 10px;
}
.adblock__head {
  padding: 12px 30px;
  padding-bottom: 0;
  /*background-color: #fafafb;*/
}
.adblock__body {
  padding: 30px;
}
.adblock__body--reducepad {
  padding: 30px 5px;
}
.adblock__inner {
  margin-left: 0px !important;
  margin-right: 0px !important;
  /*box-shadow: 0 1px 5px rgba(0,21,41,.1);
  background-color: #fff;*/
  position: relative;
  /*padding: 30px;*/
}
.adblock__inner-dashboard {
  background-color: transparent;
}
.adblock__title {
  font-size: 22px;
  margin-top: 7px;
  font-weight: bold;
  /*text-transform: capitalize;*/
}
.adblock__desc {
  font-weight: 100;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 7px;
  color: #cacaca;
  margin-top: 10px;
}
.adblock__dashboardfake {
  width: 100%;
  display: block;
}
.adblock__sumblock {
  min-height: 200px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}
.sumblock__title {
  color: #bec0cc;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
}
.sumblock__title span {
  float: right;
  border: solid 1px;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  padding: 7px 0;
  line-height: 0px;
  box-sizing: border-box;
  position: absolute;
  right: 10px;
  top: 18px;
}
.sumblock__num {
  font-size: 25px;
  margin-top: 20px;
  color: #000;
}
.sumblock__num i {
  font-size: 20px;
  margin-left: 3px;
  color: #bec0cc;
}
.sumblock__subnumlabel {
  position: absolute;
  bottom: 48px;
  color: #cacaca;
}
.sumblock__subnum {
  position: absolute;
  bottom: 15px;
  line-height: 1.5;
}
.sumblock__subnum span {
  background-color: #eee;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 5px;
}
.sumblock__percent {
  position: absolute;
  float: right;
  right: -1px;
  margin-top: -25px;
  font-size: 17px;
  color: #000;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
  font-weight: bold;
  background-color: #e7e9ec;
}
.sumblock__lefttitle div {
  float: left;
  padding: 0 15px 15px 0px;
}
.sumblock__righttitle div {
  float: right;
  padding: 0 10px 15px 11px;
  cursor: pointer;
}
.sumblock__righttitle div.active {
  border-bottom: solid 3px;
  font-weight: bold;
}
.sumblock__wraptitle {
  width: 100%;
  border-bottom: solid 1px #ddd;
  margin-bottom: 15px;
}
.sumblock__charttitle {
  font-weight: bold;
  margin-top: 15px;
}
.sumblock__chartwrap {
  width: 100%;
  height: 200px;
  margin-top: 25px;
}
.sumblock__chartopitem {
  line-height: 28px;
  color: #888;
  padding-left: 30px;
}
.sumblock__chartopitem.active {
  color: #000;
}
.sumblock__chartopitem span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  box-sizing: border-box;
  line-height: 0px;
  display: inline-block;
  position: absolute;
  margin-top: 4px;
  padding: 10px 0px;
  text-align: center;
  left: 15px;
}
.sumblock__chartopitem.active span {
  background-color: #000;
  color: #fff;
}
.sumblock__metertitle {
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: solid 1px #ddd;
}
.sumblock__filterbtn {
  float: right;
  font-weight: normal;
}
.sumblock__filterbtn>span:last-child {
  border-right: solid 1px #ddd;
  border-radius: 0 4px 4px 0;
}
.sumblock__filterbtn>span:first-child {
  border-left: solid 1px #ddd;
  border-radius: 4px 0 0 4px;
}
.sumblock__filterbtn>span {
  padding: 5px 10px;
  margin-left: 0px;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  border-left: solid 1px #ddd;
  font-size: 10px;
}
/* betable */
.betable {
  position: relative;
  padding-bottom: 40px;
}
.betable__filter {
  margin-bottom: 20px;
  display: block;
}
.betable__filter::after {
  content: "";
  clear: both;
  display: table;
}
.betable__main {
  overflow: auto;
  padding: 5px 25px;
  /* max-height: 500px; */
}
.betable__inner {
  width: 100%;
  text-align: left;
}
.betable__inner tbody tr:hover {
  background-color: #e6f7ff;
}
.betable__inner thead {
  background-color: #fff;
  color: #00122b;
  border-top: 1px solid #dee2e6;
  height: 50px;
}
.betable__inner tbody {
  border-bottom: solid 1px #f3f7f9;
}
.betable__inner tbody tr {
  border-top: solid 1px #dee2e6;
}
.betable__inner td, .betable__inner th {
  padding: 10px 5px;
  vertical-align: top;
  line-height: 1.5;
  font-size: 14px;
}
.betable__inner th {
  cursor: pointer;
  padding: 15px 5px;
  font-weight: bold;
  font-size: 16px;
  width: 30px;
  vertical-align: middle;
}
.betable__inner th:last-child {
  text-align: right;
  padding-right: 10px;
}
.betable__inner td:first-child {
  min-height: 55px;
  display: inline-block;
  font-size: 15px;
}
.betable-th {
  text-align: left !important;
  padding-right: 10px;
}

.betable__img {
  width: 30px;
  height: 40px;
  overflow: hidden;
  border-radius: 3px;
  clip-path: circle(50%);
}
.betable__img__ {
  width: 120px;
  height: 60px;
  object-fit: contain;
}
@keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}

.betable__checksuccess {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #8BC34A;
  float: left;
  position: absolute;
  left: 5px;
  margin-top: 35px;
  animation: color_change 1s infinite alternate;
}

.betable__chartstyle {
  min-width: 80px;
}

.betable__servicename {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1890ff;
}
.betable__checkfailed {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E91E63;
  float: left;
  position: absolute;
  left: 5px;
  margin-top: 35px;
}
.betable__checkwrap>small {
  padding-left: 30px;
}
.betable__price {
  color: #E91E63;
  font-weight: bold;
}
.betable__priceold {
  color: #888888;
  margin-left: 5px;
}
.betable__priceold b {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}
.betable__icohot {
  color: #E91E63;
  margin-right: 5px;
}
.betable__iconothot {
  /* margin-right: 38px; */
}
.betable__icoon {
  color: #979ba4;
  padding: 5px 5px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 0px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 100;
}
.betable__icooff {
  color: #00112b;
  padding: 5px 5px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 0px;
  margin-right: 10px;
  margin-left: 10px;
}
.betable__btnedit, .betable__btnDisEdit {
  margin-right: 10px;
  cursor: pointer;
  padding: 1px 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
}
.betable__btndelete {
  margin-right: 10px;
  cursor: pointer;
  padding: 1px 2px;
  border-radius: 2px;
  font-size: 17px;
  line-height: 22px;
}
.betable__findlabel {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 3px;
}
.betable__findinput {
  /*margin-right: 5px;*/
  border: 1px solid #fff;
  padding: 4px 11px;
  font-size: 14px;
  height: 32px;
  border-radius: 4px;
  width: 250px;
  max-width: 100%;
  padding-left: 30px;
  color: rgba(0,0,0,.65);
  background-color: #f0f2f5;
}
.betable__findinput-full {
  width: 100%;
  padding-left: 15px;
}
.betable__findwrap::after {
  content: '\e090';
  clear: both;
  display: table;
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 11px;
  margin-left: 10px;
  font-size: 12px;
}
.betable__btns {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  position: relative;
}
.searchbox__toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.searchbox__toggle>span {
  color: #be3e3e;
  line-height: 1.25;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}
.searchbox__toggle>span:last-child:hover {
  text-decoration: underline;
}
.searchbox__toggle>input {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  width: 200px;
  border-color: #d5d5d5;
}
.searchbox__toggle-icon {
  font-size: 20px;
  position: absolute;
  left: 170px;
}
.betable__addbtn {
  padding-right: 5px;
  cursor: pointer;
}
.betable__recycle {
  padding: 5px;
  cursor: pointer;
}
.betable__pagin {
  position: absolute;
  right: 0;
  top: 0px;
  display: none;
}
.betable__pagin--bot {
  top: auto;
  bottom: -5px;
  display: block;
}
.betable__pagin>div {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 32px;
  height: 30px;
  text-align: center;
  line-height: 0px;
  margin-right: 5px;
  float: left;
  padding: 15px 0px;
  color: rgba(0,0,0,.65);
  box-sizing: border-box;
}
.betable__pagin>div>i {
  line-height: 0px;
}
.betable__pagin>div:last-child {
  margin-right: 0px;
}
.betable__row--active:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 15px solid #00142a;
  position: absolute;
  margin-top: 20px;
  margin-left: -13px;
}
.betable__orderstatus--0 {
  background-color: #FFEB3B;
  padding: 0px 5px;
  white-space: nowrap;
  border-radius: 3px;
}
.betable__orderstatus--1 {
  background-color: #4CAF50;
  padding: 0px 5px;
  white-space: nowrap;
  border-radius: 3px;
  color: #fff;
}
.betable__stagingtime {
  white-space: nowrap;
  font-size: 10px;
  opacity: 0.7;
}
.betable__productiontime {
  white-space: nowrap;
  font-size: 10px;
  opacity: 0.7;
}
/* adform */
.adform {
  position: absolute;
  /*background-color: #ffffff;*/
  width: 100%;
  top: 70px;
  left: 0px;
  /*box-shadow: 0px 0px 25px rgba(0,0,0,0.3);*/
  min-height: 100%;
  z-index: 100;
  overflow: hidden;
  padding: 15px 30px;
  background-color: #f5f4f4;
  min-height: 1500px;
}
.adform__name {
  position: relative;
  padding: 20px 0px;
  font-size: 25px;
  font-weight: 300;
  color: #000000;
  font-weight: bold;
}
.adform__name.fixed {
  position: fixed;
  top: 0px;
  z-index: 1000;
  border-bottom: solid 1px #f2f4f6;
}
.adform__close {
  float: right;
  /* margin-top: -7px; */
  cursor: pointer;
}
.adform__close>span, .adform__close>span>span, .adform__btn--save {
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.adform__btn--close {
  background-color: #838383;
}
.adform__btn--save {
  background-color: #009617;
  line-height: 1.2;
}
.adform__header {
  /* height: 60px; */
}
.adform__body {
  padding: 30px;
  min-height: 600px;
  width: 100%;
  /* max-width: 800px; */
  background-color: #fff;
  border-radius: 20px;
  border: solid 1px rgba(49, 55, 62, 0.1);
  position: relative;
  margin-bottom: 100px;
}
.adform__body, .adform__body:after, .adform__body:before {
  content: " ";
  display: table;
}
.adform__body>div {
  width: 50%;
  float: left;
  padding: 5px;
}
.fullwidth-formdevide {
  width: 100% !important;
  padding: 0px !important;
}
.fullwidth-frominput {
  width: 100% !important;
  display: flex;
  align-items: center;
}
.adform__btns {
  /* width: 100% !important; */
}
.adform__btns.rx-stickybot {
  position: fixed;
  padding: 15px 30px;
  margin-top: 0px;
  background: #fff;
  box-sizing: border-box;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
}
.fullwidth-label {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  min-width: 150px;
  max-width: 150px;
  display: inline-block;
}
.adform__body input {
  width: calc(100% - 150px);
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  line-height: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  transition: all .3s;
  max-width: 100%;
}

.input_2{
width: 100% !important;
}

.adform__body input:hover, .adform__body input:focus {
  border-color: #40a9ff;
}
.adform__btns {
  margin-top: 30px;
  /*height: 30px;*/
}
.adform__btncancel {
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  float: left;
  margin-right: 10px;
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #31373e;
  /*text-transform: uppercase;*/
}
.adform__btnedit {
  border: solid 1px #ddd;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  background-color: #03A9F4;
  color: #ffffff;
  border-color: #03A9F4;
  color: #fff;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 3px;
  /* line-height: 30px; */
  cursor: pointer;
  float: left;
  margin-right: 0px;
}
.adform__btnclone {
  color: #fff;
  background-color: #1890ff;
  border: solid 1px #1890ff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 30px;
  margin-right: 10px;
  cursor: pointer;
  float: left;
  margin-right: 10px;
}

.adblock__btngen {
  border-color: #000;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding-top: 3px;
  display: inline-block;
  color: rgba(255, 0, 0, 0.65);
  background-color: #ffffff;
  border: 1px solid #ff0000;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 16px 10px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 0px;
  margin-right: 10px;
  cursor: pointer;
}

.adform__btnshowper {
  padding: 5px 15px;
  border: solid 1px #ddd;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  width: 200px;
  background-color: #03A9F4;
  color: #ffffff;
  border-color: #03A9F4;
  text-align: center;
}

.adform__flightinfo {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #eeeeee;
  border-radius: 3px;
  max-width: 320px;
}

.adperblock {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #eee;
  height: 100%;
  border-radius: 3px;
}

.adperblock__group {
  margin-bottom: 20px;
}

.adperblock__title {
  font-size: 14px;
  font-weight: bold;
}

.adperblock__peraction {
  font-size: 11px;
  border-radius: 2px;
  padding: 3px;
  margin-right: 5px;
  border: 1px solid #eee;
  margin-top: 5px;
  float: left;
}

.adcheckper {
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border-radius: 3px;
  overflow: hidden;
  height: 200px;
}

.adcheckper__head {
  font-weight: 700;
  font-size: 16px;
  margin: 0px 0;
  display: block;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 15px;
  padding: 13px;
}

.adcheckper__nametext {
  max-width: 100%;
  color: #325;
  float: left;
  margin-top: -1px;
}

.adcheckper__body {
  background-color: #fff;
  color: #333;
  font-size: 12px;
  line-height: 18px;
  height: 140px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 30px;
}

.adcheckper__child {
  margin-top: 4px;
}

.adcheckper__checkedone {
  background-color: #0D47A1;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.adcheckper__checkedone {
  background-color: #0D47A1;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.adcheckper__checkedone_notall {
  background-color: #87b7ff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.adcheckper__checkedall {
  background-color: #0d47a0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.adcheckper__checkcontroller {
  display: block;
  float: left;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  background-color: #fff;
}

.adcheckper__checkaction {
  display: block;
  float: left;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}

.authloginpage {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  margin-top: 100px;
}

.authform {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.authform__head {
  font-size: 28px;
  margin-bottom: 22px;
}

.authform_msgerror {
  color: #E74C3C;
  font-size: 12px;
  padding-bottom: 10px;
}

.authform__input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 3px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.authform__btnsubmit {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #295a93;
  /*background: #2d82cd;
  background: -webkit-linear-gradient(to left, #5B86E5, #36D1DC);
  background: -webkit-gradient(linear, right top, left top, from(#5B86E5), to(#36D1DC));
  background: -webkit-linear-gradient(right, #5B86E5, #36D1DC);
  background: -o-linear-gradient(right, #5B86E5, #36D1DC);
  background: linear-gradient(to left, #5B86E5, #36D1DC); */
  width: 100%;
  border: 0;
  border-radius: 3px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: 100;
  letter-spacing: 5px;
  line-height: 10px;
  font-weight: bolder;
}

.authform__btnsubmit:hover,.authform__btnsubmit:active,.authform__btnsubmit:focus {
  background: #3e6a9d;
  /*background: #36D1DC;
  background: -webkit-linear-gradient(to left, #5B86E5, #36D1DC);
  background: -webkit-gradient(linear, right top, left top, from(#5B86E5), to(#36D1DC));
  background: -webkit-linear-gradient(right, #5B86E5, #36D1DC);
  background: -o-linear-gradient(right, #5B86E5, #36D1DC);
  background: linear-gradient(to left, #5B86E5, #36D1DC);*/
}

.authform__msg {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 13px;
}

/* table element */
.ordertable__detail {
  font-weight: lighter;
  font-size: 11px;
  line-height: 16px;
  opacity: 0.5
}
.ordertable__detail b {
  font-weight: bold;
  color: #E91E63;
  letter-spacing: -1px;
  font-size: 10px;
}

/* */
.fullwidth-input {
  /* width: 200px; */
  border: solid 1px #ddd;
  background-color: #fff;
  padding: 10px;
  height: 25px;
  border-radius: 3px;
  max-width: 100%;
  font-size: 14px;
}
select.fullwidth-input {
  padding: 0px 10px !important;
  width: calc(100% - 150px);
  height: 32px;
}
.adminnav__adname {
  float: right;
  height: 60px;
  padding: 30px 15px;
  font-weight: bold;
  line-height: 0px;
  display: none;
}
.adminnav__avatar {
  /*float: right;
  width: 35px;
  height: 35px;
  background-color: #eeeeee;
  margin-right: 30px;
  margin-top: 12px;
  border-radius: 50%;*/
}
.adminnav__menunoti {
  display: none;
  position: absolute;
  right: 0px;
   width: 600px;
  background-color: #fff;
  top: 70px;
  z-index: 1000;
  min-height: 200px;
  text-align: left;
  border-left: solid 1px #f0f0f0;
  border-bottom: solid 1px #f0f0f0;
  line-height: initial;
}
.adminnav__menunoti.rxactive {
  display: block;
}
.adminnav__menunoti>div {
  padding: 10px 15px;
}
.adminnav__avatarmenu {
  display: none;
  position: absolute;
  right: 0px;
  width: 270px;
  background-color: #fff;
  top: 70px;
  z-index: 1000;
  min-height: 400px;
  text-align: left;
  border-left: solid 1px #f0f0f0;
  border-bottom: solid 1px #f0f0f0;
  line-height: initial;
}
.adminnav__avatarmenu.rxactive {
  display: block;
}
.adminnav__avatarmenu>div {
  padding: 10px 15px;
}
.avatarmenu__fullname {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
}
.avatarmenu__username {
  font-weight: 100;
  color: #888;
  text-transform: capitalize;
}
.avatarmenu__logout {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  bottom: 0px;
  position: absolute;
  padding: 10px 15px;
  background-color: #fafafb;
  color: #333;
}
.avatarmenu__logout i {
  margin-right: 5px;
}
.adminnav__logoimg {
  float: left;
  cursor: pointer;
  height: 50px;
  margin-right: 15px;
  /*margin: 10px 10px 10px 30px;*/
  /*background-color: #010a15;*/
  width: 50px;
}
.adminnav__logoname {
  font-size: 20px;
  font-weight: bold;
  color: #0f68c4;
  text-transform: uppercase;
}
.adminnav__logoimgdesktop {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 15px 35px;
  height: 60px;
}
.adminnav__notifynum {
  width: 16px;
  height: 16px;
  position: absolute;
  margin-left: 10px;
  margin-top: -15px;
  background-color: #E91E63;
  border-radius: 50%;
  line-height: 0px;
  padding: 8px 0;
  text-align: center;
  color: #fff;
  font-size: 11px;
}
.adminnav__language {
  display: none;
}

.order__flightdetail {

}

.order__flightdetail i {
  opacity: 0.4;
}
.order__flightdetail b {
  font-weight: bold;
}
.order__flightico {
  width: 20px;
}
.order__flighticoabs {
  width: 20px;
  position: absolute;
  margin-left: -25px;
}
.order__flighticoabsholder {
  margin-right: 25px;
}

/* chart */
svg.ct-chart-bar, svg.ct-chart-line{
  overflow: visible;
}
#chart1 .ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
}
.betable__errorhistory {
  opacity: 0.7;
  padding: 0px 5px 0 15px;
  white-space: nowrap;
  font-size: 11px;
  overflow-y: auto;
  max-height: 80px;
  line-height: 17px;
  cursor: pointer;
}
.betable__errorhistory>div {
  position: relative;
}
.betable__errorhistory>div:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ffdee9;
  position: absolute;
  border-radius: 50%;
  position: absolute;
  margin-left: -13px;
  margin-top: 2px;
}
.betable__cpumem {
  margin-top: 7px;
}
.betable__cpumem b {
  color: #8ac34a;
  font-size: 9px;
}

.betable__cpumem svg.ct-chart-bar, .betable__cpumem svg.ct-chart-line{
  overflow: visible;
}
.betable__cpumem .ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0; /*transform: translate(-100%) rotate(-15deg);*/
  white-space: nowrap;
  font-size: 8px;
  margin-left: 20px;
}

.historyerror__detail {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  padding: 20px;
  background-color: #ebf2ff;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 24px;
  border-radius: 3px;
}

.historyerror__detail b {
  font-weight: bold;
  min-width: 30px;
  color: black;
  font-size: 16px;
}

/* Scroll */
.betable__errorhistory::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.betable__errorhistory::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

.betable__errorhistory::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
}

.betable__serviceuri.ibreset {
  max-width: 300px;
  overflow: hidden;
}

/* Chart */
/* adblock__meter */
.adblock__meter1 {
  background: #fff;
}
.adblock__meter2 {
  background: #fff;
}
.adblock__meter3 {
  background: #fff;
}
.adblock__meter4 {
  background: #fff;
}
.meter__body {
  padding: 25px 25px;
  line-height: 20px;
  position: relative;
}
.meter__body .nav-icon {
  position: absolute;
  right: 26px;
  top: 26px;
  color: #d9dfe9;
  font-size: 47px;
}
.meter__number {
  font-size: 25px;
  font-weight: 100;
  margin-bottom: 7px;
}
.meter__number--meter1 {
  color: #3f51b5;
}
.meter__number--meter2 {
  color: #e64a19;
}
.meter__number--meter3 {
  color: #4caf4f;
}
.meter__number--meter4 {
  color: #ffa001;
}
.meter__footer b {
  font-size: 25px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
  color: #98a6ad;
}
.meter__footer--meter1 {
  background-color: #3f51b5;
}
.meter__footer--meter2 {
  background-color: #e64a19;
}
.meter__footer--meter3 {
  background-color: #4caf4f;
}
.meter__footer--meter4 {
  background-color: #ffa001;
}
.meter__name {
  font-weight: bold;
}
.adblock__meterfooter {
}
.meter__img {
  width: 100%;
  margin-top: 30px;
}
.adblock__number {
  padding: 20px 25px 0px 25px;
  width: 100%;
  margin-top: 70px;
}
.adblock__innercustom {
  margin-left: 0!important;
  margin-right: 0!important;
  position: relative;
  padding: 30px;
}
.chart__item {
  background-color: #ffffff;
  margin-top: 30px;
  border: 1px solid #e2e5e8;
  padding: 30px;
  padding-top: 20px;
  height: 600px;
}
.chart__title {
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: normal;
  color: #1b2e4b;
}
.chart__content {
  margin-top: 30px;
  width: 100%;
}
.chart__select {
  float: right;
  width: 190px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #e0e3f3;
  background-color: #f8f8fc;
}
.chart__select--topic {
  float: right;
  width: 100px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #e0e3f3;
  background-color: #f8f8fc;
  position: absolute;
  right: 30px;
  z-index: 0;
  top: 50px;
}

/* Navbar scroll */
.beside__navitemsinner::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.beside__navitemsinner::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.beside__navitemsinner::-webkit-scrollbar-thumb
{
  background-color: #aaaaaa;
  border: 2px solid #555555;
}

.rx__sync {
  color: #fff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
}
.rx__sync.red  {
  background-color: #DB3A34;
  border: solid 1px #DB3A34;
}
.rx__sync.green {
  background-color: #339f58;
  border: solid 1px #339f58;
}
.rx__sync.green.update {
  background-color: #155ca6;
  border: solid 1px #155ca6;
}

/* rxpaging */
.rxpagingwrap {
  width: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.col-md-4 .rxpagingwrap {
  display: block;
}
.col-md-4 .rxpaging {
  position: relative;
  right: 0;
  justify-content: flex-end;
}
.rxpaging {
  position: absolute;
  right: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.rxpage {
  float: left;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  margin-left: 7px;
  background-color: #f0f2f5;
  text-align: center;
  padding: 15px 0px;
  line-height: 0px;
  border-radius: 3px;
}
.rxpage>i {
  line-height: 0px;
}
.rxpage.rxactive {
  background-color: rgba(112, 112, 112, 0.1);
  /* background-color: #1890ff; */
  color: #000;
  font-weight: bold;
}
.rxpagesize {
  float: left;
  margin-right: 5px;
}
.rxpagesize>span {
  margin-right: 5px;
  color: #9599a3;
}
.rxpagesize>select {
  margin-right: 5px;
  margin: 0px;
  padding: 5px;
  font-size: 12px;
  height: 30px;
  appearance: none;
  background: #ffff;
  background-color: #f0f2f5;
  border: none;
}
.rxpagesize>select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}
.rxpagingwrap {
  width: 100%;
  position: relative;
}
.rxpagingcount {
  /*position: absolute;
  top: 10px;*/
  color: #a1a3a9;
}
.rxpagingcount>b {
  font-weight: bold;
  color: #666;
}
.adtable__pagingMore {
  background-color: unset;
}
.adtable__paginfirst, .adtable__paginlast {
    line-height: 0 !important;
    font-size: 20px;
}
.adform__rawedit {
  width: 100% !important;
}
.adform__raweditbtn {
  cursor: pointer;
  color: #fff;
}
.adform__raweditcontent {
  height: 500px !important;
}
.jsoneditor-search input {
  color: #1a1a1a !important;
  width: 120px !important;
  border: none !important;
  outline: none;
  margin: 1px !important;
  line-height: 20px !important;
  height: auto !important;
  padding: 0px !important;
}
.adform__btnedit--raw {
  background-color: #3883f9;
  border: none;
  border-radius: 0px;
  padding: 2px 10px;
  font-size: 12px;
}
.betable__findwrap {
  width: 800px;
  max-width: 100%;
  position: relative;
}
.findexpand {
  margin-top: 10px;
  margin-bottom: 20px;
  border: solid 1px #eee;
  border-radius: 5px;
  min-height: 60px;
}
.findexpand_head {
  height: 25px;
  background-color: #fafafa;
  padding: 15px 15px;
  line-height: 0px;
}
.findexpand_body {
  padding: 25px 15px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px rgba(49, 55, 62, 0.1);
  background-color: #ffffff;
  position: relative;
}
.class_avatar {
  width: 100%;
  max-width: 200px;
  height: 264px;
  background-color: #f8f8f8;
  padding: 20px 0;
}
.form2-header-top .class_avatar {
  float: left;
}
.form2-header-title .header__title {
  position: absolute;
  width: 100%;
}
.admin-avatar {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  object-fit: contain;
}

.form-header {
  width: 100% !important;
}
.form-header>div {
  width: 100% !important;
  display: flex;
  align-items: center;
  position: relative;
}
.text-center.form-avatar-sub {
  font-size: 14px;
  line-height: 0.93;
  text-align: center;
  color: #000000;
  padding-top: 15px;
}
.form-header-right.barcode {
    max-width: 200px;
    height: 264px;
    background-color: #f8f8f8;
    float: right;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
}
.title-top {
  font-size: 18px;
  color: #31373e;
}
.title-alias {
  font-size: 15px;
  color: #31373e;
  padding-top: 5px;
  font-style: italic;
}
.title-main {
  padding-top: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #2869b5;
}
.form-header-bottom {
  padding-top: 55px;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: auto;
}
.form-header-bottom>span {
  margin-right: 5px;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #000000;
  /*white-space: nowrap;*/
  width: 200px;
}
.form-header-bottom .rxselectbox-wrap {
  width: 100%;
  max-width: 350px;
  background-color: #f8f8f8;
}
#input-search {
  width: 100%;
}
.rx-form__body {
  width: 100% !important;
}
.rx-form__body--title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #2869b5;
    padding: 20px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
}
.rx-form__body--title:before {
  content: '';
  background: #2869b5;
  width: 10px;
  position: absolute;
  left: 0;
  height: 36px;
}
.rx-form__body>div:last-child {
  width: 100%;
  position: relative;
  background-color: #f8f8f8;
  /*padding: 30px;*/
  padding: 20px 0;
}
.rx-form__item {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
.rx-form__item>div {
  width: 100%;
  /* width: 50%; */
  padding: 10px 30px;
  display: flex;
  align-items: center;
}
.rx-form__item>div>span {
    min-width: 200px;
    max-width: 150px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    padding-right: 2px;
}
.rx-form__item>div>span.custom_width {
  max-width: 500px;
  width: 400px;
}
span.custom_width p {
  font-size: 10px;
}
.button_action {
  margin-left: 295px;
  margin-top: 15px;
}
strong {
    line-height: 1.5;
}
.tab-list-item {
    display: inline-block;
    padding-right: 15px;
    cursor: pointer;
    background-color: #d2d8e0;
    margin-right: 10px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.tab-list-item.tab-list-active {
    color: #fff;
    background-color: #626f7e;
}
.adform__typecard--body>span {
    min-width: 200px;
}
.adform__typecard--body.view {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.adform__typecard--body.view:nth-child(even) {
  padding: 0 10px;
}
.content.unconfirm {
    color: red;
}
span.icon-bellnum.unconfirm {
    background-color: #4caf50;
}
.rx-form__item .rxselectbox-wrap, .rx-form__item input, .rx-form__item select {
    width: 100%;
    min-width: 100px;
    color: #000;
    /* height: 35px; */
}
.react-datepicker-wrapper {
  width: 100%;
}
.rx-form__select {
    padding: 4px 11px !important;
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #ffffff;
    background-image: none !important;
}
.rx-upload_cmnd {
    width: 150px;
    height: 85px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #dedede;
    display: flex;
    align-items: center;
}
.admin-product-img-multi {
  width: 150px;
  height: 85px;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px rgba(112, 112, 112, 0.2);
  background-color: #dedede;
  float: left;
  margin-right: 15px;
  position: relative;
}
.admin-image-add {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.icon-addImg {
  font-size: 50px;
  line-height: 0.32;
  color: #000000;
  margin: auto;
}
.admin-product-img-remove {
    position: absolute;
    padding: 3px;
    border: 1px solid #838383;
    color: #000;
    font-weight: 300;
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 5px;
    top: 0;
    right: 0;
    text-align: center;
}
.icon-cmnd {
  margin-top: 15px;
  font-size: 32px;
  /* float: left; */
  display: block;
  height: 32px;
  width: 100%;
  text-align: center;
}
.rx-upload_cmnd-title {
    display: block;
    margin-top: 7px;
    font-size: 12px;
    text-align: center;
    color: #000000;
}
.rx-upload_cmnd:first-child {
    margin-right: 20px;
}
.rx-upload_cmnd .admin-product-img-upload, .admin-image-upload {
  width: 100%;
  height: 100%;
  max-height: 85px;
  object-fit: contain;
  padding: 0;
  border: unset;
  border-radius: unset;
}
.rx-form__body .p10 {
    padding: 10px 30px;
}
.rx-form__title--sub {
    padding: 0 30px;
    position: relative;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.4px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    margin: 15px 0;
}
.rx-form__title--sub>span {
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 30px;
    left: 240px;
    background-color: rgba(112, 112, 112, 0.2);
}
.adform__footer .adform__btns {
  width: 100%;
}
.adform__footer .adform__close {
    float: right;
    /* margin-top: 15px; */
}
.css-1wa3eu0-placeholder {
  font-size: 13px;
}

.class_avatar-img {
    height: 190px;
    width: 130px;
    object-fit: contain;
    margin: auto;
    display: flex;
}
.rxdiff__content {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.rxdiff__content table {
    width: 100%;
}
.rxdiff__content .text-center {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  padding: 5px 15px;
  background-color: #fafafa;
}
.rxdiff__content tr:first-child{
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
}
.rxdiff__content td tr {
  border: 1px solid #e5e5e5;
}
.rxdiff__content td tr td {
  border-right: 1px solid #e5e5e5;
}
.rxdiff__content td {
    padding: 5px 15px;
    background-color: #fff;
}
.rxdiff__content td div {
    padding: 5px 0 5px 40px;
}
.rxdiff__content-number {
    padding: 0;
    width: 50px;
    text-align: center;
    background-color: #fafafa !important;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.rxdiff__content--old {
    background-color: #fbe9eb !important;
}
.rxdiff__content--new {
    background-color: #ecfdf0 !important;
}
.rxselectbox-wrap {
  min-height: 42px;

}
.rx-table__form {
  padding: 0 30px;
  position: relative;
}
.rx-table__form table, .rx-table__form td {
    /*border: #d3d3d3 1px dotted;*/
    padding: 5px 10px;
    background-color: #fff;
    border: solid 1px rgba(112, 112, 112, 0.2);
}
.rx-table__form td {
  margin: 5px;
}
.rx-table__form input {
  width: 100%;
  border: #d3d3d3 1px dotted;
}
.form-header-code {
  position: absolute;
  bottom: -20px;
  text-align: center;
  width: 100%;
  left: 0;
}
.adbtn__add {
    width: 100px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    background-color: #0f68c4 !important;
    cursor: pointer;
    text-align: center;
}
.adbtn__clear {
    width: 100px;
    font-weight: bold;
    color: #838383 !important;
    cursor: pointer;
    text-align: center;
}
.rx-table__form-header {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  border: solid 1px #ffffff !important;
  background-color: #31373e !important;
  color: #fff;
  padding: 10px 15px !important;
  line-height: 1.2;
  vertical-align: middle;
}
.rxlist__card-table .rx-table__form-header {
  padding: 5px !important;
  font-size: 18px;
}
.rx-cursor {
    cursor: pointer;
}
.class_avatar img {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  object-fit: contain;
  display: flex;
  margin: auto;
}

.rx-form__note {
  font-size: 10px;
  font-weight: bolder;
  color: red;
  margin-left: 186px;
}

.react-datepicker__month-select, .react-datepicker__year-select {
    height: 25px !important;
    border: 1px solid #fafafa;
    border-radius: 5px;
}
.react-datepicker {
  border: none;
  box-shadow: 0 1px 5px rgba(0,21,41,.1)
}

.rx--box__title {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #000000;
  width: 75px;
  white-space: nowrap;
}

.rx--btn__search {
  width: 213px;
  height: 40px;
  border-radius: 5px;
  background-color: #626f7e;
}

/* .rx--box__filter {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #be3e3e;
} */

.rx--box__search {
  display: block;
  /*margin-top: 10px;
  margin-bottom: 10px;
  height: 110px;*/
  cursor: pointer;
}

.rx--btn__clearFilter {
  width: 68px;
  height: 18px;
  font-size: 15px;
  line-height: 0.87;
  text-align: left;
  cursor: pointer;
}

.search_now {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  display: block;
  margin-top: 10px;
}

.clearFilter {
  bottom: -25px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #0f68c4;
  display: block;
  position: absolute;
}
.clearFilter:hover {
  text-decoration: underline;
}

.rx-form__body > div > div > p {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  line-height: 1.5;
}

.rx-title__table {
  background-color: #fff !important;
}

.rx-title__table > th {
  /*color: #ffffff;*/
}
.rx-table__form-plus {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #eaeaea;
  position: absolute;
  padding: 8px;
  font-size: 17px;
  right: 30px;
  bottom: 0;
  cursor: pointer;
}
.title-header {
  font-size: 25px;
  font-weight: bold;
}
.title-slug {
  font-weight: bold;
}
.rx-form__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 20px;
    grid-gap: 5px;
    font-size: 15px;
}
.rxlist__card-table {
    max-width: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    position: absolute;
}
.rxlist__card-table .rx-table__form {
  padding: 0;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.rx-form__header {
  display: block !important;
}
.rxlist__card-table td {
  padding: 8px;
  border: solid 1px rgba(112, 112, 112, 0.2);
  line-height: 1.4;
  font-size: 15px;
}
.iconcustom-require.icon-require {
    color: #be3e3f;
    font-size: 25px;
}
.rx-xursor {
  cursor: pointer;
}
.rx-list__card {
  position: relative;
  height: 358px;
  width: 100%;
  margin-top: 20px;
}
.rx--check__titleForm .rx--check__title .rx--check__time .rx--check__content {
  color: #31373e;
}

.rx--check__title .rx--check__time .rx--check__content {
  font-size: 12px;
}

.rx--check__titleForm {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 31px;
  display: block;
}

.rx--check__title {
  margin-bottom: 11px;
  display: block;
}

.rx--check__time {
  opacity: 0.6;
  display: block;
  margin-bottom: 5px;
}

.rx--check__content {
  font-weight: bold;
  display: block;
  border-radius: 3px;
  background-color: #f8f8f8;
  height: 100%;
}

.rx--check__content > .content {
  display: block;
  padding-top: 14px;
  padding-left: 5px;
  max-height: 200px;
  overflow-y: scroll;
  height: 100%;
}

.rx--checkIssue {
  display: block;
  padding-bottom: 40px;
  padding-left: 10px;
  position: relative;
  font-size: 14px;
}
.rx--checkIssue__btn {
  position: absolute;
  left: -5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.rx--checkIssue__btn.duyet {
  background: #2869b5;
}
.rx--checkIssue__btn.khongduyet {
  background: #be3e3f;
}
.rx--checkIssue__line {
  position: absolute;
  left: -1px;
  background: #dddddd;
  width: 3px;
  height: 112px;
  top: -112px;
}
.rx-form__sign-check {
    width: 50px !important;
    height: 25px !important;
}
.rx-form__sign__item {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  height: 100px;
}
.formsign__title {
  font-size: 15px;
  color: #31373e;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
}
.formsign__sign {
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
}
.formsign__time {
  font-size: 14px;
  text-align: center;
}
.rx-form__sign-check {
  width: 25px !important;
  height: 25px !important;
  margin-right: 20px;
  text-align: center;
  border: solid 1px rgba(112, 112, 112, 0.2);
  border-radius: 5px;
}
.rx-form__sign-check.iconcustom-check {
  padding: 2px 3px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  background-color: #0f68c4;
}
.rx-marginTop {
  margin-top: -30px;
}
.rx-crud__form {
  width: 100% !important;
}
.rx-padding {
  padding: 10px !important;
}
.rx-form__info span {
  font-size: 16px;
  min-width: 230px;
}
.rx-form__info>div {
  width: 100%;
  display: flex;
  align-items: center;
}

.avatar_print {
  width: 100%;
  max-width: 130px;
  height: 220px;
  padding: 20px 0;
}

.avatar_print img {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  object-fit: contain;
  display: flex;
  margin: auto;
}
.barcode__print {
  position: absolute;
  right: 5px;
  top: 5px;
}

.text-center > P {
  display: block;
}

/* TIMELINE */
.renderTimeline {
    display: block;
    position: relative;
    padding: 15px;
    margin: 15px 0;
    /* opacity: 0.5; */
    border-radius: 20px;
    border: solid 1px rgba(49, 55, 62, 0.1);
}

.progress-bar-order {
    display: block;
}
.progress-bar-item {
     width: 20%;
    display: inline-block;
    font-size: 14px;
    text-align: center;
}
.progress-bar-item2 {
  width: 50%;
  display: inline-block;
  font-size: 14px;
  text-align: center;
}
.right-item{
  float: right;
}
.progress-bar-order .progress-bar-item:first-child .progress-bar-item-line.left {
    background: transparent;
}
.progress-bar-order .progress-bar-item:last-child .progress-bar-item-line.right {
    background: transparent;
}
.progress-bar-item-ico {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    color: #dbdbdb;
    padding: 0;
    border: 3px solid #dbdbdb;
    margin: 0 auto;
    background-color: #fff;
}
.progress-bar-item-ico-in {
    background-image: url(/images/static/MaskGroup2@3x.png);
    background-size: 50%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.progress-bar-item-progress {
    display: block;
    position: relative;
}
.progress-bar-item-line {
    width: calc(50% - 22px);
    height: 3px;
    background: #ffffff;
    margin: auto 0;
    position: absolute;
    top: 50%;
    z-index: 1;
}
.progress-bar-item-line.right {
    right: 0;
}
.progress-bar-item2.active.right {
  float: right;
}
.progress-bar-item.active .progress-bar-item-line {
    background: #2869b5;
}
.progress-bar-item.active .progress-bar-item-ico {
    color: #2869b5;
    border: 6px solid #2869b5;
}
.progress-bar-item.active .progress-bar-item-ico-in {
    background-image: url(/images/static/mask@3x.png);
}
.progress-bar-item2.active .progress-bar-item-line {
  background: #2869b5;
}
.progress-bar-item2.active .progress-bar-item-ico {
  color: #2869b5;
  border: 6px solid #2869b5;
}
.progress-bar-item2.active .progress-bar-item-ico-in {
  background-image: url(/images/static/mask@3x.png);
}

.progress-bar-item.active.nonactive .progress-bar-item-line {
    background: #F44336;
}
.progress-bar-item.active.nonactive .progress-bar-item-ico {
    color: #F44336;
    border: 6px solid #F44336;
}
.progress-bar-item2.active.nonactive .progress-bar-item-line {
  background: #F44336;
}
.progress-bar-item2.active.nonactive .progress-bar-item-ico {
  color: #F44336;
  border: 6px solid #F44336;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.progress-bar-item-step {
    font-size: 12px;
    color: #31373e;
    font-weight: bold;
    line-height: 1.08;
}
.progress-bar-item-title {
    font-size: 16px;
    font-weight: 100;
    padding: 5px;
}
.progress-bar-item-date {
    text-align: center;
    width: 100%;
    display: inline-block;
    opacity: 0.5;
    font-size: 12px;
}
.adform__typecard .rxselectbox-wrap {
    width: 100%;
}
.adform__typecard div {
    font-size: 16px !important;
}
.adform__typecard .rxselectbox-result>div {
  padding: 8px 5px;
}
.adform__typecard--body {
  display: flex;
  align-items: center;
}
.adform__typecard--select {
  width: calc(100% - 150px);
}
.form2-header-title {
  display: block !important;
  width: 100%;
  padding-bottom: 10px;
}
.width-100-percent {
  width: 100%;
}
.form2-header-top {
  width: 100%;
  min-height: 100px;
  position: relative;
}
.header__left {
  float: left;
  width: 40%;
}
.header__left .form-header-bottom {
  padding: 0;
}
.header__right {
  float: right;
}
.header__right .title-top {
    font-weight: 500;
}
.title-sub {
    padding: 30px 0;
    font-size: 16px;
    text-align: center;
}
.fullwidth-frominput .react-datepicker-wrapper {
  width: calc(100% - 150px);
}
.fullwidth-frominput .react-datepicker-wrapper input {  width: 100% !important; }
.header__left .rxselectbox-wrap {
    min-height: 35px;
    width: 100%;
}
.header__left .text-center {
  font-size: 16px;
}
.header__hr {
    margin: 10px auto;
    height: 1px;
    width: 60%;
    background: #f5f4f4;
}
.header__title {
  padding-top: 40px;
  font-size: 20px;
  font-weight: 500;
}
.rx-fixed.rx-image__full {
    background: rgba(0,0,0,.8);
    z-index: 1001;
    display: flex;
    align-items: center;
    top: 70px;
    height: calc(100% - 70px);
}
.rx-image__content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.rx-image__content-full>div {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0;
}
.rx-image__content-full {
  display: flex;
  align-items: center;
  overflow: auto;
  position: absolute;
  height: calc(100% - 100px);
}
.rx-image__close {
  position: fixed;
  right: 10px;
  top: 80px;
  font-size: 30px;
  cursor: pointer;
  background: #000;
  color: #fff;
  z-index: 2;
  border-radius: 3px;
}
.rx-image__back {
  left: 0px;
}
.rx-image__next {
  right: 0px;
}
.rx-image__back, .rx-image__next {
  position: fixed;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}
.adnav__theme {
  display: flex;
  align-items: center;
  padding: 0 50px;
}
.adnav__theme .adnav__itemname {
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #fff;
}
.adnav__theme .adnav__itemname.black {
  background-color: #31373e;
  margin-right: 5px;
}
.adnav__theme .adnav__itemname.white {
  background-color: #fff;
}
.adnav__theme.white .adnav__itemname {
  border: 1px solid #31373e;
}
.rx-form__body--note {
  border-radius: 6px;
  background-color: #2869b5;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  margin-top: -20px;
  margin-left: 25px;
  position: absolute;
  z-index: 1;
}
.rx-form__body.note>div:last-child {
  border-radius: 10px;
  background-color: #e7e8eb;
  margin-top: 50px;
  padding: 30px;
}
.adform__btn--clear {
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #000;
  display: flex;
  align-items: center;
}
.adform__btn--clear>i {
  font-size: 20px;
  line-height: 0;
  margin-right: 5px;
}

.table--name_office {
  min-width: 250px;
}
.table--name_office {
  min-width: 100px;
}

.table--name_small{
  min-width: 100px;
}

.card__name {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
}

.card__image{
  display: inline-block;
  margin-bottom: -5px;
}
.rx-flex {
  display: flex;
  align-items: center;
}
.adform__toggle {
  box-shadow: 0 10px 50px 0 rgba(56, 65, 74, 0.03);
  background-color: #ffffff;
}
.betable__status {
  background-color: rgba(26, 188, 156, 0.18);
  border-radius: 4px;
  padding: 3px 5px;
}
.findexpand_close {
    position: absolute;
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    top: 5px;
    padding: 10px;
    z-index: 10;
}
.findexpand_close>i {
    margin-right: 5px;
}
.rx-filter {
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    border: solid 1px rgba(49, 55, 62, 0.1);
    background-color: #ffffff;
    position: relative;
}
.rx-percent-25 {
  width: 25%;
}
.marginRight-35 {
  margin-right: 35px;
}
.rx-form__sign {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  padding: 0 10px;
}
.rxselectbox-result>div>div {
  padding: 5px;
  padding-right: 10px;
  border: solid 1px rgba(112, 112, 112, 0.2);
  border-radius: 3px;
  position: relative;
  background-color: #fff;
}
.rxselectbox-result>div>div>b {
  position: relative;
  right: 3px;
  top: 0;
  font-size: 10px;
  color: #737272;
}
.rx-image__list {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
}
.rx-image__list>div {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: auto;
}
.rx-image__list img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 5px;
}
.opacity-img {
  opacity: 0.4;
  cursor: pointer;
}
/*.adblock__image {
  width: 607px;
  height: 308px;
}
.print .adblock__image {
  width: 324px;
  height: 204px;
}*/
.betable__img-card {
  width: 80px;
  max-height: 50px;
  object-fit: contain;
}
.img_card .rx-upload_cmnd {
  width: 100px;
  height: 50px;
  margin: 0;
  background-color: unset;
  border: none;
  border-radius: 5px;
}
.img_card .admin-product-img-upload {
  border-radius: 5px;
  border: solid 1px rgba(112, 112, 112, 0.2);
}
.rxloading {
  display: flex;
  align-items: center;
  z-index: 9999;
}
.betable__report>thead>tr {
  background-color: #fff;
}
.betable__report tr {
  border-bottom: 1px solid #ddd;
}
.betable__report td {
  min-width: 80px;
}
.filter__dashboard {
  margin-top: 15px;
}
.filter__dashboard .rx--btn__clearFilter {
  float: left;
  position: relative;
  width: unset;
  height: 100%;
}
.filter__dashboard .clearFilter {
  white-space: nowrap;
}
.betable__btnimport.active {
  background-color: #626f7e;
}
.betable__report thead td {
    border-right: 1px solid #ddd;
}
.betable__report td:first-child {
  display: table-cell;
}
.adform__name-tab {
    margin-bottom: -20px;
}
.adform__title {
  font-size: 32px;
  padding-bottom: 20px;
  font-weight: 300;
}
.adform__title>span {
  font-weight: 500;
}
.rxlist__card-table.print td {
  vertical-align: middle;
}
.rxlist__card-table.print .img_card .admin-product-img-upload {
  width: 100px;
  height: 50px;
  object-fit: contain;
}
.adform__note {
  font-size: 16px;
  font-weight: bold;
}
.adform__note i {
  color: #323232 !important;
  font-weight: bold;
}
.rx-filter__dashboard {
  margin: 10px 0 0 0 !important;
}
.align__right {
  text-align: right;
}
.adform__typecard--item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
}
.adform__typecard--item.view {
  background-color: #fff;
}
.betable__typecard--select {
  width: 200px;
}
.rxlist__card-table.return thead tr th {
  padding: 10px;
  background-color: #31373e;
  color: #fff;
}
.daterangepicker .drp-buttons .btn {
  float: right;
  margin-bottom: 10px;
}
.rx-flex .react-bootstrap-daterangepicker-container {
  width: 100%;
  background-color: #fff;
  border: solid 1px rgba(112, 112, 112, 0.2);
}
.rx-flex .icon {
  float: right;
}
.rxlist__card-table.return table tbody tr td {
    vertical-align: middle;
}
.text-right {
  text-align: right;
  color: #0d47a0;
  font-weight: bold;
}
.rx-noti td {
  padding: 5px;
}
.rx-noti__header {
  background-color:#ccebff !important;
  border-bottom: 1px solid #eee;
}
.dropitem.rx-noti__body {
    background: #fff;
}
.rx-noti__header td {
    padding: 10px 5px;
    font-weight: bold;
    font-size: 16px;
}
.noti__menu__count {
  text-align: right;
  color: red;
}
.rx-noti {
    border: 1px solid #eee;
    margin-bottom: 10px;
}
.align-right{
  text-align : right
}
.gender--item {
    max-width: 370px;
}
.gender--title {
  max-width: 100px !important;
  min-width: 100px !important;
}
.province--item {
    width: 260px;
    max-width: 100%;
}
.LoaiHinhBC {
  vertical-align: middle;
}
.LoaiHinhBC .rxselectbox-wrap {
  width: 100%;
}
.datetime--table {
  display: flex;
  align-items: center;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px;
}
.datetime--table .react-datepicker__header {
  padding-bottom: 8px;
}
.rx-sub__header {
  font-size: 16px;
}

.betable__btnDisEdit {
  opacity: 0.3;
}

.rxdragdroptree-over {
    padding-right: 26px;
}

.rxdragdroptree-wrap {
    width: 100%;
}

.rxdragdroptree-item-inner {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 10px;
    height: 20px;
}

.rxdragdroptree-item {
    position: relative;
    display: block;
    padding: 10px;
    cursor: pointer;
    border: solid 1px #eee;
    box-sizing: border-box;
}

.rxdragdroptree-item>div:first-child { 

}

.rxdragdroptree-placeholder { 
    background: red;
    z-index: 10;
    position: absolute;
    width: 100px;
}

.rxdragdroptree-placeholder:before {
    content: ''; 
    color: #ffffff;
    display: block;
    height: 2px;  
    box-shadow: 0px 0px 10px red;
    box-sizing: border-box;  
}


.rxdragdroptree-over.rxdragdroptree-folderstyle {
    padding-right: 26px;
    height: 400px;
    overflow-y: scroll;
}

.rxdragdroptree-folderstyle .rxdragdroptree-wrap {
    width: 100%;
}

.rxdragdroptree-folderstyle  .rxdragdroptree-item-inner {
    position: absolute;
    width: 100%;
    left: 0px;
    top: 10px;
    height: 20px;
}

.rxdragdroptree-folderstyle .rxdragdroptree-item {
    position: relative;
    display: block;
    padding: 0px;
    cursor: pointer;
    box-sizing: border-box;
    padding-left: 11px;
    border: none;
    border-left: solid 1px #aaa;
    padding-top: 9px;
    padding-bottom: 0px;
    margin-left: 5px;
}

.rxdragdroptree-folderstyle .rxdragdroptree-item:before {
    content: '';
    display: block;
    width: 10px;
    height: 1px;
    position: absolute;
    background-color: #aaa;
    left: 0px;
    top: 21px;
}

.rxdragdroptree-folderstyle  .rxdragdroptree-item>div:first-child { 

}

.rxdragdroptree-folderstyle  .rxdragdroptree-placeholder { 
    background: red;
    z-index: 10;
    position: absolute;
    width: 100px;
}

.rxdragdroptree-folderstyle  .rxdragdroptree-placeholder:before {
    content: ''; 
    color: #ffffff;
    display: block;
    height: 2px;
    box-shadow: 0px 0px 10px red;
    box-sizing: border-box;  
}

.rxdragdroptree-folderstyle .rxdragdroptree-item-content {
    height: 30px;
    display: block;
    background-color: #fff;
    padding: 5px 10px;
    box-sizing: border-box;
    border-radius: 3px;
    border: solid 1px #aaa;
    overflow: hidden;
}
.rxtoggle-container {
    display: block;
    position: relative;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
    width: 45px;
    height: 25px;
    border-radius: 30px;
    background: #f8f8f8;
    cursor: pointer;
    box-sizing: border-box;
}

.rxtoggle-style1 {
    box-shadow: inset 0 0 0 0px rgba(19,191,17,1), 0 0 0 2px #dddddd;
}

.rxtoggle-style1.rx-active {
    box-shadow: inset 0 0 0 18px rgb(22, 125, 251), 0 0 0 2px rgb(22, 114, 218);
    background: #12bf11;
}

.rxtoggle-inner {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 34px;
    text-indent: 40px;
    height: 25px;
    width: 25px;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    right: auto;
    background: white;
    box-shadow: 0 3px 3px rgba(0,0,0,.2), 0 0 0 2px #dddddd;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    transition: .25s ease-in-out;
    outline: none;
    font-family: Helvetica Neue,helvetica,arial,verdana,sans-serif;
}

.rx-active > .rxtoggle-inner{
    left: calc(100% - 25px);
    box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0,0,0,.3);
}

.admin-product-img-upload {
  width: 112px;
  height: 112px;
  border: solid 1px #eee;
  padding: 3px;
  border-radius: 3px;
}
.rx-image-footer {
    position: absolute;
    left: 15px;
    width: 50px;
    border: 1px solid red;
    bottom: 15px;
    margin: 0 auto;
    width: 100%;
}
.rx-image-footer button {
    text-align: center;
    margin-left: 50%;
}
.imageRotate {
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
  /* IE 9 */
}
.imageRotate button {
    padding: 25px 18px;
    border-radius: 10px;
    border: 2px solid #fff;
    background: #2196F3;
    color: #fff;
    cursor: pointer;
}
.rotate90 {
  transform: rotate(90deg) translateY(-100%);
  -webkit-transform: rotate(90deg) translateY(-100%);
  -ms-transform: rotate(90deg) translateY(-100%);
}
.imageRotate.rotate90 {
  transform: rotate(180deg) translate(-100%, -100%);
  -webkit-transform: rotate(180deg) translate(-100%, -100%);
  -ms-transform: rotate(180deg) translateX(-100%, -100%);
}
.imageRotate.rotate90 {
  transform: rotate(270deg) translateX(-100%);
  -webkit-transform: rotate(270deg) translateX(-100%);
  -ms-transform: rotate(270deg) translateX(-100%);
}

.popups {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0.8;
}
.popup {
    /* height: 525px;
    width: 400px;
    border-radius: 5px;
    background-color: #E2E4E7; */
    position: fixed;
    /* max-width: 90%; */
    z-index: 1111;
}
.popups-transparent {
    background-color: transparent;
}
.popup__header {
    height: 65px;
    width: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #069616;
    position: absolute;
    top: 0;
}

.popup__title {
    width: 100%;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    background: transparent;
    text-align: center;
    padding: 20px;
}

.popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 9;
}

.popup__close-delete {
    font-size: 25px;
    color: #fff;
}

.popup__body {
    /* width: 50%; */
    width: 70%;
    height: 450px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 65px;
    background-color: transparent;
}

.popup__main {
    background-color: #ffffff;
    width: 100%;
    /* height: 435px; */
    height: auto;
    border-radius: 6px;
    padding: 30px;
    margin-top: 10px;
    min-height: 435px;
}

.popup__tabs {
    display: flex;
    justify-content: space-between;
}

.popup__tab {
    width: 50%;
    height: 44px;
    border-radius: 6px;
    background-color: #3d4145;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.popup__form-socials {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    background: transparent;
}
.popup__form-socials span {
  background: transparent;
}
.rxhide {
  display: block;
}

.popup__button {
    width: 100%;
    height: 46px;
    border-radius: 23px;
    background-color: #0072bd;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: relative;
    outline: none;
    user-select: none;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .popup__button:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    /*background: url(/images/icons/next@2x.png);*/
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
  }

.popup__facebook {
    width: 144px;
    height: 50px;
    border-radius: 23px;
    background-color: #054c90;
    border: none;
    cursor: pointer;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.53px;
    text-align: center;
    color: #ffffff;
    outline: none;
    user-select: none;
}

.popup__google {
    width: 144px;
    height: 50px;
    border-radius: 23px;
    background-color: #d0021b;
    border: none;
    cursor: pointer;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.53px;
    text-align: center;
    color: #ffffff;
    outline: none;
    user-select: none;
}

.popup__forgot {
    font-size: 14px;
    text-align: center;
    color: #7c838a;
    cursor: pointer;
    margin: 20px 0;
}

.popup__message {
    font-size: 14px;
    text-align: center;
    color: #3d4145;
    padding-bottom: 10px;
}

.popup__message-link {
    color: #0072bd;
    font-size: 14px;
    cursor: pointer;
}
.popup__message-link a {
    color: #0072bd;
    font-size: 14px;
    cursor: pointer;
}

.popup__form-label {
    font-size: 14px;
    font-weight: 600;
    color: #3d4145;
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    background: transparent;
}
.popup__message {
  display: none;
}

.popup__form-group {
    margin-bottom: 20px;
    background: transparent;
}

.popup__form-input {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 8px 0;
    border-bottom: 1px solid #e2e4e7;
    font-family: Gilroy;
    font-size: 18px;
    color: #3d4145;
    width: 100%;
    padding-left: 15px;
}

.popup__form-input.input-partitioned {
    padding-left: 10px;
    letter-spacing: 34px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 43px 1px;
    background-repeat: repeat-x;
    background-position-x: 30px;
    width: 250px;
}

.popup__form-input::placeholder {
    color: #c5c9d0;
}

.popup__select {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 7px 0;
    border-bottom: 1px solid #e2e4e7;
    font-family: Gilroy;
    font-size: 18px;
    color: #3d4145;
    width: 100%;
}

.popup__inline-form-group {
    display: flex;
    align-items: center;
}

/* .popup__inline-form-group div:first-child {
    margin-right: 24px;
}

.popup__inline-form-group div:last-child {
    flex: 1;
} */

.popup__tab--active {
    background-color: white;
    color: #3d4145;
    position: relative;
}

.popup__tab--active:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100px;
    height: 3px;
    border-radius: 1.5px;
    background-image: linear-gradient(268deg, #2b8845, #2b8845);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.popup__back {
    position: absolute;
    left: 15px;
    top: 22px;
    cursor: pointer;
    z-index: 9;
}

.falert {
    text-align: center;
    height: 5px;
    font-size: 15px;
    color: red;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 10%;
    background: #fff;
    z-index: 100;
}

.iconiread-close.btn-close_popup:before {
    color: #fff;
}

.popup__message-err {
    color: red;
    font-size: 17px;
    text-align: center;
}

.popup__areaButton {
    height: 30px;
}

.popup_titleCheck {
    font-size: 18px;
    font-weight: 700;
}
.content__2--body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    width: 100%;
}
.content__1--right img {
	width: 500px;
	object-fit: contain;
}
.content__1--left {
    display: flex;
    align-items: center;
    padding: 30px;
}
.content__1--logo {
    height: 80px;
    margin-bottom: 45px;
    object-fit: contain;
}
.content__1--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    font-family: 'Gilroy', sans-serif;
}
.content__1--desc {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.42;
    text-align: left;
    color: #464646;
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: 'Gilroy', sans-serif;
}
.content__1--download {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
}
.content__1--download img {
    height: 80px;
    width: 240px;
    object-fit: contain;
    cursor: pointer;
}
.download__play {
    margin-left: 30px;
}
.content__2--right {
    position: relative;
    width: 100%;
    height: 657px;
    margin-top: 50px;
}
.content__2--right img {
    position: absolute;
    left: 60px;
    width: 566px;
    height: 100%;
    object-fit: contain;
}
.content__2--head {
    display: flex;
    align-items: center;
    position: relative;
}
.content__2--icon {
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.content__2--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    padding-left: 45px;
}
.content__2--desc {
    padding-top: 80px;
}
.content__2--desc div {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    display: flex;
}
.content__2--desc img {
    width: 27px;
    height: 20px;
    object-fit: contain;
    margin-right: 30px;
}
.content__3--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    font-family: 'Gilroy', sans-serif;
}
.content__3--desc {
    width: 100%;
    max-width: 850px;
    padding-top: 40px;
}
.item__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    font-family: 'Gilroy', sans-serif;
}
.item__title img {
    position: absolute;
    right: 0;
    width: 16px;
    height: 10px;
    object-fit: contain;
}
.content__3--item {
    /*padding: 20px 0;*/
    border-bottom: 1px solid #eee;
}
.item__content {
    display: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    padding-bottom: 10px;
    font-family: 'Gilroy', sans-serif;
}
.footer {
    padding: 10px 240px;
    width: 100%;
    display: flex;
    left: 0;
    bottom: 0;
    position: absolute;
    align-items: center;
}
.footer__logo {
    width: 120px;
    height: 40px;
    object-fit: contain;
}
.footer__copy {
    /*width: 170px;
    margin: auto;*/
    width: 100%;
    text-align: center;
    color: rgba(54, 54, 54, 0.85);
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
}
.rx-mobile {
    display: none;
}
.content__1--right {
    position: relative;
    display: flex;
}
.rx-toggle_1 {
    position: absolute;
    bottom: 50px;
    left: -85px;
    height: 65px !important;
    width: 84px !important;
}
.rx-toggle_2 {
    position: relative;
    margin-top: 80%;
    width: 84px !important;
    height: 65px !important;
}
.rx-toggle_3 {
    position: absolute;
    bottom: 30px;
    left: 532px !important;
    /* right: 100px; */
    width: 84px !important;
    height: 65px !important;
}
.rx-toggle_4 {
    position: absolute;
    right: 0px;
    bottom: -40px;
    height: 65px !important;
    width: 84px !important;
}
#current_year {
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    color: rgba(54, 54, 54, 0.85);
}
.rx-toggle_5 {
    width: 84px;
    height: 65px;
    position: absolute;
    left: 120px;
}
.rx-toggle_6 {
    position: absolute;
    width: 117px;
    height: 90px;
    right: 23%;
    bottom: 40%;
}
.content__term--body {
    padding: 15px 240px;
    position: relative;
}
.content__term--body .footer {
    position: relative;
    padding: 15px 0;
}
.content__term--desc, .content__term--desc li, .content__term--desc ul, .content__term--desc a {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.42;
    text-align: left;
    color: #464646;
    font-family: 'Gilroy', sans-serif;
}

.content__term--header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    text-transform: uppercase;
    font-family: 'Gilroy', sans-serif;
}
.content__faq .content__3--body {
    background-image: unset;
    padding: 50px 150px;
}
.content__faq .content__3--desc {
    padding: 0;
    max-width: unset;
}
.icon-content {
    height: 30px;
    object-fit: contain;
    margin-top: -10px;
}
.footer__right {
    text-align: center;
    width: 100%;
}
.footer__term {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: center;
    color: rgba(54, 54, 54, 0.85);
    padding-bottom: 10px;
    font-family: 'Gilroy', sans-serif;
}
.ul {
    display: list-item;
    list-style: inside;
    margin-left: 1.5em;
}
.footer__term:hover {
    text-decoration: underline;
}
@media only screen and (min-width: 1025px) and (max-width: 1440px){
    .content__1--body {
        padding: 140px 110px 80px;
    }
    .content__1--logo {
        height: 65px;
        margin-bottom: 40px;
    }
    .content__1--title {
        font-size: 50px;
    }
    .content__1--right img {
        width: 380px;
    }
    .content__1--desc {
        font-size: 20px;
    }
    .content__1--left {
        padding: 10px;
    }
    .content__1--download img {
        height: 65px;
        width: 200px;
    }
    .content__2--item {
        padding: 80px 110px;
    }
    .content__2--right img {
        left: 0;
        width: 510px;
        height: 590px;
    }
    .content__2--icon {
        width: 100px;
        height: 100px;
    }
    .content__2--title {
        font-size: 50px;
        padding-left: 30px;
    }
    .content__2--desc img {
        margin-right: 20px;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 20px;
        margin-bottom: 25px;
    }
    .content__2--background {
        width: 40%;
        height: calc(100% + 160px);
        top: 80px;
        bottom: -240px;
    }
    .content__3--body {
        padding: 80px 110px 30px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 50px 110px;
    }
    .content__3--title {
        font-size: 50px;
    }
    .footer {
        padding: 30px 0 0;
        position: relative;
    }
    .content__term--body {
        padding: 15px 110px;
        position: relative;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px){
    .content__1--body {
        padding: 120px 60px 60px;
    }
    .content__1--logo {
        height: 65px;
        margin-bottom: 30px;
    }
    .content__1--title {
        font-size: 40px;
    }
    .content__1--right img {
        width: 340px;
    }
    .content__1--desc {
        font-size: 16px;
    }
    .content__1--left {
        padding: 10px;
    }
    .content__1--download img {
        height: 60px;
        width: 180px;
    }
    .download__play {
        margin-left: 20px;
    }
    .content__2--item {
        padding: 60px;
    }
    .content__2--right img {
        left: 40px;
        width: 355px;
        height: 410px;
    }
    .content__2--icon {
        width: 80px;
        height: 80px;
    }
    .content__2--title {
        font-size: 40px;
        padding-left: 25px;
    }
    .content__2--desc img {
        margin-right: 20px;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .content__2--background {
        width: 40%;
        height: calc(100% + 160px);
        top: 80px;
        bottom: -240px;
    }
    .content__3--body {
        padding: 20px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 20;
    }
    .content__3--title {
        font-size: 40px;
    }
    .item__title {
        font-size: 18px;
    }
    .item__desc {
        font-size: 16px;
    }
    .footer {
        padding: 20px;
        position: relative;
        padding-bottom: 0;
    }
    .content__term--body {
        padding: 20px;
        position: relative;
    }
    .content__term--desc {
        font-size: 16px;
    }
    .content__term--header {
        font-size: 18px;
    }
}
@media only screen and (max-width: 768px){
    .rx-toggle_1 {
        display: none;
    }
    .rx-toggle_2 {
        display: none;
    }
    .rx-toggle_3 {
        display: none;
    }
    .rx-toggle_4 {
        display: none;
    }
    .rx-toggle_5 {
        display: none;
    }
    .rx-toggle_6 {
        display: none;
    }
    .content__1--body {
        padding: 20px 15px;
        display: block;
        background-position: right -60px top;
        background-size: 50%;
    }
    .content__1--logo {
        height: 40px;
        margin-bottom: 10px;
    }
    .content__1--title {
        font-size: 30px;
    }
    .rx-mobile {
        display: block;
    }
    .phone_1 {
        width: 165px;
        margin: 10px auto;
    }
    .content__1--right {
        display: none;
    }
    .content__1--desc {
        font-size: 14px;
    }
    .content__1--left {
        padding: 0px;
    }
    .content__1--download img {
        height: 45px;
        width: 137px;
    }
    .download__play {
        margin-left: 15px;
    }
    .content__2--item {
        padding: 20px 15px;
        padding-top: 100px;
    }
    .content__2--background {
        width: 40%;
        height: 130px;
        top: -30px;
        bottom: unset;
    }
    .content__2--right {
        display: none;
    }
    .content__1--download {
        position: relative;
    }
    .phone_2 {
        width: 297px;
        margin: 40px auto 10px;
    }
    .content__2--body {
        display: block;
    }
    .content__2--icon {
        width: 64px;
        height: 64px;
    }
    .content__2--title {
        font-size: 25px;
        padding-left: 30px;
    }
    .content__2--desc img {
        margin-right: 13px;
        width: 16px;
        height: 10px;
        object-fit: contain;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .content__3--body {
        padding: 20px 15px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 15px;
    }
    .content__3--title {
        font-size: 24px;
    }
    .content__3--desc {
        padding-top: 20px;
    }
    .content__faq .content__3--desc {
        padding: 0;
    }
    .item__title {
        font-size: 16px;
        padding-right: 20px;
    }
    .item__content {
        font-size: 14px;
    }
    .footer {
        padding: 0;
        padding-top: 25px;
        padding-bottom: 0px;
        display: grid;
        position: relative;
    }
    .footer__logo {
        width: 70px;
        height: 24px;
        margin: auto;
    }
    .content__term--body {
        padding: 15px;
    }
    .content__term--desc {
        font-size: 14px;
    }
    .content__term--header {
        font-size: 16px;
    }
}
/* Slides */

.slide {
    height: 450px;
    position: relative;
}

.slide__item a {
    text-decoration: none;
}

.slide__itemimg {
    width: 100%;
    padding-left: 50px;
    margin-top: 55px;
    position: absolute;
    z-index: -1;
}

.slide__txttitle {
    margin-top: 160px;
    font-size: 40px;
    text-transform: uppercase;
    font-weight: bold;
    color: #262453;
    width: 470px;
    line-height: 58px;
    letter-spacing: -3px;
    padding-left: 20px;
}

.slide__txtdesc {
    font-size: 20px;
    margin-top: 20px;
    color: #888;
    padding-left: 20px;
}

.slide__back {
    position: absolute;
    top: 50%;
    left: 20px;
    width: 50px;
    height: 50px;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
    border-radius: 50%;
    text-align: center;
    padding-top: 16px;
    font-size: 20px;
    cursor: pointer;
}

.slide__next {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 50px;
    height: 50px;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.1);
    border-radius: 50%;
    text-align: center;
    padding-top: 16px;
    font-size: 20px;
    cursor: pointer;
}

/* Support */

.block__supportwrap {
    padding-left: 7% !important;
    padding-right: 7% !important;
}

.block__supportico {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 10px;
    color: #fff;
    font-size: 25px;
    font-weight: bold;
}

.block__support {
    margin: auto;
    width: 100% !important;
    background: #11998e;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    background: #36D1DC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #5B86E5, #36D1DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    border-radius: 3px;
    text-transform: uppercase;
    text-align: center;
    font-size: 13px;
    color: #fff;
    opacity: 0.9;
    margin-top: -30px;
    padding-bottom: 20px;
    line-height: 20px;
    min-height: 115px;
}

.block__supportdesc {
    font-size: 11px;
    line-height: 16px;
    margin-top: 5px;
    text-transform: none;
    color: #cbf5ff;
}

/* Products */

.block__product {
    padding-bottom: 30px;
}

.block__productwrap {
    background-color: #f9f9f9;
    margin-top: -60px;
    padding-top: 60px;
}

.block__productwrap--fff {
    background-color: #fff;
    margin-top: 50px;
    padding-top: 50px;
}

.block__producttitle {
    font-size: 36px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 50px;
}

.block__producttitle:after {
    content: '';
    width: 6%;
    left: 47%;
    height: 5px;
    background: #36D1DC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #5B86E5, #36D1DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    display: block;
    position: absolute;
    margin-top: 15px;
}

.product__rate {
    text-align: center;
}

.product__name {
    text-align: center;
}

.product__name>i {
    font-size: 12px;
    color: #888;
}

.product__buy {
    text-align: center;
    width: 200px;
    margin: auto;
    padding: 15px 20px;
    box-sizing: border-box;
    background: #11998e;
    background: linear-gradient(to right, #38ef7d, #11998e);

    background: #36D1DC;  /* fallback for old browsers */  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to left, #5B86E5, #36D1DC); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    cursor: pointer;
    border-radius: 2px;
    color: #fff;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 20px;
}

.product__buy:hover {
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.product__buy--small {
    max-width: 140px;
    padding: 15px 0px;
    font-size: 14px;
}
.product__price {
    text-align: center;
    margin-top: 5px;
    margin-bottom: 15px;
}

.product__pricenew {
    position: relative;
    color: #ee3897;
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

.product__pricenew>i {
    color: #888888;
    font-size: 12px;
    font-weight: normal;
    margin-left: 5px;
}

.product__priceold {
    text-decoration: line-through;
    color: #aaa;
}

.product__currency {
    position: absolute;
    top: 0px;
    font-size: 11px;
    text-decoration: underline;
    margin-left: 2px;
}

.block__productdetail {
    border-radius: 3px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.block__productdetail--small {
    padding: 0px;
}

.product__image {
    width: 100%;
    overflow: hidden;
    padding: 35px;
}

.product__image img {
    width: 100%;
}

.product__star {
    margin-right: 5px;
}

.product__name {
    font-size: 16px;
    line-height: 30px;
    margin-top: 22px;
}

/* Reviews */

.block__reviewdetail {
    max-width: 300px;
    margin: auto;
    position: relative;
}

.review__content {
    height: 180px;
    border: solid 4px #eee;
    padding: 25px;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 50px;
    color: #888;
    text-align: center;
    overflow: hidden;
    line-height: 28px;
}

.review__content::after {
    width: 0;
    height: 0;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-top: 15px solid #eee;
    content: '';
    top: 180px;
    position: absolute;
    left: 0px;
    margin-left: 45%;
}

.review__content > p {
    max-height: 110px;
    overflow: hidden;
}

.review__decor {
    position: absolute;
    font-size: 100px;
    left: 19px;
    top: 4px;
    color: #eeeeee;
    z-index: -1;
}

.block__review {
    padding-bottom: 100px;
}

.block__reviewwrap {
    padding-top: 40px;
    padding-bottom: 40px;
}

.block__reviewtitle {
    font-size: 36px;
    text-align: center;
    margin-top: 50px;
    margin-bottom: 100px;
}
.review__name {
    text-align: center;
}
.review__name>i {
    font-size: 12px;
    color: #888;
}

.review__productdetail {
    border-radius: 3px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #ffffff;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.review__image {
    width: 100%;
    overflow: hidden;
    text-align: center;
}

.review__image img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    margin: auto;
    filter: grayscale;
    filter: grayscale(100%);
    opacity: 0.2;
}

.review__star {
    margin-right: 5px;
}

.review__name {
    font-size: 16px;
    line-height: 30px;
    margin-top: 22px;
    margin-bottom: 30px;
}

/* Partners */

.block__partneritem img {
    height: 90px;
    float: left;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    margin-left: 20px;
    margin-right: 20px;
    filter: grayscale(100%);
    opacity: 0.5;
}

.block__partner.container {
    max-height: 100px;
    overflow: hidden;
}

.block__partnerwrap {
    border-top: solid 1px #eee;
    border-bottom: solid 1px #eee;
}
.adbody__card {
    padding-top: 70px;
}
.adcard__filter {
    padding: 30px;
}
.adcard__filter .rx-form__item {
    margin-bottom: 10px;
}
.adcard__filter .rx-form__item>span {
    width: 150px;
}

.footer {
    
}

.footer__title {
    margin-top: 80px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-weight: bold;
}

.footer__title--center {
    text-align: center;
}

.footer__head {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    color: #333;
}

.footer__block {
    line-height: 18px;
    font-size: 13px;
    color: #9E9E9E;
    margin-bottom: 20px;
}

.footer__blocklast {
    background-color: #fafafa;
    padding-bottom: 120px;
}

.footer__accountitem:first-child {
    margin-top: -9px;
}

.footer__accountitem {
    line-height: 36px;
}

.footer__accountitem--center {
    line-height: 26px;
    text-align: center;
    padding: 0 10px;
}

.footer__accountitem--contact {
    padding: 0 10px;
    margin-top: 20px;
    position: relative;
}

.footer__contactinput {
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 0 10px;
}

.footer__contactbtn {
    position: absolute;
    float: right;
    right: 0px;
    width: 80px;
    padding: 0 10px;
    background-color: #333333;
    color: #fff;
    font-size: 11px;
    text-align: center;
}

.footer__bot {
    background-color: #000000;
    color: #fff;
    height: 42px;
    text-align: center;
    margin-top: -42px;
    position: absolute;
    width: 100%;
}

.footer__copyright {
    padding-top: 6px;
    padding-bottom: 5px;
    font-size: 10px;
}

.footer__dkkd {
    font-size: 9px;
    color: #777;
}

.footer__background {
    width: 100%;
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
    opacity: 0.7;
    border: none;
    box-sizing: border-box;
}
/* Home Navigation */

.homenav {
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(61,119,180,0.12), 0 0.25rem 12px 0 rgba(91,139,199,0.24);
    z-index: 100;
    position: fixed;
    width: 100%;
}

.homenav__left {
    float: left;
    width: 500px;
}

.homenav__right {
    float: right;
}

.homenav__item {
    float: left;
    padding: 20px 10px;
    cursor: pointer;
}

.homenav__logoimg {
    float: left;
    margin-top: 3px;
    cursor: pointer;
    max-height: 45px;
    margin-left: -20px;
}

.homenav__item--mobile {
    border: solid 1px #E91E63;
    background-color: #E91E63;
    color: #fff;
    padding: 6px 15px;
    border-radius: 2px;
    margin-top: 13px;
}

.homenav__item--login {
    border: solid 1px #E91E63;
    background-color: #ffffff;
    color: #E91E63;
    padding: 6px 15px;
    border-radius: 2px;
    margin-top: 13px;
    margin-left: 10px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.homenav__item--login>a {
    color: #E91E63;
}

.homenav__cartico {
    font-size: 16px;
    margin-top: -4px;
    font-weight: bold;
    margin-right: 5px;
    position: relative;
}

.homenav__carttxt {
    font-size: 12px;
    margin-left: 5px;
}

.homenav__cartnum {
    position: absolute;
    top: 17px;
    left: -17px;
    color: #fff;
    background-color: #333;
    height: 22px;
    box-sizing: border-box;
    width: 22px;
    padding: 6px 3px;
    text-align: center;
    border-radius: 50%;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.homenav__cartlite {
    position: absolute;
    width: 350px;
    padding: 20px;
    top: 55px;
    box-sizing: border-box;
    background-color: #fff;
    box-shadow: 0px 2px 40px rgba(0,0,0,0.2);
    right: -110px;
    height: 400px;
    border-top: solid 3px #333;
}

.homenav__cartlite:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #333;
    top: -10px;
    left: 46%;
}

.homenav__cartbuy {
    text-align: center;
    background-color: #157efb;
    padding: 15px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}

.homenav__cartcontinue {
    font-size: 13px;
    text-align: center;  
    margin-bottom: 8px;
    font-weight: normal;
    background-color: #eee;
    border-radius: 3px;
    line-height: 30px;
    height: 30px;
    cursor: pointer;
}

.homenav__cartitem {
    margin-bottom: 15px;
    height: 50px;
    position: relative;
}

.homenav__cartitem--lg {
    height: 80px;
}

/* Cart */

.cartitem__holder {
    height: 280px;
    overflow-y: auto;
}

.cartitem__pic img {
    width: 100%;
}

.cartitem__pic {
    width: 50px;
    height: 50px;  
    border-radius: 2px;
    position: absolute;
}

.cartitem__nam {
    width: 100%;
    padding-left: 58px;
    padding-right: 58px;
    overflow: hidden;
    font-weight: lighter;
    font-size: 13px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 18px;
}

.cartitem__pic--lg {
    width: 80px;
    height: 80px;  
}

.cartitem__nam--lg {
    padding-left: 90px;
    font-size: 16px;
    margin-bottom: 5px;
}

.cartitem__pricehold {
    width: 100%;
    padding-left: 58px;
    padding-right: 58px;
    float: left;
    margin-top: 3px;
    font-size: 12px;
    line-height: 24px;
}

.cartitem__pricehold--lg {
    padding-left: 90px;
}

.cartitem__num {
    float: left;
    font-weight: normal;
    width: 30px;
    text-align: center;
    background-color: #eee;
    margin-right: 5px;
    border-radius: 3px;
}

.cartitem__price {
    float: left;
    font-weight: normal;
    margin-right: 5px;
}

.cartitem__total {
    float: left;
    font-weight: bold;
}

.cartitem__del {
    position: absolute;
    right: 0px;
    background-color: #333;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
    font-size: 17px;
    font-weight: lighter;
    cursor: pointer;
    top: 15px;
    margin-right: 10px;
}

.cartitem__empty {
    line-height: 23px;
    font-size: 13px;
    text-align: center;
    font-weight: normal;
}
.col--rightborder {
    border-right: solid 1px #eee;
}

.ppayment__cart {
    min-height: 400px;
}

.ppayment__info {
    max-width: 400px;
    display: block;
    margin: auto;
}
.ppayment__info label {
    width: 100%;
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
    margin-top: 20px;
    font-size: 14px;
}

.ppayment__info label b {
    color: #E91E63;
    font-size: 20px;
    position: absolute;
    margin-left: 5px;
    margin-top: -5px;
}

.ppayment__info input {
    width: 100%;
    height: 40px;
    padding: 7px;
    font-size: 14px;
    border-radius: 3px;
    box-shadow: none;
    border: solid 1px #ddd;
}

.ppayment__done {
    background-color: #26c685;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding: 15px;
    text-align: center;  
    border-radius: 3px;
    cursor: pointer;
}

.ppayment__error {
    margin-top: 20px;
    height: 35px;
    line-height: 17px;
    color: #E91E63;
}

.pppayment__success {
    min-height: 500px;
    text-align: center;
    font-size: 24px;
    color: #4CAF50;
    padding-top: 30px;
}

.pcate {
    min-height: 1000px;
}

.pcate__search {  
    min-height: 1000px;
    border-right: solid 1px #eee;
}

.pcate__products {
    padding-top: 30px;
}

.pcate__slide {
    min-height: 200px;
    width: 100%;
    background-color: #fafafa;
    margin-top: 50px;
    margin-bottom: 30px;
}

.searchfield__name {
    width: 100%;
    margin: auto;
    height: 31px;
    border-radius: 3px;
    border: solid 1px #eee;
    padding-left: 10px;
    font-size: 12px;
}

.searchfield__namewrap {
    position: relative;
    width: 90%;
    cursor: pointer;
}

.searchfield__nameico {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 15px;
    padding: 8px 7px;
    color: #abb7cc;
    box-sizing: border-box;
}

.searchfield__filter {
    padding-top: 30px;
    padding-bottom: 30px;
}

.searchfield__cate label {
    font-size: 12px;
    color: #888;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.searchfield__cateitem {
    position: relative;
    padding: 7px 13px;
    cursor: pointer;
}

.searchfield__cateitem:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid black;
    left: 0px;
    top: 10px;
}

.block__parallax {
    padding-top: 180px;
    position: relative;
    min-height: 550px;
    background-image: url('/images/static/parallaximg.jpg');
    height: 100%; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.block__parallaxstroketop {
    width: 100%;
    position: absolute;
    top: 0px;
    background-image: url('/images/static/stroketop.png');
    background-repeat: repeat-x;
    height: 100px;
    background-size: contain;
    background-position-y: 0%;
}

.block__parallaxstrokebot {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-image: url('/images/static/strokebot.png');
    background-repeat: repeat-x;
    height: 100px;
    background-size: contain;
    background-position-y: 100%;
}

.block__parallaxtitle {
    font-size: 24px;
    line-height: 30px;
    padding-top: 17px;
    text-align: center;
    margin-bottom: 10px;  
    color: #1fb0dc;
}

.block__parallaxdesc {
    padding-left: 40px;
    padding-right: 30px;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
}

.block__parallaxcontent {
    margin: auto;
    width: 80%;
    max-width: 600px;
    min-height: 140px;
    background-color: #ffffffd1;
    box-shadow: 0 0 40px rgba(0,0,0,0.3);
    border: solid 1px #ffff;
    padding: 10px;
    padding-top: 0px;
}
/*
  http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;*/
  font-family: 'Roboto', 'Encode Sans', 'Source Sans Pro', 'Roboto Slab', 'Source Serif Pro', sans-serif;
}

@font-face {
   font-family: Gilroy;
   src: url(/static/media/Gilroy-Regular1.c0649498.ttf);
}

@font-face {
   font-family: Gilroy;
   src: url(/static/media/Gilroy-Bold1.ab668278.ttf);
   font-weight: bold;
}

a {
  color: #242628;
  text-transform: none;
  text-decoration: none;
}
b {
    font-weight: bold;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  width: 100%;
  margin: auto;
  min-height: 42px;
  border-radius: 3px;
  border: 1px solid #C4D8F8;
  padding-left: 10px;
  font-size: 16px;
  color: #242628;
}
.progress-bar:before {
  content: '';
  position: fixed;
  z-index: 999;
  left: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}
.progress-bar {
  height: 7px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  z-index: 1001;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #63b2ff;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.loader {
  margin: 10px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid #63b2ff;
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid #63b2ff;
  transform: translateZ(0);
  animation: load8 0.5s infinite linear;
  position: fixed;
  bottom: 20px;
  /* background-color: #000; */
  right: 20px;
  display: block;
  z-index: 1000;
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.main-container {
  /*padding-top: 50px;*/
  min-height: 500px;
}

.beside.black {
	background-color: #31373e !important;
}

.beside.white {
	background-color: #fff !important;
}

.white .adnav__groupname {
	background-color: #fff !important;
	color: #323232;
}
.white .adnav__groupname>span, .white .adnav__groupname.expand>span {
	color: #323232;
}
.white .adnav__groupwrap {
	background-color: #fff;
}
.white .adnav__itemname>a, .white .adnav__theme .adnav__itemname {
	color: #323232;
}
.black .adnav__groupname {
	background-color: #31373e !important;
	color: #fff;
}
.black .adnav__groupname>span, .black .adnav__groupname.expand>span {
	color: #fff;
}
.black .adnav__groupwrap {
	background-color: #31373e;
}
.black .adnav__itemname>a, .black .adnav__theme .adnav__itemname {
	color: #fff;
}

td.rxwidth--stt {
    width: 40px;
}
td.rxwidth--gender {
    width: 40px;
}

.rx_develop {
	font-size: 30px;
	font-weight: 700;
	text-align: center;
}
/* adtopbar */
.adtopbar {
  height: 70px;
  /*box-shadow: 0 1px 4px rgba(0,21,41,.08);*/
  background-color: #147ce1;
  border-bottom: solid 3px #014391;
  width: 100%;
  z-index: 10001;
  position: fixed;
  top: 0px;
  left: 0px;
  background-image: url('/images/static/bg_hd.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  display: flex;
  align-items: center;
}
.adtopbar__left {
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.adtopbar__left img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
}
.adtopbar__logo--sub {
    color: #fff;
    font-size: 18px;
    line-height: 1.4;
}
.adtopbar__logo--title {
    color: #fff;
    font-size: 20px;
    /* line-height: 0.54; */
    letter-spacing: 0.48px;
    font-weight: 500;
    line-height: 1.2;
}
.adtopbar__togglesidebar {
  left: 0px;
  /* position: fixed; */
  padding: 23px;
  font-size: 25px;
  color: #aaa;
  line-height: 0px;
  top: 0px;
  background: transparent;
  z-index: 101;
}
.adtopbar__togglesidebar.rxsideactive {
  left: 0;
}
.adtopbar__right {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
}
.adtopbar__center {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
}
.adtopbar__right>div {
  float: left;
  height: 100%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  width: 70px;
  /*border-left: 1px solid #e7eaef;*/
  /*padding: 23px;*/
}
.adminnav__notify {
  padding: 23px !important;
  color: #fff;
}
.adtopbar__addcard.hotline {
  background: #127ce1;
  border-right: 1px solid;
  display: block;
  padding: 25px 10px;
}
.hotline {
  display: block;
  text-align: left;
  color: #fff;
  /* padding: 3px 0px; */
}
.hotline p {
  display: inline-block;
}
.p1 {
  width: 38px;
  font-size: 14px;
}
.adtopbar__right .adminnav__avatar {
  width: 200px;
  padding: 0;
  background-color: #295a93;
  color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}
.adminnav__avatar>div:first-child {
  width: 100%;
  height: 100%;
  background-color: #295a93;
  color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.adminnav__avatar>div>span {
  font-size: 14px;
  line-height: 1.29;
}
.adminnav__avatar-icon {
    font-size: 25px !important;
    line-height: 0 !important;
    position: absolute;
    margin-top: 5px;
    right: 0px;
    padding: 0 25px;
}
.adtopbar__right .adminnav__avatar>span {
  /*position: absolute;
  width: 36px;
  left: 0px;
  height: 36px;
  top: 0px;
  padding: 18px 0px;
  line-height: 0px;*/
  text-align: center;
  font-size: 32px;
}
.topnav-icon {
  /*line-height: 0px;*/
  cursor: pointer;
  font-size: 23px;
  color: #ffffff;
}

/* beside */
.beside {
  position: fixed;
  /*width: 230px;*/
  width: 270px;
  height: 2060px;
  z-index: 100;
  top: 70px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  background-color: #1c67af;
  /*box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;*/
}
.beside.rxsideactive {
  display: none;
}
.beside__navitems {
}
.beside__navitemsinner {
  position: absolute;
  width: 100%;
  /*top: 50px;*/
  padding-top: 0px;
  padding-bottom: 200px;
  overflow-y: auto;
  height: 100%;
  background-image: url('/images/static/bg@3x.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.beside__logo {
  height: 60px;
  width: 100%;
  text-align: center;
  line-height: 0px;
  padding: 30px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #fff;
  background-image: linear-gradient(180deg, #000000, #00142a);
}
.beside__logoversion {
  font-size: 10px;
  margin-top: 25px;
  position: absolute;
}
.beside__logoicon {
  float: left;
  color: #E91E63;
  font-weight: bold;
}
.adnav__groupwrap {
  position: relative;
  display: none;
  padding: 10px 0;
  background-color: #24527f;
}
.adnav__groupwrap--card {
  padding: 2px 0;
  background-color: #24527f;
  overflow-x: auto;
}
.adnav__groupwrap.rxactive {
  display: block;
}
/*.adnav__groupwrap:before {
  content: '';
  width: 1px;
  height: 100%;
  background: rgba(255,255,255, 0.7);
  display: block;
  position: absolute;
  left: 29px;
  top: -7px;
}*/
.adnav__groupname:before {
  font-weight: normal;
  font-size: 20px;
  position: absolute;
  right: 20px;
  /*top: 20px;*/
}
.adnav__groupname2:before {
  color: #000000 !important;
  font-weight: normal;
  font-size: 30px;
  position: absolute;
  right: 20px;
  /*top: 20px;*/
}
.adnav__groupname.expand, .adnav__groupname.rxactive {
  /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);*/
  background-color: #147ce1;
  color: #fff;
}
.adnav__groupname.expand>span, .adnav__groupname.expand>span>i {

  color:#fff;
}
.adnav__itemname.rxactive .adnav__groupname {
  background-color: #be3e3e;
}
.adnav__groupname2.expand {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #a6a6a6;
  color: black;
}
.adnav__groupname2.rxactive {
  display: block;
  background-color: #f0f2f5;
  border-bottom: #fff ;
}
.adnav__groupname2.expand>span, .adnav__groupname2.expand>span>i {
  color:black;
}
.adnav__itemname.rxactive .adnav__groupname2 {
  background-color: #be3e3e;
}
.adnav__groupname--link:after {
  display: none;
}
.adnav__groupname {
  background-color: #165ca6;
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 1px solid #5387c4;
}
.adnav__groupname2 {
  background-color:#dddd;
  position: relative;
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 2px solid #bfbfbf;
}
.adnav__groupname>a {
  padding: 25px 50px;
  color: #ffffff;
  letter-spacing: 2.1px;
  display: block;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.adnav__groupname2>a {
  padding: 25px 50px;
  color: #444;
  letter-spacing: 2.1px;
  display: block;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.adnav__groupname>a>i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0px;
  margin-top: 6px;
  margin-left: -30px;
  position: absolute;
  color: #ffffff;
  font-weight: bold;
}
.adnav__groupname2>a>i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0px;
  margin-top: 6px;
  margin-left: -30px;
  position: absolute;
  color: black;
  font-weight: bold;
}
.adnav__groupname>span {
  color: #ffffff;
  font-size: 17px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2.4;
  font-weight: bold;
}
.adnav__groupname2>span {
  color: #000000;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2.4;
  font-weight: bold;
}
.adnav__groupname>span>i {
  margin-right: 10px;
  font-size: 20px;
  float: left;
  margin-top: 7px;
  color: #015bb4;
  font-weight: bold;
}
.adnav__groupname2>span>i {
  margin-right: 10px;
  font-size: 20px;
  float: left;
  margin-top: 7px;
  color: #015bb4;
  font-weight: bold;
}
.adnav__groupname--expand>a {
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>a {
  /*background-color: #1890ff;*/
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>a>i {
  /*color: #ffffff;*/
}
.adnav__groupname--expand>span {
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>span {
  /*background-color: #1890ff;*/
  /*color: black;*/
}
.adnav__groupname.rxactive>span>i {
  color: black;
}
.adnav__groupname2.rxactive>span {
  /*background-color: #1890ff;*/
  color: black;
}
.adnav__groupname2.rxactive>span>i {
  color: #ffffff;
}
.adnav__groupname--first {
  /*margin-top: 40px;*/
}
.adnav__groupname--last {
  position: fixed;
  bottom: -1px;
  width: 270px;
  padding: 0;
  /*background-color: #010f1e;*/
}
.adnav__groupname2--last {
  position: fixed;
  bottom: -1px;
  width: 270px;
  padding: 0;
  /*background-color: #010f1e;*/
}
/*.adnav__groupname:hover {
  color: rgba(255,255,255,1);
}
.adnav__groupname:hover>a {
  color: #ffffff;
}*/
.adnav__itemname {
  line-height: 0px;
}
.adnav__itemname>a {
  /*height: 30px;*/
  padding: 15px 50px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 0px;
  display: block;
  width: 100%;
  white-space: nowrap;
  /*background: #0072C6;
  border-bottom: solid 1px #015bb4;*/
}
.adnav__itemname.rxactive>a {
  background-color: #074391;
  color: #ffffff;
}
.adnav__itemname>span {
  /*height: 30px;*/
  padding: 15px 50px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 0px;
  display: block;
  width: 100%;
  white-space: nowrap;
  /*background: #0072C6;
  border-bottom: solid 1px #015bb4;*/
}
.adnav__itemname.rxactive>span {
  background-color: #074391;
  color: #ffffff;
}
/*.adnav__itemname>a:before {
  content: '';
  background: rgba(255,255,255, 0.7);
  height: 1.1px;
  width: 5px;
  position: absolute;
  left: 30px;
}*/
.rxhide {
  display: none;
}
.rxshow {
  display: block;
}
.rxfull {
  width: 100%;
}
/*.adnav__itemname:hover {
  background-color: rgba(255,255,255,0.1);
}*/
.adnav__itemname .nav-icon {
  margin-right: 10px;
  line-height: 0px;
  color: #ffffff;
  font-size: 20px;
}
.adnav__itemname_lv2 .nav-icon, .adnav__itemname_lv3 .nav-icon {
    color: #b3b3b3;
}
.adnav__itemname .nav-icon.icon-graph {
  display: none;
}
/* bemain */
.bemain {
  padding-left: 270px;
  min-height: 2000px;
  /*overflow: hidden;*/
  background-color: #f0f2f5;
}
.bemain.rxsideactive {
  padding-left: 0px;
}
.bemain__wrap {
  padding: 30px;
}
.avatar_name {
    font-size: 11px;
    color: #ffffff;
    font-style: italic;
}
.rx-font-14 {
  font-size: 14px;
}
.rx-require {
    color: red;
}

.adtopbar__logo>a {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 30px;
}
.adtopbar__logo {
    width: 270px;
    height: 100%;
    float: left;
    border-right: 2px solid #f5f4f4;
}
.adtopbar__title {
    /*float: left;*/
    color: #fff;
    /*display: flex;*/
    /*align-items: center;*/
    /*height: 100%;*/
    /*padding-left: 100px;*/
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 24px;
    font-weight: bold;
    line-height: 0.54;
    letter-spacing: 0.48px;
    text-transform: uppercase;
}
.rx-authpage {
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  top: 70px;
  left: 0;
  background-image: url('/images/static/around.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  /*background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(129,214,254,1) 100%);*/
}

input#HoSoID-All {
    padding-left: 20px;
    width: 16px;
    height: 16px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 5px;
    margin-left: -5px;
}
.css-checkboxShow {
  border: solid 1px #adb5bd;
  border-radius: 4px;
  background-color: #fff;
  width: 16px;
  height: 16px;
}
label.css-label {
    margin-left: 5px;
}
input#IDHoSo-233 {
    padding-left: 20px;
    width: 16px;
    height: 16px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 5px;
    margin-left: -5px;
    margin-right: 5px;
    border: solid 1px #adb5bd;
    border-radius: 4px;
    background-color: #fff;
}
.col-md-12_manualtxt1 {
    color: #000080;
    font-size: 25px;
    font-family: Tahoma;
    font-weight: bold;
    float: left;
}
p.col-md-12_manualtxt2 {
    text-align: left;
    font-size: 22px;
}
h1.col-md-12_manualtxt2 {
  text-align: left;
  font-size: 26px;
  color: #e60000 !important;
  font-weight: bold;

}

.body_manual {
    width: 100%;
    margin-top: 15px;
}
img.manual__img {
    height: auto;
    display: block;
    margin: auto;
    vertical-align: middle;
    max-width: 100%;
}
.manual_content {
    background: white;
    color: #000000;
    font-size: 16px;
    font-family: Tahoma;
    margin-top: 15px;
}
h1.content_note1 {
    font-weight: bold;
    font-size: 29px;
    text-align: left;
    color: #000000;
}
p.content_note2 {
    color: #e60000;
    font-weight: 900;
    text-align: left;
    font-size: 22px;
}
p.content_note1 {
    text-align: left;
}
strong.content_note1 {
    color: #e60000;
    font-weight: bold;
    text-align: left;
    font-size: 22px;
}
strong.content_note2 {
    color: #0000b3 !important;
    text-align: left;
    font-weight: bold;
    font-size: 25px;
}

.rxpopup-addnew-cus__.print {
    position: relative;
    top: 0;
    left: 0;
    width: 494px;
    height: 317px;
    z-index: 99999;
}
.print .adblock--print {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.print .adblock__image {
  background-image: url(/images/static/background_card.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.rxprint__sign--img {
  height: 60px;
  width: 100%;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rxprint__sign--img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/*.print { display: none !important; }*/
._print_form_add {
  font-weight: normal;
}
._print_width_50 {
  width: 50%;
  display: inline-block;
  padding-right: 10px;
  font-size: 8px;
}
@media print {
  html, body {
    font-size: 5.25pt !important;
    /* height: 100%; */
    width: 100%;
    /* position: fixed; */
    /* position: absolute; */
    overflow: hidden;
    line-height: 0.5 !important;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    /* margin: 0;
    padding: 0; */
  }
  .adblock__head,.footer, .adform__btns.rx-stickybot, .betable,
  .adtopbar, .beside,.adform__typecard, .adform__header, .adform__body.col-md-2, .adform__footer,
  .rx-crud__form, .adform {display: none !important; height: 0 !important; min-height: unset !important; padding: 0 !important;}
  .bemain {min-height: unset !important; padding: 0 !important}
  .main-container {min-height: unset !important;}
  .adblock__body, .adblock {padding: 0 !important;}
  .print {
    display: block !important;
    z-index: 1000;
  }
  .rxprint__info>p {
    line-height: 1.2 !important;
  }
  .adblock__label {
    /* width: 325px; */
    /* height: 205px; */
    /* margin: auto; */
    position: relative;
    overflow: visible;
  }
  .rxprint__title {
    padding-top: 12pt;
    text-align: center;
  }
  .rxprint__title-main {
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    font-size: 11.5pt;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ed3237;
  }
  .rxprint__title-sub {
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    font-size: 10pt;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #373435;
  }
  .print .adblock__inner { box-shadow: unset !important; }
  .print .adblock--print { padding: 0; }
  .print .avatar_print {
    height: 85pt !important;
    width: 56.7pt !important;
    max-width: 56.7pt !important;
    padding: 0;
    object-fit: cover;
    /* margin-left: 25mm; */
    /* margin-top: -10pt !important; */
  }
  .print .avatar_print img {
    height: 85pt !important;
    width: 56.7pt !important;
    max-width: 56.7pt !important;
    padding: 0;
    object-fit: cover;
    position: absolute;
    top: 14.6pt;
    left: 12.6pt;
  }
  .print .form-header {
    padding-top: 105px;
    padding-left: 157px;
    padding-right: 12.6pt;
  }

  .textCenter__print {
    display: inline-block;
    width: 100%;
    font-size: 5.25pt !important;
    font-weight: 600;
  }

  .textName___print {
    font-weight: bold !important;
  }

  .textOrganization___print {
    white-space: pre-wrap;
  }

  .barcode__print {
    position: absolute;
    right: 16.2pt;
    top: 5.9pt;
    width: 36.9pt;
    height: 36.9pt;
  }

  .text__print {
    font-size: 5.25pt !important;
    font-weight: bold;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    display: flex;
  }
  .text__print--info {
    font-size: 12px !important;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #373435;
  }
  .text__print--info span {
    font-weight: bold;
    font-family: 'Times New Roman', sans-serif;
  }
  .text__print--sign span {
    font-family: 'Times New Roman', sans-serif;
  }
  .text__print--sign {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    color: #373435;
  }
  .text-center > P {
    display: block;
  }

  .text__print > span {
    font-size: 12px !important;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #373435;
  }
  .rxprint__info {
    position: absolute;
    top: 220px;
    left: 27px;
  }
  .rxprint__sing-name {
      /* position: absolute; */
      width: 195pt;
      font-weight: bold;
      font-size: 5.25pt !important;
      text-align: center;
      /*font-family: 'TimeNew', sans-serif;*/
      font-family: 'Times New Roman', sans-serif;
  }
  .rxprint__sign {
    width: 210px;
    position: absolute;
    right: 30px;
    top: 197px;
  }
  .rxprint__sign>div {
    white-space: nowrap;
  }
}
.rxprint__info>p {
  line-height: 1.2 !important;
}
.adblock__label {
  /* width: 325px; */
  /* height: 205px; */
  /* margin: auto; */
  position: relative;
  overflow: visible;
}
.rxprint__title {
  padding-top: 20pt;
  width: 100%;
  text-align: center;
}
.rxprint__title-main {
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #ed3237;
}
.rxprint__title-sub {
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #373435;
}
.print .adblock__inner { box-shadow: unset !important; }
.print .adblock--print { padding: 0; }
.print .avatar_print {
  padding: 0;
  object-fit: cover;
  position: absolute;
  height: 170px !important;
  width: 111px !important;
  max-width: 111px !important;
  padding: 0;
  object-fit: cover;
  position: absolute;
  top: 34px;
  left: 28px;
}
.print .avatar_print img {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0;
  object-fit: cover;
}
.print .form-header {
  padding-top: 25px;
  padding-left: 153px;
  padding-right: 12.6pt;
}

.textCenter__print {
  display: inline-block;
  width: 100%;
  font-size: 5.25pt !important;
  font-weight: 600;
}

.textName___print {
  font-weight: bold !important;
}

.textOrganization___print {
  white-space: pre-wrap;
}

.barcode__print {
  position: absolute;
  right: 37px;
  top: 18px;
}
.barcode__print img {
  width: 100%;
  height: 100%;
}

.text__print {
  font-size: 5.25pt !important;
  font-weight: bold;
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  display: flex;
  width: 100%;
}

.text__print--info {
  font-size: 12px !important;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #373435;
}
.text__print--info span {
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
.text__print--sign span {
  font-family: 'Roboto', sans-serif;
}

.text__print--sign {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 13px;
  letter-spacing: normal;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  color: #373435;
}

.text-center > P {
  display: block;
}

.text__print > span {
  font-size: 12px !important;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  /*font-weight: normal;*/
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #373435;
}
.rxprint__info {
  position: absolute;
  top: 220px;
  left: 27px;
}
.rxprint__sing-name {
    /* position: absolute; */
    width: 195pt;
    font-weight: bold;
    font-size: 5.25pt !important;
    text-align: center;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Roboto', sans-serif;
}
.rxprint__sign {
  width: 210px;
  position: absolute;
  right: 30px;
  top: 207px;
}
.rxprint__sign>div {
  white-space: nowrap;
}
.chart__total {
  color: #98a6ad;
  text-align: center;
  font-size: 14px;
  padding-top: 25px;
  line-height: 1.14;
  font-weight: bold;
}
.chart__total-number {
  font-size: 30px;
  line-height: 1.17;
  text-align: center;
  color: #343a40;
}
.chart__filter {
  display: flex;
  align-items: center;
  float: right;
}
.chart__filter-left .rxselectbox-wrap {
  width: 300px;
  margin-right: 10px;
}
.chart__filter-right {

}
.react-bootstrap-daterangepicker-container {
  float: right;
  padding: 9px 15px;
  font-size: 15px;
  cursor: pointer;
  color: #4c5b69;
  border-radius: 3px;
  border: solid 1px #e6edf4;
  background-color: #f9fbfd;
}
.chart__filter-item {
  padding: 7px 10px;
  border: 1px solid #eee;
  margin-left: 5px;
  border-radius: 3px;
  font-size: 14px;
  background-color: #f3f7f9;
  cursor: pointer;
}
.chart__filter-item.active {
  background-color: #6c757d;
  color: #fff;
}
.authform__btnsubmit.redirect {
  margin-top: 10px;
  letter-spacing: normal;
  background: #f2f2f2;
  color: #323232;
  font-weight: 500;
}
.authpage__toggle {
    width: 600px;
    margin: auto;
    background: #fff;
    border: 1px solid #eee;
    padding: 20px;
    /* height: 300px; */
}
.authpage__toggle .authform__head {
  text-align: center;
}
.authpage__toggle-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
}
.authpage__item {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  padding: 20px;
  display: grid;
}
.authpage__item img {
  width: 80px;
  height: 80px;
  margin: auto;
  object-fit: contain;
}
.authpage__item div {
  font-size: 14px;
  padding: 10px 0;
  font-weight: 400px;
  text-align: center;
}
.adtopbar__addcard {
  display: flex;
  align-items: center;
  width: unset !important;
  color: #fff;
  background-color: #295a93;
  padding: 10px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1px;
  font-weight: bolder;
}
.adtopbar__addcard:hover {
  background-color: #3e6a9d;
}
.adtopbar__addcard>i {
  font-size: 20px;
}
.noti__menu {
  display: flex;
  align-items: center;
}
.noti__menu>div:first-child {
  position: relative;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.noti__menu .betable__typecard--select {
  width: calc(100% - 200px);
  position: absolute;
  right: 0px;
  /*border-bottom: 1px solid #eee;
  padding-bottom: 10px;*/
}
.noti__menu .rxselectbox-wrap {
  width: 100%;
}
.authform-register {
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.authform__title {
  background: #e7e7e7;
  padding: 10px 20px;
}
.authform__title span {
  padding: 5px;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid #d1d9da;
  border-bottom: none;
  color: #3a88cb;
}
.authform-register .rx-form__item>div {
  padding: 10px 0;
}
.authform-register .rx-form__item_button>div{
  padding: 10px 0;
  margin-left: 295px;
  margin-top: 15px;
  width: 100%;
}

.authform__btnsubmit.register {
    width: 100%;
    /* background: #2d82cd; */
    letter-spacing: normal;
    font-weight: 400;
    padding: 10px 15px;
    margin-right: 15px;
}

.authform__btnsubmit.print {
  width: 100%;
  /* background: #2d82cd; */
  letter-spacing: normal;
  font-weight: 400;
  padding: 10px 15px;
}
.text__name {
  text-transform: uppercase;
}
.text__subname {
  text-transform: capitalize;
}
.sign__img {
  font-size: 60px;
  display: flex;
  justify-content: center;
  margin-left: -10px;
}
.sign__img1 {
  display: flex;
  justify-content: center;
  margin-left: 14px;
  height: 60px;
  margin-top: -5pt;
  font-size: 71px;
  transform: rotate(5deg);
  margin-bottom: 6pt;
}

@media (max-width: 890px){
  .authform-register .rx-form__item_button>div{
    padding: 10px 0;
    margin-left: 0px !important;
    margin-top: 15px;
    width: 100%;
  }
  .authform__btnsubmit.register{
    max-width:100%;
    float: left;
    text-align: center;
    }

  .authform__btnsubmit.print{
    max-width:100%;
    float: left;
    margin-top: 10px;
    margin-left: 0px !important;
    text-align: center;
    }
}
/* RXEXPENDABLE */
.rxexpand-wrap {
  margin-top: 5px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.rxexpand-toggle {
  position: absolute;
  height: 20px;
  width: 8%;
  left: 46%;
  display: block;
  bottom: -3px;
  background: #fff;
  box-shadow: 0 0 15px rgba(0,0,0,.3);
  border-radius: 3px;
  opacity: 0.7;
}

.rxexpand-toggle:after {
  content: ".....";
  display: block;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 100;
  line-height: 11px;
  color: #999;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.rxexpand-toggle.rx-active:after {
}

/* RXDRAGDROP*/
.rxdragdrop-wrap {
  width: 300px;
  height: 500px;
  overflow-y: scroll;
}
.rxdragdrop-container {
  display: block;
  padding: 13px;
  border: solid 1px #eee;
}
.rxdragdrop-container>div:first-child {
  margin-top: 10px;
}

div.rxdragdrop-placeholder {
  background: rgb(255,240,120);
}

div.rxdragdrop-placeholder:before {
  content: "Drop here";
  color: rgb(225,210,90);
}

.rx-sort-asc {
  position: relative;
}

.rx-sort-asc:after {
  content: '>';
  display: block;
  position: absolute;
  top: 13px;
  right: 10px;
  font-weight: 100;
  transform: rotate(90deg);
  font-size: 12px;
  background-color: #dddddd;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  line-height: 0px;
  padding: 8px 0px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

.rx-sort-desc {
  position: relative;
}

.rx-sort-desc:after {
  content: '>';
  display: block;
  position: absolute;
  top: 13px;
  right: 10px;
  font-weight: 100;
  transform: rotate(-90deg);
  font-size: 12px;
  background-color: #dddddd;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  line-height: 0px;
  padding: 8px 0px;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

/* .admin-product-img-upload {
  width: 112px;
  height: 112px;
  border: solid 1px #eee;
  padding: 3px;
  border-radius: 3px;
} */

.slide__itemgif {
  position: absolute;
  right: 50px;
  top: -40px;
  width: 550px;
  transform: rotate(289deg);
  z-index: 0;
  opacity: 0.3;
}

.container--relative {
  position: relative;
}

.rx-cat-title {
  text-align: center;
  padding: 10px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #2869b5;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);
}

.loaders {
  margin: auto;
  display: flex;
  align-items: center;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

tr:nth-child(odd) {
    background: #f3f7f9;
}
td.rxsticky {
    position: sticky;
    right: -25px;
    background: #e6f6ff;
}

html {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

small {
    font-size: 90%;
    opacity: 0.8;
}

td small {
    font-size: 100%;
    opacity: .8;
    max-height: 45px;
    overflow: hidden;
    display: inline-block;
}

input:focus {
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
}

input:hover {
    border-color: #40a9ff;
    border-right-width: 1px!important;
}

.rxwidth--100 {
    width: 100px;
}

.rxwidth--120 {
    width: 120px;
}

.rxwidth--150 {
    width: 150px;
}

.tableFloatingHeaderOriginal {
    z-index: 100 !important;
    top: 20px !important;
}

.ibreset b {
    font-weight: bold;
}

.ibreset i {
    font-weight: lighter;
    opacity: 0.4;
}

.btn {
    color: #fff;
    background-color: #339f58;
    border: solid 1px #339f58;
    /*text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);*/
    padding: 10px 20px;
    border-radius: 3px;
    line-height: 1.25;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.btn:hover {
    /*color: #fff;
    background-color: #339f58;
    border-color: #339f58;*/
}

.btn--default {
    color: rgba(0,0,0,.65);
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    line-height: 30px;
    cursor: pointer;
}

.btn--findexpand {
    margin-right: 5px;
}

.btn--default:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
}

.betable__btnimport {
    background-color: #fff;
    border-color: #31373e;
    color: #31373e ;
}
.betable__btnadd>i {
    font-size: 20px;
    line-height: 0;
    margin-top: -3px;
}

.betable__btnexport {
    border-radius: 3px;
    border: solid 1px #31373e;
    background-color: #626f7e;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.2;
}

.betable__btnimport > i {
    margin-right: 5px;
    font-size: 20px;
    line-height: 0;
    margin-top: -3px;
}

.betable__btnexport > i {
    margin-right: 5px;
    font-size: 20px;
    line-height: 0;
}


.show-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal {
    offset-rotate: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
}
.modal {
    width: 500px;
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    border-radius: 5px;
}
.modal h2 {
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin: 0;
    font-size: 19px;
    text-align: center;
}
.modal .content {
    padding: 15px;
}
.modal .content textarea {
    width: 100%;
    min-height: 100px;
}
.modal .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 35px 15px;
}
.rx-upload_cmnd111 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.7;
    z-index: 1111;

}
.clearfix.bg-showfull {
    position: relative;
    width: 1000px;
    height: 1000px;
    z-index: 2222;
}
.clearfix.bg-showfull .fullsize img {
    width: 100%;
    height: 100%;
}
.modal__contact {
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    border-radius: 5px;
}
.modal__contact-title {
    padding: 15px;
    background-color: #2869b5;
    color: #fff;
    font-size: 18px;
}
.modal__contact .content {
    padding: 15px;
}
.modal__contact .actions {
    border-top: 1px solid #ccc;
    padding: 25px 15px;
}
.modal__contact .content>div {
    padding: 5px;
    border: 1px solid #dddddd;
    border-bottom: none;
    background-color: #f8f8f8;
}
.modal__contact .content>div:last-child {
    background-color: #fff;
    border-bottom: 1px solid #dddddd;
}
.textuppercase {
    text-transform: uppercase;
}

/* RX COL */
.rxcol-30 {
  width: 30%;
  float: left;
  padding: 30px;
}
.rxcol-40 {
  width: 40%;
  float: left;
  padding: 30px;
}
.rxcol-35 {
  width: 35%;
  float: left;
  padding: 30px;
}
.rxcol-65 {
  width: 65%;
  float: left;
  padding: 30px;
}
.rxcol-50 {
  width: 50%;
  float: left;
  padding: 30px;
}
.rxcol-60 {
  width: 60%;
  float: left;
  padding: 30px;
}
.rxcol-70 {
  width: 70%;
  float: left;
  padding: 30px;
}
.rxcol-100 {
  width: 100%;
  float: left;
  /*padding: 30px;*/
}
@media (max-width: 1330px) {
  .rxcol-35 {
    width: 100%;
    float: left;
    padding: 30px;
  }
  .rxcol-65 {
    width: 100%;
    float: left;
    padding: 30px;
  }
}
@media (max-width: 1250px) {
  .rxcol-30 {
    width: 100%;
    float: left;
    padding: 30px;
  }
  .rxcol-70 {
    width: 100%;
    float: left;
    padding: 30px;
  }
}

.col-md-padleft-big {
  padding-left: 60px;
}
/* BOOTSTRAP COL */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}
@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
  .mobilehide {
    display: none;
  }
}
@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}
@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
  .betable__main {
    overflow: auto;
    padding: 5px 25px;
    /* max-height: 300px; */
  }
}
@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}
@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}
@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}
@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}
.visible-print {
  display: none !important;
}
@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}
.visible-print-block {
  display: none !important;
}
@media print {
  .visible-print-block {
    display: block !important;
  }
}
.visible-print-inline {
  display: none !important;
}
@media print {
  .visible-print-inline {
    display: inline !important;
  }
}
.visible-print-inline-block {
  display: none !important;
}
@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}
@media print {
  .hidden-print {
    display: none !important;
  }
}
.container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .container {
    /*width: 1170px;*/
    width: 1080px;
  }
}
.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
}
.col, .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}
.col, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12 {
  float: left;
}
.col-xs-12 {
  width: 100%;
}
.col-xs-11 {
  width: 91.66666667%;
}
.col-xs-10 {
  width: 83.33333333%;
}
.col-xs-9 {
  width: 75%;
}
.col-xs-8 {
  width: 66.66666667%;
}
.col-xs-7 {
  width: 58.33333333%;
}
.col-xs-6 {
  width: 50%;
}
.col-xs-5 {
  width: 41.66666667%;
}
.col-xs-4 {
  width: 33.33333333%;
}
.col-xs-3 {
  width: 25%;
}
.col-xs-2 {
  width: 16.66666667%;
}
.col-xs-1 {
  width: 8.33333333%;
}
.col-xs-pull-12 {
  right: 100%;
}
.col-xs-pull-11 {
  right: 91.66666667%;
}
.col-xs-pull-10 {
  right: 83.33333333%;
}
.col-xs-pull-9 {
  right: 75%;
}
.col-xs-pull-8 {
  right: 66.66666667%;
}
.col-xs-pull-7 {
  right: 58.33333333%;
}
.col-xs-pull-6 {
  right: 50%;
}
.col-xs-pull-5 {
  right: 41.66666667%;
}
.col-xs-pull-4 {
  right: 33.33333333%;
}
.col-xs-pull-3 {
  right: 25%;
}
.col-xs-pull-2 {
  right: 16.66666667%;
}
.col-xs-pull-1 {
  right: 8.33333333%;
}
.col-xs-pull-0 {
  right: auto;
}
.col-xs-push-12 {
  left: 100%;
}
.col-xs-push-11 {
  left: 91.66666667%;
}
.col-xs-push-10 {
  left: 83.33333333%;
}
.col-xs-push-9 {
  left: 75%;
}
.col-xs-push-8 {
  left: 66.66666667%;
}
.col-xs-push-7 {
  left: 58.33333333%;
}
.col-xs-push-6 {
  left: 50%;
}
.col-xs-push-5 {
  left: 41.66666667%;
}
.col-xs-push-4 {
  left: 33.33333333%;
}
.col-xs-push-3 {
  left: 25%;
}
.col-xs-push-2 {
  left: 16.66666667%;
}
.col-xs-push-1 {
  left: 8.33333333%;
}
.col-xs-push-0 {
  left: auto;
}
.col-xs-offset-12 {
  margin-left: 100%;
}
.col-xs-offset-11 {
  margin-left: 91.66666667%;
}
.col-xs-offset-10 {
  margin-left: 83.33333333%;
}
.col-xs-offset-9 {
  margin-left: 75%;
}
.col-xs-offset-8 {
  margin-left: 66.66666667%;
}
.col-xs-offset-7 {
  margin-left: 58.33333333%;
}
.col-xs-offset-6 {
  margin-left: 50%;
}
.col-xs-offset-5 {
  margin-left: 41.66666667%;
}
.col-xs-offset-4 {
  margin-left: 33.33333333%;
}
.col-xs-offset-3 {
  margin-left: 25%;
}
.col-xs-offset-2 {
  margin-left: 16.66666667%;
}
.col-xs-offset-1 {
  margin-left: 8.33333333%;
}
.col-xs-offset-0 {
  margin-left: 0%;
}
@media (min-width: 768px) {
  .col, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 992px) {
  .col, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}
@media (min-width: 1200px) {
  .col, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}
.clearfix,
.clearfix:before,
.clearfix:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}
.clearfix:after,
.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.pull-right {
  float: right !important;
}
.pull-left {
  float: left !important;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}

.rxsideactive_back {
  display: none;
}
.adtopbar__togglesidebar {
  /* display: none; */
}
.rx-fixed-sidebar {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.54);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  transform: withTranslationZ(0px);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .adform {
    padding: 15px;
  }
  .title-main {
    padding-top: 25px;
  }
  .adform__body {
    padding: 15px;
    display: block;
    overflow: hidden;
  }
  .adform__body>div {
    padding: 0 ;
  }
  .class_avatar {
    height: 200px;
    max-width: 150px;
    padding: 10px;
  }
  .class_avatar img {
    height: 150px;
  }
  .text-center.form-avatar-sub {
    padding-top: 10px;
  }
  .form-header-right.barcode {
    max-width: 150px;
    height: 200px;
    padding: 10px;
  }
  .adtopbar__addcard.hotline {
    background-color: unset;
    padding: 5px 10px;
  }
  .adblock {
    padding-top: 0;
  }
  .adtopbar__left {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #4b4b4b;
  }
  .adtopbar__center {
    justify-content: flex-end;
    right: 20px;
    height: 70px;
    top: 0;
  }
  .adtopbar__right {
    top: 70px;
    height: calc(100% - 70px);
  }
  .adtopbar {
    position: relative;
    height: 123px;
    background-image: unset;
  }
  .adminnav__avatarmenu, .adminnav__menunoti {
    top: 53px;
  }
  .adtopbar__togglesidebar {
    top: 71px;
    display: block;
    padding: 13px;
    background-color: #295a93;
    cursor: pointer;
    position: absolute;
  }
  .adminnav__notify {
    padding: 20px 13px 5px !important;
  }
  span.icon-bellnum {
    font-size: 18px;
    width: 30px;
    height: 30px;
    padding: 15px 0px;
  }
  .beside {
    display: none;
    overflow: hidden;
    top: 123px;
  }
  .beside.rxsideactive {
    display: block;
    z-index: 1000;
  }
  .rxsideactive_back {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    left: 0px;
    top: 0px;
    z-index: 999;
  }
  .bemain {
    padding-left: 0px;
  }
  .findexpand_body .col-md-12 {
    width: 100%;
  }
  .findexpand_body .rx--btn__search {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .betable__btns.filter {
    display: block;
  }
  .betable__btns.filter .betable__btnimport, .betable__btns.filter .betable__typecard--select {
    width: 100%;
    position: relative;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .button_action {
    margin: 0;
  }
  .col-md-2.col-cus-md2 {
    width: unset;
  }
  .slide__next {
    display: none;
  }
  .slide__back {
    display: none;
  }
  .slide__itemimg {
    display: none;
  }
  .bemain__wrap {
    padding: 0px;
  }
  .betable__main {
    overflow: auto;
    overflow-x:auto;
    padding: 5px 15px;
  }
  .adminnav__logoimgdesktop {
    display: none;
  }
  .btn {
    padding: 5px 10px;
  }
  .searchbox__toggle {
    left: 0;
    top: 40px;
    width: 100%;
  }
  .searchbox__toggle>input {
    margin: 0;
  }
  .adform__toggle {
    margin-top: 40px;
  }
  .adminnav__logoimg {
    float: left;
    cursor: pointer;
    height: 60px;
    padding: 2px;
    width: 70px;
    display: block;
    object-fit: cover;
    object-position: left;
    background-color: transparent;
  }
  .homenav__left {
    display: none;
  }
  .slide__itemgif {
    display: none;
  }
  .adform__body {
    padding: 20px;
  }
  .adform__body>div {
    width: 100%;
  }
  .adblock__body {
    padding: 30px 0px;
  }
  .adblock__head {
    padding: 1px 30px;
  }
  .betable__filter {
    padding: 0 15px;
  }
  .rxpagingwrap {
    padding: 15px;
  }
  .rxpaging {
    right: 15px;
  }
  .adminnav__avatarmenu {
    top: 50px;
  }
  .adform {
    padding: 15px;
  }
  .rx-form__info {
    grid-template-columns: repeat(1, 1fr);
  }
  .rx-form__item>div {
    display: block;
  }
  .adtopbar__logo--sub {
    font-size: 14px;
  }
  .adtopbar__left {
    position: absolute;
    border-bottom: none;
    justify-content: center;
    padding: 0;
  }
  .adtopbar__left img {
    width: 40px;
    height: 40px;
  }
  .adtopbar__center {
    justify-content: center;
    height: 40px;
    top: 45px;
    right: 0;
    border-bottom: 1px solid #4b4b4b;
  }
  .adtopbar__title {
    font-size: 18px;
  }
  .adtopbar__togglesidebar {
    top: 85px;
  }
  .adtopbar__right {
    top: 85px;
    height: calc(100% - 85px);
  }
  .adtopbar {
    height: 137px;
  }
  .adtopbar__right .adminnav__avatar {
    width: 50px;
    padding: 10px;
  }
  .adtopbar__right .adminnav__avatar>span {
    font-size: 25px;
    justify-content: center;
  }
  .mobilehide {
    display: none;
  }
}

@font-face {
  font-family: 'icomooncustom';
  src:  url(/static/media/icomooncustom.677a2922.eot);
  src:  url(/static/media/icomooncustom.677a2922.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomooncustom.5c6fdf59.ttf) format('truetype'),
    url(/static/media/icomooncustom.498054d4.woff) format('woff'),
    url(/static/media/icomooncustom.4fc76d7c.svg#icomooncustom) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconcustom-"], [class*=" iconcustom-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomooncustom' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconcustom-cmnd:before {
  content: "\e90b";
}
.iconcustom-Group-2:before {
  content: "\e90c";
}
.iconcustom-uniE90D:before {
  content: "\e90d";
}
.iconcustom-uniE90E:before {
  content: "\e90e";
}
.iconcustom-record:before {
  content: "\e90f";
  color: #ffffff;
}
.iconcustom-find:before {
  content: "\e910";
}
.iconcustom-home:before {
  content: "\e911";
  color: #ffffff;
}
.iconcustom-card:before {
  content: "\e912";
  color: #ffffff;
}
.iconcustom-acc:before {
  content: "\e913";
}
.iconcustom-system:before {
  content: "\e914";
  color: #ffffff;
}
.iconcustom-setting:before {
  content: "\e915";
  color: #ffffff;
}
.iconcustom-down:before {
  content: "\e916";
  color: #ffffff;
}
.iconcustom-up:before {
  content: "\e917";
}
.iconcustom-require:before {
  content: "\e918";
}
.iconcustom-avatar:before {
  content: "\e919";
}
.iconcustom-Mask-Group-2-1:before {
  content: "\e900";
  color: #fff;
}
.iconcustom-plus:before {
  content: "\e901";
}
.iconcustom-Mask-Group-2-3:before {
  content: "\e902";
  color: #be3e3e;
}
.iconcustom-Mask-Group-2-4:before {
  content: "\e903";
}
.iconcustom-Mask-Group-2-5:before {
  content: "\e904";
}
.iconcustom-Mask-Group-2-6:before {
  content: "\e905";
  color: #000;
}
.iconcustom-Mask-Group-2-7:before {
  content: "\e906";
  color: #fff;
}
.iconcustom-Mask-Group-2-8:before {
  content: "\e907";
}
.iconcustom-check:before {
  content: "\e908";
}
.iconcustom-Mask-Group-2-10:before {
  content: "\e909";
  color: #dbdbdb;
}
.iconcustom-Mask-Group-2:before {
  content: "\e90a";
}
.iconcustom-active:before {
  content: "\e91a";
}
.iconcustom-close:before {
  content: "\e91b";
  color: #919191;
}
.iconcustom-create:before {
  content: "\e91c";
  color: #fff;
}
.iconcustom-date:before {
  content: "\e91d";
}
.iconcustom-delete:before {
  content: "\e91e";
}
.iconcustom-export:before {
  content: "\e91f";
}
.iconcustom-export2:before {
  content: "\e920";
  color: #fff;
}
.iconcustom-import:before {
  content: "\e921";
}
.iconcustom-print:before {
  content: "\e922";
  color: #dbdbdb;
}
.iconcustom-sort .path1:before {
  content: "\e923";
  color: rgb(49, 55, 62);
}
.iconcustom-sort .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgba(49, 55, 62, 0.3);
}
.iconcustom-sign:before {
  content: "\e925";
  color: #0f348b;
}
.iconcustom-sign1:before {
  content: "\e926";
  color: #0f348b;
}
.icon-images:before {
  content: "\e914";
}

@font-face {
  font-family: 'icomoon';
  src:  url(/static/media/icomoon.5c4b141f.eot);
  src:  url(/static/media/icomoon.5c4b141f.eot#iefix) format('embedded-opentype'),
    url(/static/media/icomoon.4e5ae7cd.ttf) format('truetype'),
    url(/static/media/icomoon.2ec5b811.woff) format('woff'),
    url(/static/media/icomoon.9a28d106.svg#icomoon) format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-uniE900:before {
  content: "\e900";
}
.icomoon-uniE901:before {
  content: "\e901";
}
.icomoon-uniE902:before {
  content: "\e902";
}
.icomoon-uniE903:before {
  content: "\e903";
}
.icomoon-uniE904:before {
  content: "\e904";
}
.icomoon-uniE905:before {
  content: "\e905";
}
.icomoon-uniE906:before {
  content: "\e906";
}
.icomoon-uniE907:before {
  content: "\e907";
}
.icomoon-uniE908:before {
  content: "\e908";
}
.icomoon-uniE909:before {
  content: "\e909";
}
.icomoon-uniE90A:before {
  content: "\e90a";
}
.icomoon-uniE90B:before {
  content: "\e90b";
}
.icomoon-uniE90C:before {
  content: "\e90c";
}
.icomoon-uniE90D:before {
  content: "\e90d";
}
.icomoon-uniE90E:before {
  content: "\e90e";
}
.icomoon-uniE90F:before {
  content: "\e90f";
}
.icomoon-uniE910:before {
  content: "\e910";
}
.icomoon-uniE911:before {
  content: "\e911";
}
.icomoon-uniE912:before {
  content: "\e912";
}
.icomoon-uniE913:before {
  content: "\e913";
}
.icomoon-uniE914:before {
  content: "\e914";
}
.icomoon-uniE915:before {
  content: "\e915";
}
.icomoon-uniE916:before {
  content: "\e916";
}
.icomoon-uniE917:before {
  content: "\e917";
}
.icomoon-uniE918:before {
  content: "\e918";
}
.icomoon-uniE919:before {
  content: "\e919";
}
.icomoon-uniE91A:before {
  content: "\e91a";
}
.icomoon-uniE91B:before {
  content: "\e91b";
}
.icomoon-uniE91C:before {
  content: "\e91c";
}
.icomoon-uniE91D:before {
  content: "\e91d";
}
.icomoon-uniE91E:before {
  content: "\e91e";
}
.icomoon-uniE91F:before {
  content: "\e91f";
}
.icomoon-uniE920:before {
  content: "\e920";
}
.icomoon-uniE921:before {
  content: "\e921";
}
.icomoon-uniE922:before {
  content: "\e922";
}
.icomoon-uniE923:before {
  content: "\e923";
}
.icomoon-uniE924:before {
  content: "\e924";
}
.icomoon-uniE925:before {
  content: "\e925";
}
.icomoon-uniE926:before {
  content: "\e926";
}
.icomoon-uniE927:before {
  content: "\e927";
}
.icomoon-uniE928:before {
  content: "\e928";
}
.icomoon-uniE929:before {
  content: "\e929";
}
.icomoon-uniE92A:before {
  content: "\e92a";
}
.icomoon-uniE92B:before {
  content: "\e92b";
}
.icomoon-uniE92C:before {
  content: "\e92c";
}
.icomoon-uniE92D:before {
  content: "\e92d";
}
.icomoon-uniE92E:before {
  content: "\e92e";
}
.icomoon-uniE92F:before {
  content: "\e92f";
}
.icomoon-uniE930:before {
  content: "\e930";
}
.icomoon-uniE931:before {
  content: "\e931";
}
.icomoon-uniE932:before {
  content: "\e932";
}
.icomoon-uniE933:before {
  content: "\e933";
}
.icomoon-uniE934:before {
  content: "\e934";
}
.icomoon-uniE935:before {
  content: "\e935";
}
.icomoon-uniE936:before {
  content: "\e936";
}
.icomoon-uniE937:before {
  content: "\e937";
}
.icomoon-uniE938:before {
  content: "\e938";
}
.icomoon-uniE939:before {
  content: "\e939";
}
.icomoon-uniE93A:before {
  content: "\e93a";
}
.icomoon-uniE93B:before {
  content: "\e93b";
}
.icomoon-uniE93C:before {
  content: "\e93c";
}
.icomoon-uniE93D:before {
  content: "\e93d";
}
.icomoon-uniE93E:before {
  content: "\e93e";
}
.icomoon-uniE93F:before {
  content: "\e93f";
}
.icomoon-uniE940:before {
  content: "\e940";
}
.icomoon-uniE941:before {
  content: "\e941";
}
.icomoon-uniE942:before {
  content: "\e942";
}
.icomoon-ArrowBack:before {
  content: "\e943";
}
.icomoon-ArrowNext:before {
  content: "\e944";
}
.icomoon-Bar:before {
  content: "\e945";
}
.icomoon-Cart:before {
  content: "\e946";
}
.icomoon-Check:before {
  content: "\e947";
}
.icomoon-Close:before {
  content: "\e948";
}
.icomoon-Icon1:before {
  content: "\e949";
}
.icomoon-Icon2:before {
  content: "\e94a";
}
.icomoon-Icon3:before {
  content: "\e94b";
}
.icomoon-Icon4:before {
  content: "\e94c";
}
.icomoon-Facebook:before {
  content: "\e94d";
}
.icomoon-Google:before {
  content: "\e94e";
}
.icomoon-Shopee:before {
  content: "\e94f";
}
.icomoon-Youtube:before {
  content: "\e950";
}
.icomoon-Zalo:before {
  content: "\e951";
}
.icomoon-Star:before {
  content: "\e952";
}
.icomoon-StarFull:before {
  content: "\e953";
}
.icomoon-StarHalf:before {
  content: "\e954";
}
.icomoon-Email:before {
  content: "\e955";
}
.icomoon-FAQ:before {
  content: "\e956";
}
.icomoon-Info:before {
  content: "\e957";
}
.icomoon-Order:before {
  content: "\e958";
}
.icomoon-Return:before {
  content: "\e959";
}
.icomoon-Ship:before {
  content: "\e95a";
}
.icomoon-uniE95B:before {
  content: "\e95b";
}
.icomoon-uniE95C:before {
  content: "\e95c";
}
.icomoon-uniE95D:before {
  content: "\e95d";
}
.icomoon-uniE95E:before {
  content: "\e95e";
}
.icomoon-uniE95F:before {
  content: "\e95f";
}
.icomoon-uniE960:before {
  content: "\e960";
}
.icomoon-uniE961:before {
  content: "\e961";
}
.icomoon-uniE962:before {
  content: "\e962";
}
.icomoon-Delete:before {
  content: "\e963";
}
.icomoon-Left:before {
  content: "\e964";
}
.icomoon-Right:before {
  content: "\e965";
}
.icomoon-Down:before {
  content: "\e966";
}
.icomoon-Up:before {
  content: "\e967";
}
.icomoon-Start:before {
  content: "\e968";
}
.icomoon-End:before {
  content: "\e969";
}
.icomoon-Minus:before {
  content: "\e96a";
}
.icomoon-Plus:before {
  content: "\e96b";
}
.icomoon-Home:before {
  content: "\e96c";
}
.icomoon-Profile:before {
  content: "\e96d";
}
.icomoon-Heart:before {
  content: "\e96e";
}
.icomoon-HeartSolid:before {
  content: "\e96f";
}
.icomoon-Rectangle:before {
  content: "\e970";
}
.icomoon-Search:before {
  content: "\e971";
}
.icomoon-Zoom:before {
  content: "\e972";
}
.icomoon-Write:before {
  content: "\e973";
}
.icomoon-Edit:before {
  content: "\e974";
}
.icomoon-Like:before {
  content: "\e975";
}
.icomoon-phone:before {
  content: "\e976";
}
.icomoon-Reply:before {
  content: "\e977";
}
.icomoon-Send:before {
  content: "\e978";
}
.icomoon-Share:before {
  content: "\e979";
}
.icomoon-Shield:before {
  content: "\e97a";
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
  padding: 0;
  border: 0;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time, .react-datepicker--time-only .react-datepicker__time-box {
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement="bottom-end"] .react-datepicker__triangle, .react-datepicker-popper[data-placement="top-end"] .react-datepicker__triangle {
  left: auto;
  right: 50px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__header--time:not(.react-datepicker__header--time--only) {
  border-top-left-radius: 0;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 95px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__year {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  max-width: 180px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  display: inline-block;
  width: 4rem;
  margin: 2px;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__month--selected, .react-datepicker__month--in-selecting-range, .react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__month--selected:hover, .react-datepicker__month--in-selecting-range:hover, .react-datepicker__month--in-range:hover,
.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter--in-selecting-range:hover,
.react-datepicker__quarter--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc;
  pointer-events: none;
}

.react-datepicker__month--disabled:hover,
.react-datepicker__quarter--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__day,
.react-datepicker__month-text,
.react-datepicker__quarter-text,
.react-datepicker__year-text {
  cursor: pointer;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted,
.react-datepicker__month-text--highlighted,
.react-datepicker__quarter-text--highlighted,
.react-datepicker__year-text--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover,
.react-datepicker__month-text--highlighted:hover,
.react-datepicker__quarter-text--highlighted:hover,
.react-datepicker__year-text--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1,
.react-datepicker__month-text--highlighted-custom-1,
.react-datepicker__quarter-text--highlighted-custom-1,
.react-datepicker__year-text--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2,
.react-datepicker__month-text--highlighted-custom-2,
.react-datepicker__quarter-text--highlighted-custom-2,
.react-datepicker__year-text--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range ,
.react-datepicker__month-text--in-selecting-range ,
.react-datepicker__quarter-text--in-selecting-range ,
.react-datepicker__year-text--in-selecting-range {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range , .react-datepicker__month--selecting-range
.react-datepicker__month-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__quarter-text--in-range , .react-datepicker__month--selecting-range
.react-datepicker__year-text--in-range {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover,
.react-datepicker__month-text--disabled:hover,
.react-datepicker__quarter-text--disabled:hover,
.react-datepicker__year-text--disabled:hover {
  background-color: transparent;
}

.react-datepicker__month-text.react-datepicker__month--selected:hover, .react-datepicker__month-text.react-datepicker__month--in-range:hover, .react-datepicker__month-text.react-datepicker__quarter--selected:hover, .react-datepicker__month-text.react-datepicker__quarter--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__month--selected:hover,
.react-datepicker__quarter-text.react-datepicker__month--in-range:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--selected:hover,
.react-datepicker__quarter-text.react-datepicker__quarter--in-range:hover {
  background-color: #216ba5;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #f0f0f0;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

.react-datepicker__close-icon::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: "\00d7";
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.rxpopup-addnew-cus-- {
    position: relative;
    width: 324px;
    height: 204px;
    z-index: 9999999;
}
.rxpopup-addnew-cus--.printer--all {
    page-break-after: always;
    position: relative;
    z-index: 999999999;
}
/*.avatar_print--new {
    width: 70.5pt;
    height: 82.5pt;
    overflow: hidden;
    position: relative;
    margin-top: 19pt;
    margin-left: 19pt;
    z-index: 9999999;
}*/
.avatar_print--new {
    width: 55.7pt;
    height: 82.5pt;
    overflow: hidden;
    position: absolute;
    margin-top: 18.5pt;
    margin-left: 16.5pt;
    z-index: 9999999;
}
.avatar_print--new img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 9999999;
}
.form-header--new {
	display: flex;
	width: 100% !important;
	position: relative;
}
/*.textCenter__print--new {
    padding-left: 6pt;
    padding-top: 46pt;
    margin-right: 12pt;
    max-width: calc(100% - 89.5pt);
}*/
.textCenter__print--new {
    padding-left: 77pt;
    padding-top: 46pt;
    margin-right: 12pt;
}
.text__print--new, .text__print--coquan {
    display: flex;
}
.text__print--coquan1 {
    display: block;
    line-height: 9px;
}
.text__print--coquan1 span {
    float: unset;
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 10px;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--coquan span {
    line-height: 11px !important;
}
.text__print--new span, .text__print--coquan span {
	float: left;
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.4;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--new span:first-child {
    /*font-weight: normal !important;*/
}
.textName___print--new {
    text-transform: uppercase;
}
.text__subname {
    text-transform: capitalize
}
.form-header-qrcode {
	position: absolute;
    top: 9.6pt;
    right: 14.2pt;
    z-index: 9999999;
}
.form-header-qrcode canvas {
    position: relative;
    z-index: 9999999;
}
.rxprint__info--new {
    position: absolute;
    left: 12.6pt;
    top: 108pt;
}
.rxprint__sign--new {
    position: absolute;
    top: 96pt;
    right: 10pt;
    width: 135pt;
}
.adblock--new {
    width: 100%;
    height: 100%;
    position: relative;
}
.adblock__label--new {
    width: 100%;
    height: 100%;
    /*background-image: url(/images/static/background_card.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;*/
}
.text__info {
	font-size: 9px;
    font-weight: normal;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__info span {
	font-weight: bold;
	font-size: 9px;
	/*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__sign {
	text-align: center;
	font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.img__sign2 {
    display: flex;
    justify-content: center;
    margin-left: 48px;
    height: 38px;
    font-size: 36px;
}
.img__sign1 {
    display: flex;
    justify-content: center;
    margin-left: 22px;
    height: 38px;
    font-size: 38px;
}
.img__sign {
    display: flex;
    justify-content: center;
    margin-left: 65px;
    height: 42px;
    margin-top: -6pt;
    font-size: 55px;
    transform: rotate(10deg);
    margin-bottom: 7pt;
}
.img__sign img {
    height: 100%;
    width: auto;
    object-fit: contain;
    position: relative;
    z-index: 9999999;
}
.textOrganization___print--new {
    white-space: pre-wrap !important;
}
.textOrganization___print--nowrap {
    white-space: nowrap !important;
}
.textOrganization___print--coquan {
    white-space: nowrap !important;
    font-size: 8px;
}
.form-header-qrcode canvas {
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--pre {
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    position: relative;
    z-index: 9999999;
    opacity: 1;
    text-align: justify;
}
.rxprint__dau {

}

@media print{
    .toast-container, .betable.adblock__inner .fixed {
        z-index: 1;
    }
    .progress-bar {
        display: none !important;
        z-index: 1;
    }
}

