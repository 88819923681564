.rxtoggle-container {
    display: block;
    position: relative;
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 16px;
    width: 45px;
    height: 25px;
    border-radius: 30px;
    background: #f8f8f8;
    cursor: pointer;
    box-sizing: border-box;
}

.rxtoggle-style1 {
    -webkit-box-shadow: inset 0 0 0 0px rgba(19,191,17,1), 0 0 0 2px #dddddd;
    -moz-box-shadow: inset 0 0 0 0px rgba(19,191,17,1),0 0 0 2px #dddddd;
    box-shadow: inset 0 0 0 0px rgba(19,191,17,1), 0 0 0 2px #dddddd;
}

.rxtoggle-style1.rx-active {
    -webkit-box-shadow: inset 0 0 0 18px rgb(22, 125, 251), 0 0 0 2px rgb(22, 114, 218);
    -moz-box-shadow: inset 0 0 0 18px rgb(22, 125, 251), 0 0 0 2px rgb(22, 114, 218);
    box-shadow: inset 0 0 0 18px rgb(22, 125, 251), 0 0 0 2px rgb(22, 114, 218);
    background: #12bf11;
}

.rxtoggle-inner {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    line-height: 34px;
    text-indent: 40px;
    height: 25px;
    width: 25px;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    top: 0px;
    left: 0px;
    right: auto;
    background: white;
    -webkit-box-shadow: 0 3px 3px rgba(0,0,0,.2), 0 0 0 2px #dddddd;
    -moz-box-shadow: 0 3px 3px rgba(0,0,0,.2),0 0 0 2px #dddddd;
    box-shadow: 0 3px 3px rgba(0,0,0,.2), 0 0 0 2px #dddddd;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
    outline: none;
    font-family: Helvetica Neue,helvetica,arial,verdana,sans-serif;
}

.rx-active > .rxtoggle-inner{
    left: calc(100% - 25px);
    -webkit-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0,0,0,.3);
    -moz-box-shadow: 0 0 0 2px transparent,0 3px 3px rgba(0,0,0,.3);
    box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0,0,0,.3);
}