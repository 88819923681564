.rxsideactive_back {
  display: none;
}
.adtopbar__togglesidebar {
  /* display: none; */
}
.rx-fixed-sidebar {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.54);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  transform: withTranslationZ(0px);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  pointer-events: auto;
  cursor: pointer;
}
@media only screen and (max-width: 1024px) {
  .adform {
    padding: 15px;
  }
  .title-main {
    padding-top: 25px;
  }
  .adform__body {
    padding: 15px;
    display: block;
    overflow: hidden;
  }
  .adform__body>div {
    padding: 0 ;
  }
  .class_avatar {
    height: 200px;
    max-width: 150px;
    padding: 10px;
  }
  .class_avatar img {
    height: 150px;
  }
  .text-center.form-avatar-sub {
    padding-top: 10px;
  }
  .form-header-right.barcode {
    max-width: 150px;
    height: 200px;
    padding: 10px;
  }
  .adtopbar__addcard.hotline {
    background-color: unset;
    padding: 5px 10px;
  }
  .adblock {
    padding-top: 0;
  }
  .adtopbar__left {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    margin-left: 0;
    padding-left: 20px;
    padding-bottom: 10px;
    border-bottom: 1px solid #4b4b4b;
  }
  .adtopbar__center {
    justify-content: flex-end;
    right: 20px;
    height: 70px;
    top: 0;
  }
  .adtopbar__right {
    top: 70px;
    height: calc(100% - 70px);
  }
  .adtopbar {
    position: relative;
    height: 123px;
    background-image: unset;
  }
  .adminnav__avatarmenu, .adminnav__menunoti {
    top: 53px;
  }
  .adtopbar__togglesidebar {
    top: 71px;
    display: block;
    padding: 13px;
    background-color: #295a93;
    cursor: pointer;
    position: absolute;
  }
  .adminnav__notify {
    padding: 20px 13px 5px !important;
  }
  span.icon-bellnum {
    font-size: 18px;
    width: 30px;
    height: 30px;
    padding: 15px 0px;
  }
  .beside {
    display: none;
    overflow: hidden;
    top: 123px;
  }
  .beside.rxsideactive {
    display: block;
    z-index: 1000;
  }
  .rxsideactive_back {
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.4);
    left: 0px;
    top: 0px;
    z-index: 999;
  }
  .bemain {
    padding-left: 0px;
  }
  .findexpand_body .col-md-12 {
    width: 100%;
  }
  .findexpand_body .rx--btn__search {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  .betable__btns.filter {
    display: block;
  }
  .betable__btns.filter .betable__btnimport, .betable__btns.filter .betable__typecard--select {
    width: 100%;
    position: relative;
    margin-top: 2px;
  }
}
@media (max-width: 767px) {
  .button_action {
    margin: 0;
  }
  .col-md-2.col-cus-md2 {
    width: unset;
  }
  .slide__next {
    display: none;
  }
  .slide__back {
    display: none;
  }
  .slide__itemimg {
    display: none;
  }
  .bemain__wrap {
    padding: 0px;
  }
  .betable__main {
    overflow: auto;
    overflow-x:auto;
    padding: 5px 15px;
  }
  .adminnav__logoimgdesktop {
    display: none;
  }
  .btn {
    padding: 5px 10px;
  }
  .searchbox__toggle {
    left: 0;
    top: 40px;
    width: 100%;
  }
  .searchbox__toggle>input {
    margin: 0;
  }
  .adform__toggle {
    margin-top: 40px;
  }
  .adminnav__logoimg {
    float: left;
    cursor: pointer;
    height: 60px;
    padding: 2px;
    width: 70px;
    display: block;
    object-fit: cover;
    object-position: left;
    background-color: transparent;
  }
  .homenav__left {
    display: none;
  }
  .slide__itemgif {
    display: none;
  }
  .adform__body {
    padding: 20px;
  }
  .adform__body>div {
    width: 100%;
  }
  .adblock__body {
    padding: 30px 0px;
  }
  .adblock__head {
    padding: 1px 30px;
  }
  .betable__filter {
    padding: 0 15px;
  }
  .rxpagingwrap {
    padding: 15px;
  }
  .rxpaging {
    right: 15px;
  }
  .adminnav__avatarmenu {
    top: 50px;
  }
  .adform {
    padding: 15px;
  }
  .rx-form__info {
    grid-template-columns: repeat(1, 1fr);
  }
  .rx-form__item>div {
    display: block;
  }
  .adtopbar__logo--sub {
    font-size: 14px;
  }
  .adtopbar__left {
    position: absolute;
    border-bottom: none;
    justify-content: center;
    padding: 0;
  }
  .adtopbar__left img {
    width: 40px;
    height: 40px;
  }
  .adtopbar__center {
    justify-content: center;
    height: 40px;
    top: 45px;
    right: 0;
    border-bottom: 1px solid #4b4b4b;
  }
  .adtopbar__title {
    font-size: 18px;
  }
  .adtopbar__togglesidebar {
    top: 85px;
  }
  .adtopbar__right {
    top: 85px;
    height: calc(100% - 85px);
  }
  .adtopbar {
    height: 137px;
  }
  .adtopbar__right .adminnav__avatar {
    width: 50px;
    padding: 10px;
  }
  .adtopbar__right .adminnav__avatar>span {
    font-size: 25px;
    justify-content: center;
  }
  .mobilehide {
    display: none;
  }
}
