.rx-cursor.rxactive {
    background-color: #03A9F4 !important;
    color: #fff;
}
.text-left-fix-with {
  width: 400px;
  display: inline-block;
}
.button_right {
  float: right !important;
}
.rxnote { color: red; font-style: italic;}
.rxform--province {
  width: 100%;
  display: flex;
  align-items: center;
}
.adform.top-0 {
  top: 0;
}
.proges-text {
  font-size: 16px;
  padding-left: 10px;
  font-weight: 500;
}
.proges-total {
  font-size: 15px;
  padding-top: 10px;
}
.adform__btn--save:last-child {
    margin-right: 0px;
}
.adform__btn--save {
    margin-right: 10px;
    font-size: 15px;
}
.betable__print {
  position: relative;
  display: inline-flex;
  width: 100%;
}
.betable__print>div {
  margin-right: 15px;
}
/* .betable__print>div:last-child {
  margin-left: 15px;
} */
.betable__print>div {
  /*display: flex;
  align-items: center;*/
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 30%;
  padding-top: 10px;
}
.betable__print .react-datepicker-wrapper {
    width: 200px;
    margin: auto;
}
.betable__print>div>div {
  margin-right: 10px;
  font-size: 15px;
}
.select--province {
  margin-left: 10px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  min-height: 42px;
  border-radius: 4px;
}
.toast-container {
    position: fixed;
    z-index: 999999;
}
.toast-top-right {
    top: 70px;
    right: 12px;
}
.betable__inner td.active {
    /* background-color: #4CAF50; */
    text-align: left;
    vertical-align: middle;
    color: #4CAF50;
    font-weight: bold;

}
.icon__download {
    position: absolute;
    right: 50px;
    top: 10px;
    font-size: 28px;
    cursor: pointer;
    color: #ffffff;
    z-index: 2;
    border-radius: 3px;
}
.img__multi--content {
  display: flex;
  justify-content: center;
  position: relative;
}
.img__multi--content img {
  height: 100%;
  width: auto;
  max-width: 100%;
  object-fit: contain;
}
.betable__inner td.unactive {
    text-align: left;
    vertical-align: middle;
    color: red;
    font-weight: bold;
    /* background-color: #FFEB3B; */
}
.rxtext__history {
  cursor: pointer;
}
.rxcrud__toggle--item {
    border-bottom: 1px solid #eee;
    position: relative;
    width: 100%;
}
.toggle__item--title {
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    background-color: #eee;
    padding: 5px 10px;
}
.toggle__item--title span {
    right: 10px;
    position: absolute;
}
.rxcrud__toggle {
    display: block !important;
    max-height: 500px;
    overflow-y: auto;
}
.toggle__item--desc {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: left;
    padding-bottom: 10px;
}
th.rx-th-width-220 {
    min-width: 100px;
}
.toast {
    position: relative;
    overflow: hidden;
    margin: 0 0 6px;
    padding: 15px 15px 15px 50px;
    width: 300px;
    border-radius: 2px;
    background-position: 15px;
    background-repeat: no-repeat;
    color: #fff;
    opacity: .9;
    filter: alpha(opacity=90);
}
.toast {
    background-color: #20a8d8;
    max-width: 350px;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: 0 0.25rem 0.75rem rgba(0,0,0,.1);
}
.toast-title {
    font-weight: 700;
    padding: 10px 0;
}
.toast-message {
    word-wrap: break-word;
}
.adform__btnedit.adform__btnedit--raw {
  min-height: 42px;
  padding: 15px 30px;
  border-radius: 5px;
}
span.icon-bellnum {
    background-color: #e91e63;
    border-radius: 50%;
    font-size: 20px;
    width: 35px;
    height: 35px;
    position: absolute;
    line-height: 0;
    padding: 18px 0px;
    top: 0;
    color: #fff;
}
.member {
    min-width: 200px;
}
.noti__menu__tab .title {
    padding: 10px 10px;
    background-color: #f0f3f5;
}
.noti__menu__name {
    font-size: 22px;
    font-weight: bolder;
    color: #000;
    text-align: center;
    display: initial !important;
    /*border-bottom: 1px solid #eee;
    padding-bottom: 10px;*/
}
.noti__menu__tab {
    font-weight: bold;
    padding: 0 15px !important;
}
.noti__menu__tab span {
    text-align: right;
    float: right;
    color: red;
}
.dropitem {
    align-items: center;
    width: 100%;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    color: #333;
    padding: 5px 0;
    position: relative;
}
.dropitem>span {
    color: #333;
    float: unset;
}
.dropitem>span {
    text-align: left;
}
.dropitem .datetime {
  position: absolute;
  right: 0;
}
.dropitem>span {
    color: #333;
}
.noti__menu__tab__item {
    border: 1px solid #eee;
    padding: 10px 10px;
    max-height: 150px;
    overflow: scroll;
}
.dropitem:hover {
  background-color: #f0f3f5;
  font-weight: bold;

}

.meter__body__ {
  padding: 15px;
  background: #fff;
}
.meter__icon {
  display: inline-block;
  max-width: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  align-items: center;
  padding: 10px;
  font-size: 25px;
}
.meter__icon.icon1 {
  border: solid 1px #6658dd;
  background-color: rgba(102, 88, 221, 0.25);
}
.meter__icon.icon2 {
  border: solid 1px #1abc9c;
  background-color: rgba(26, 188, 156, 0.25);
}
.meter__icon.icon3 {
  border: solid 1px #4fc6e1;
  background-color: rgba(79, 198, 225, 0.25);
}
.meter__icon.icon4 {
  border: solid 1px red;
  background-color: #fc7b7b;
}
.meter__icon.icon5 {
  border: solid 1px #f7b84b;
  background-color: rgba(247, 184, 75, 0.25);
}
.meter__content {
  display: inline-block;
  text-align: right;
  padding-left: 15px;
  float: right;
}
.meter__footer {
  padding: 12px 25px;
  color: #000;
  font-size: 22px;
  vertical-align: middle;
  align-items: center;
}

.adnav__itemname.adnav__itemname_lv2>a {
  padding: 15px 75px;
}
.adnav__itemname.adnav__itemname_lv3>a {
  padding: 15px 100px;
}
.col-md-2.col-cus-md2 {
  width: 20%;
}
input.betable__findinput.col-md-3 {
  min-height: 42px;
}
select.betable__findinput.col-md-3 {
  min-height: 42px;
}
span.news_inputcheck_radio input {
  width: 50px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
}
.betable__status.betable__status_6 {
  background-color: #bc2b41;
  color: #fff;
}
.betable.adblock__inner .fixed {
  position: fixed;
  top: 70px;
  left: 275px;
  z-index: 1111;
  width: calc(100% - 275px);
  bottom: 0;
}
/*.betable.adblock__inner .fixed .modal {
  margin-left: -200px;
}*/
/* adblock */
.adblock {
  /*background-color: #ffffff;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);*/
  position: relative;
  padding-top: 70px;
}
.form-header-title {
  display: inline-block;
  text-align: center;
  width: 100%;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.text-center {
  text-align: center !important;
  /*padding: 8px;*/
  line-height: 1.4;
  font-weight: normal;
  font-size: 16px;
}
.text-left {
  text-align: left;
  line-height: 1.4;
  font-weight: normal;
}
.text-left b {
  font-size: 18px;
  font-weight: bold;
}
.custom-search .fullwidth-input {
  height: 40px;
}
input#import-excel {
  /* margin-top: 15px; */
  padding: 5px;
  width: 100%;
}
span#buttonSubmitCsv {
  padding: 5px 11px;
  margin-left: 10px;
}
.adblock__head {
  padding: 12px 30px;
  padding-bottom: 0;
  /*background-color: #fafafb;*/
}
.adblock__body {
  padding: 30px;
}
.adblock__body--reducepad {
  padding: 30px 5px;
}
.adblock__inner {
  margin-left: 0px !important;
  margin-right: 0px !important;
  /*box-shadow: 0 1px 5px rgba(0,21,41,.1);
  background-color: #fff;*/
  position: relative;
  /*padding: 30px;*/
}
.adblock__inner-dashboard {
  background-color: transparent;
}
.adblock__title {
  font-size: 22px;
  margin-top: 7px;
  font-weight: bold;
  /*text-transform: capitalize;*/
}
.adblock__desc {
  font-weight: 100;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  margin-bottom: 7px;
  color: #cacaca;
  margin-top: 10px;
}
.adblock__dashboardfake {
  width: 100%;
  display: block;
}
.adblock__sumblock {
  min-height: 200px;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.1);
  margin-bottom: 30px;
  padding: 20px;
  position: relative;
}
.sumblock__title {
  color: #bec0cc;
  font-size: 14px;
  font-weight: 100;
  text-transform: uppercase;
}
.sumblock__title span {
  float: right;
  border: solid 1px;
  width: 16px;
  height: 16px;
  text-align: center;
  border-radius: 50%;
  padding: 7px 0;
  line-height: 0px;
  box-sizing: border-box;
  position: absolute;
  right: 10px;
  top: 18px;
}
.sumblock__num {
  font-size: 25px;
  margin-top: 20px;
  color: #000;
}
.sumblock__num i {
  font-size: 20px;
  margin-left: 3px;
  color: #bec0cc;
}
.sumblock__subnumlabel {
  position: absolute;
  bottom: 48px;
  color: #cacaca;
}
.sumblock__subnum {
  position: absolute;
  bottom: 15px;
  line-height: 1.5;
}
.sumblock__subnum span {
  background-color: #eee;
  padding: 2px 10px;
  border-radius: 10px;
  margin-left: 5px;
}
.sumblock__percent {
  position: absolute;
  float: right;
  right: -1px;
  margin-top: -25px;
  font-size: 17px;
  color: #000;
  padding: 5px 10px;
  border-radius: 10px 0 0 10px;
  font-weight: bold;
  background-color: #e7e9ec;
}
.sumblock__lefttitle div {
  float: left;
  padding: 0 15px 15px 0px;
}
.sumblock__righttitle div {
  float: right;
  padding: 0 10px 15px 11px;
  cursor: pointer;
}
.sumblock__righttitle div.active {
  border-bottom: solid 3px;
  font-weight: bold;
}
.sumblock__wraptitle {
  width: 100%;
  border-bottom: solid 1px #ddd;
  margin-bottom: 15px;
}
.sumblock__charttitle {
  font-weight: bold;
  margin-top: 15px;
}
.sumblock__chartwrap {
  width: 100%;
  height: 200px;
  margin-top: 25px;
}
.sumblock__chartopitem {
  line-height: 28px;
  color: #888;
  padding-left: 30px;
}
.sumblock__chartopitem.active {
  color: #000;
}
.sumblock__chartopitem span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 0px;
  display: inline-block;
  position: absolute;
  margin-top: 4px;
  padding: 10px 0px;
  text-align: center;
  left: 15px;
}
.sumblock__chartopitem.active span {
  background-color: #000;
  color: #fff;
}
.sumblock__metertitle {
  font-weight: bold;
  padding-bottom: 15px;
  border-bottom: solid 1px #ddd;
}
.sumblock__filterbtn {
  float: right;
  font-weight: normal;
}
.sumblock__filterbtn>span:last-child {
  border-right: solid 1px #ddd;
  border-radius: 0 4px 4px 0;
}
.sumblock__filterbtn>span:first-child {
  border-left: solid 1px #ddd;
  border-radius: 4px 0 0 4px;
}
.sumblock__filterbtn>span {
  padding: 5px 10px;
  margin-left: 0px;
  border-top: solid 1px #ddd;
  border-bottom: solid 1px #ddd;
  border-left: solid 1px #ddd;
  font-size: 10px;
}
/* betable */
.betable {
  position: relative;
  padding-bottom: 40px;
}
.betable__filter {
  margin-bottom: 20px;
  display: block;
}
.betable__filter::after {
  content: "";
  clear: both;
  display: table;
}
.betable__main {
  overflow: auto;
  padding: 5px 25px;
  /* max-height: 500px; */
}
.betable__inner {
  width: 100%;
  text-align: left;
}
.betable__inner tbody tr:hover {
  background-color: #e6f7ff;
}
.betable__inner thead {
  background-color: #fff;
  color: #00122b;
  border-top: 1px solid #dee2e6;
  height: 50px;
}
.betable__inner tbody {
  border-bottom: solid 1px #f3f7f9;
}
.betable__inner tbody tr {
  border-top: solid 1px #dee2e6;
}
.betable__inner td, .betable__inner th {
  padding: 10px 5px;
  vertical-align: top;
  line-height: 1.5;
  font-size: 14px;
}
.betable__inner th {
  cursor: pointer;
  padding: 15px 5px;
  font-weight: bold;
  font-size: 16px;
  width: 30px;
  vertical-align: middle;
}
.betable__inner th:last-child {
  text-align: right;
  padding-right: 10px;
}
.betable__inner td:first-child {
  min-height: 55px;
  display: inline-block;
  font-size: 15px;
}
.betable-th {
  text-align: left !important;
  padding-right: 10px;
}

.betable__img {
  width: 30px;
  height: 40px;
  overflow: hidden;
  border-radius: 3px;
  clip-path: circle(50%);
}
.betable__img__ {
  width: 120px;
  height: 60px;
  object-fit: contain;
}

@-webkit-keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}
@-moz-keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}
@-ms-keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}
@-o-keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}
@keyframes color_change {
  from { background-color: #8BC34A; }
  to { background-color: #CDDC39; }
}

.betable__checksuccess {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #8BC34A;
  float: left;
  position: absolute;
  left: 5px;
  margin-top: 35px;
  -webkit-animation: color_change 1s infinite alternate;
  -moz-animation: color_change 1s infinite alternate;
  -ms-animation: color_change 1s infinite alternate;
  -o-animation: color_change 1s infinite alternate;
  animation: color_change 1s infinite alternate;
}

.betable__chartstyle {
  min-width: 80px;
}

.betable__servicename {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #1890ff;
}
.betable__checkfailed {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #E91E63;
  float: left;
  position: absolute;
  left: 5px;
  margin-top: 35px;
}
.betable__checkwrap>small {
  padding-left: 30px;
}
.betable__price {
  color: #E91E63;
  font-weight: bold;
}
.betable__priceold {
  color: #888888;
  margin-left: 5px;
}
.betable__priceold b {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}
.betable__icohot {
  color: #E91E63;
  margin-right: 5px;
}
.betable__iconothot {
  /* margin-right: 38px; */
}
.betable__icoon {
  color: #979ba4;
  padding: 5px 5px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 0px;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: 100;
}
.betable__icooff {
  color: #00112b;
  padding: 5px 5px;
  border-radius: 2px;
  font-size: 14px;
  line-height: 0px;
  margin-right: 10px;
  margin-left: 10px;
}
.betable__btnedit, .betable__btnDisEdit {
  margin-right: 10px;
  cursor: pointer;
  padding: 1px 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 22px;
}
.betable__btndelete {
  margin-right: 10px;
  cursor: pointer;
  padding: 1px 2px;
  border-radius: 2px;
  font-size: 17px;
  line-height: 22px;
}
.betable__findlabel {
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-left: 3px;
}
.betable__findinput {
  /*margin-right: 5px;*/
  border: 1px solid #fff;
  padding: 4px 11px;
  font-size: 14px;
  height: 32px;
  border-radius: 4px;
  width: 250px;
  max-width: 100%;
  padding-left: 30px;
  color: rgba(0,0,0,.65);
  background-color: #f0f2f5;
}
.betable__findinput-full {
  width: 100%;
  padding-left: 15px;
}
.betable__findwrap::after {
  content: '\e090';
  clear: both;
  display: table;
  font-family: 'simple-line-icons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 11px;
  margin-left: 10px;
  font-size: 12px;
}
.betable__btns {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  float: left;
  width: 100%;
  position: relative;
}
.searchbox__toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.searchbox__toggle>span {
  color: #be3e3e;
  line-height: 1.25;
  font-size: 16px;
  margin-left: 10px;
  cursor: pointer;
}
.searchbox__toggle>span:last-child:hover {
  text-decoration: underline;
}
.searchbox__toggle>input {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  width: 200px;
  border-color: #d5d5d5;
}
.searchbox__toggle-icon {
  font-size: 20px;
  position: absolute;
  left: 170px;
}
.betable__addbtn {
  padding-right: 5px;
  cursor: pointer;
}
.betable__recycle {
  padding: 5px;
  cursor: pointer;
}
.betable__pagin {
  position: absolute;
  right: 0;
  top: 0px;
  display: none;
}
.betable__pagin--bot {
  top: auto;
  bottom: -5px;
  display: block;
}
.betable__pagin>div {
  cursor: pointer;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  width: 32px;
  height: 30px;
  text-align: center;
  line-height: 0px;
  margin-right: 5px;
  float: left;
  padding: 15px 0px;
  color: rgba(0,0,0,.65);
  box-sizing: border-box;
}
.betable__pagin>div>i {
  line-height: 0px;
}
.betable__pagin>div:last-child {
  margin-right: 0px;
}
.betable__row--active:before {
  content: '';
  display: block;
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 15px solid #00142a;
  position: absolute;
  margin-top: 20px;
  margin-left: -13px;
}
.betable__orderstatus--0 {
  background-color: #FFEB3B;
  padding: 0px 5px;
  white-space: nowrap;
  border-radius: 3px;
}
.betable__orderstatus--1 {
  background-color: #4CAF50;
  padding: 0px 5px;
  white-space: nowrap;
  border-radius: 3px;
  color: #fff;
}
.betable__stagingtime {
  white-space: nowrap;
  font-size: 10px;
  opacity: 0.7;
}
.betable__productiontime {
  white-space: nowrap;
  font-size: 10px;
  opacity: 0.7;
}
/* adform */
.adform {
  position: absolute;
  /*background-color: #ffffff;*/
  width: 100%;
  top: 70px;
  left: 0px;
  /*box-shadow: 0px 0px 25px rgba(0,0,0,0.3);*/
  min-height: 100%;
  z-index: 100;
  overflow: hidden;
  padding: 15px 30px;
  background-color: #f5f4f4;
  min-height: 1500px;
}
.adform__name {
  position: relative;
  padding: 20px 0px;
  font-size: 25px;
  font-weight: 300;
  color: #000000;
  font-weight: bold;
}
.adform__name.fixed {
  position: fixed;
  top: 0px;
  z-index: 1000;
  border-bottom: solid 1px #f2f4f6;
}
.adform__close {
  float: right;
  /* margin-top: -7px; */
  cursor: pointer;
}
.adform__close>span, .adform__close>span>span, .adform__btn--save {
  padding: 10px 20px;
  border-radius: 3px;
  font-size: 14px;
  color: #fff;
  font-weight: bold;
}
.adform__btn--close {
  background-color: #838383;
}
.adform__btn--save {
  background-color: #009617;
  line-height: 1.2;
}
.adform__header {
  /* height: 60px; */
}
.adform__body {
  padding: 30px;
  min-height: 600px;
  width: 100%;
  /* max-width: 800px; */
  background-color: #fff;
  border-radius: 20px;
  border: solid 1px rgba(49, 55, 62, 0.1);
  position: relative;
  margin-bottom: 100px;
}
.adform__body, .adform__body:after, .adform__body:before {
  content: " ";
  display: table;
}
.adform__body>div {
  width: 50%;
  float: left;
  padding: 5px;
}
.fullwidth-formdevide {
  width: 100% !important;
  padding: 0px !important;
}
.fullwidth-frominput {
  width: 100% !important;
  display: flex;
  align-items: center;
}
.adform__btns {
  /* width: 100% !important; */
}
.adform__btns.rx-stickybot {
  position: fixed;
  padding: 15px 30px;
  margin-top: 0px;
  background: #fff;
  box-sizing: border-box;
  bottom: 0px;
  right: 0px;
  z-index: 1000;
  box-shadow: 1px 1px 10px rgba(0,0,0,0.1);
}
.fullwidth-label {
  font-size: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 500;
  min-width: 150px;
  max-width: 150px;
  display: inline-block;
}
.adform__body input {
  width: calc(100% - 150px);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  -webkit-font-feature-settings: "tnum";
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  height: 32px;
  padding: 4px 11px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  line-height: 14px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  -webkit-transition: all .3s;
  -o-transition: all .3s;
  transition: all .3s;
  max-width: 100%;
}

.input_2{
width: 100% !important;
}

.adform__body input:hover, .adform__body input:focus {
  border-color: #40a9ff;
}
.adform__btns {
  margin-top: 30px;
  /*height: 30px;*/
}
.adform__btncancel {
  font-size: 15px;
  padding: 10px 20px;
  cursor: pointer;
  float: left;
  margin-right: 10px;
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #ffffff;
  font-weight: bold;
  line-height: 1.25;
  text-align: center;
  color: #31373e;
  /*text-transform: uppercase;*/
}
.adform__btnedit {
  border: solid 1px #ddd;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  background-color: #03A9F4;
  color: #ffffff;
  border-color: #03A9F4;
  color: #fff;
  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 3px;
  /* line-height: 30px; */
  cursor: pointer;
  float: left;
  margin-right: 0px;
}
.adform__btnclone {
  color: #fff;
  background-color: #1890ff;
  border: solid 1px #1890ff;
  text-shadow: 0 -1px 0 rgba(0,0,0,.12);
  -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 7px 10px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 30px;
  margin-right: 10px;
  cursor: pointer;
  float: left;
  margin-right: 10px;
}

.adblock__btngen {
  border-color: #000;
  text-align: center;
  border-radius: 3px;
  cursor: pointer;
  padding-top: 3px;
  display: inline-block;
  color: rgba(255, 0, 0, 0.65);
  background-color: #ffffff;
  border: 1px solid #ff0000;
  box-shadow: 0 2px 0 rgba(0,0,0,.045);
  padding: 16px 10px;
  font-size: 14px;
  border-radius: 3px;
  line-height: 0px;
  margin-right: 10px;
  cursor: pointer;
}

.adform__btnshowper {
  padding: 5px 15px;
  border: solid 1px #ddd;
  border-radius: 3px;
  margin-right: 5px;
  cursor: pointer;
  width: 200px;
  background-color: #03A9F4;
  color: #ffffff;
  border-color: #03A9F4;
  text-align: center;
}

.adform__flightinfo {
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #eeeeee;
  border-radius: 3px;
  max-width: 320px;
}

.adperblock {
  margin-top: 15px;
  padding: 15px;
  border: 1px solid #eee;
  height: 100%;
  border-radius: 3px;
}

.adperblock__group {
  margin-bottom: 20px;
}

.adperblock__title {
  font-size: 14px;
  font-weight: bold;
}

.adperblock__peraction {
  font-size: 11px;
  border-radius: 2px;
  padding: 3px;
  margin-right: 5px;
  border: 1px solid #eee;
  margin-top: 5px;
  float: left;
}

.adcheckper {
  padding: 15px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f8f8f8;
  border-radius: 3px;
  overflow: hidden;
  height: 200px;
}

.adcheckper__head {
  font-weight: 700;
  font-size: 16px;
  margin: 0px 0;
  display: block;
  height: 30px;
  position: absolute;
  top: 10px;
  left: 15px;
  padding: 13px;
}

.adcheckper__nametext {
  max-width: 100%;
  color: #325;
  float: left;
  margin-top: -1px;
}

.adcheckper__body {
  background-color: #fff;
  color: #333;
  font-size: 12px;
  line-height: 18px;
  height: 140px;
  overflow-y: auto;
  padding: 10px;
  margin-top: 30px;
}

.adcheckper__child {
  margin-top: 4px;
}

.adcheckper__checkedone {
  background-color: #0D47A1;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.adcheckper__checkedone {
  background-color: #0D47A1;
  width: 100%;
  height: 100%;
  border-radius: 2px;
}

.adcheckper__checkedone_notall {
  background-color: #87b7ff;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.adcheckper__checkedall {
  background-color: #0d47a0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
}

.adcheckper__checkcontroller {
  display: block;
  float: left;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  width: 20px;
  height: 20px;
  position: relative;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
  background-color: #fff;
}

.adcheckper__checkaction {
  display: block;
  float: left;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  width: 15px;
  height: 15px;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.2);
}

.authloginpage {
  width: 360px;
  padding: 8% 0 0;
  margin: auto;
  margin-top: 100px;
}

.authform {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}

.authform__head {
  font-size: 28px;
  margin-bottom: 22px;
}

.authform_msgerror {
  color: #E74C3C;
  font-size: 12px;
  padding-bottom: 10px;
}

.authform__input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  border-radius: 3px;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.authform__btnsubmit {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #295a93;
  /*background: #2d82cd;
  background: -webkit-linear-gradient(to left, #5B86E5, #36D1DC);
  background: -webkit-gradient(linear, right top, left top, from(#5B86E5), to(#36D1DC));
  background: -webkit-linear-gradient(right, #5B86E5, #36D1DC);
  background: -o-linear-gradient(right, #5B86E5, #36D1DC);
  background: linear-gradient(to left, #5B86E5, #36D1DC); */
  width: 100%;
  border: 0;
  border-radius: 3px;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  -o-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
  font-weight: 100;
  letter-spacing: 5px;
  line-height: 10px;
  font-weight: bolder;
}

.authform__btnsubmit:hover,.authform__btnsubmit:active,.authform__btnsubmit:focus {
  background: #3e6a9d;
  /*background: #36D1DC;
  background: -webkit-linear-gradient(to left, #5B86E5, #36D1DC);
  background: -webkit-gradient(linear, right top, left top, from(#5B86E5), to(#36D1DC));
  background: -webkit-linear-gradient(right, #5B86E5, #36D1DC);
  background: -o-linear-gradient(right, #5B86E5, #36D1DC);
  background: linear-gradient(to left, #5B86E5, #36D1DC);*/
}

.authform__msg {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 13px;
}

/* table element */
.ordertable__detail {
  font-weight: lighter;
  font-size: 11px;
  line-height: 16px;
  opacity: 0.5
}
.ordertable__detail b {
  font-weight: bold;
  color: #E91E63;
  letter-spacing: -1px;
  font-size: 10px;
}

/* */
.fullwidth-input {
  /* width: 200px; */
  border: solid 1px #ddd;
  background-color: #fff;
  padding: 10px;
  height: 25px;
  border-radius: 3px;
  max-width: 100%;
  font-size: 14px;
}
select.fullwidth-input {
  padding: 0px 10px !important;
  width: calc(100% - 150px);
  height: 32px;
}
.adminnav__adname {
  float: right;
  height: 60px;
  padding: 30px 15px;
  font-weight: bold;
  line-height: 0px;
  display: none;
}
.adminnav__avatar {
  /*float: right;
  width: 35px;
  height: 35px;
  background-color: #eeeeee;
  margin-right: 30px;
  margin-top: 12px;
  border-radius: 50%;*/
}
.adminnav__menunoti {
  display: none;
  position: absolute;
  right: 0px;
   width: 600px;
  background-color: #fff;
  top: 70px;
  z-index: 1000;
  min-height: 200px;
  text-align: left;
  border-left: solid 1px #f0f0f0;
  border-bottom: solid 1px #f0f0f0;
  line-height: initial;
}
.adminnav__menunoti.rxactive {
  display: block;
}
.adminnav__menunoti>div {
  padding: 10px 15px;
}
.adminnav__avatarmenu {
  display: none;
  position: absolute;
  right: 0px;
  width: 270px;
  background-color: #fff;
  top: 70px;
  z-index: 1000;
  min-height: 400px;
  text-align: left;
  border-left: solid 1px #f0f0f0;
  border-bottom: solid 1px #f0f0f0;
  line-height: initial;
}
.adminnav__avatarmenu.rxactive {
  display: block;
}
.adminnav__avatarmenu>div {
  padding: 10px 15px;
}
.avatarmenu__fullname {
  font-size: 18px;
  font-weight: bold;
  text-transform: capitalize;
  color: #000;
}
.avatarmenu__username {
  font-weight: 100;
  color: #888;
  text-transform: capitalize;
}
.avatarmenu__logout {
  font-size: 16px;
  font-weight: bold;
  width: 100%;
  bottom: 0px;
  position: absolute;
  padding: 10px 15px;
  background-color: #fafafb;
  color: #333;
}
.avatarmenu__logout i {
  margin-right: 5px;
}
.adminnav__logoimg {
  float: left;
  cursor: pointer;
  height: 50px;
  margin-right: 15px;
  /*margin: 10px 10px 10px 30px;*/
  /*background-color: #010a15;*/
  width: 50px;
}
.adminnav__logoname {
  font-size: 20px;
  font-weight: bold;
  color: #0f68c4;
  text-transform: uppercase;
}
.adminnav__logoimgdesktop {
  width: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 15px 35px;
  height: 60px;
}
.adminnav__notifynum {
  width: 16px;
  height: 16px;
  position: absolute;
  margin-left: 10px;
  margin-top: -15px;
  background-color: #E91E63;
  border-radius: 50%;
  line-height: 0px;
  padding: 8px 0;
  text-align: center;
  color: #fff;
  font-size: 11px;
}
.adminnav__language {
  display: none;
}

.order__flightdetail {

}

.order__flightdetail i {
  opacity: 0.4;
}
.order__flightdetail b {
  font-weight: bold;
}
.order__flightico {
  width: 20px;
}
.order__flighticoabs {
  width: 20px;
  position: absolute;
  margin-left: -25px;
}
.order__flighticoabsholder {
  margin-right: 25px;
}

/* chart */
svg.ct-chart-bar, svg.ct-chart-line{
  overflow: visible;
}
#chart1 .ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0;
  transform: translate(-100%) rotate(-45deg);
  white-space:nowrap;
}
.betable__errorhistory {
  opacity: 0.7;
  padding: 0px 5px 0 15px;
  white-space: nowrap;
  font-size: 11px;
  overflow-y: auto;
  max-height: 80px;
  line-height: 17px;
  cursor: pointer;
}
.betable__errorhistory>div {
  position: relative;
}
.betable__errorhistory>div:before {
  content: '';
  display: block;
  width: 10px;
  height: 10px;
  background-color: #ffdee9;
  position: absolute;
  border-radius: 50%;
  position: absolute;
  margin-left: -13px;
  margin-top: 2px;
}
.betable__cpumem {
  margin-top: 7px;
}
.betable__cpumem b {
  color: #8ac34a;
  font-size: 9px;
}

.betable__cpumem svg.ct-chart-bar, .betable__cpumem svg.ct-chart-line{
  overflow: visible;
}
.betable__cpumem .ct-label.ct-label.ct-horizontal.ct-end {
  position: relative;
  justify-content: flex-end;
  text-align: right;
  transform-origin: 100% 0; /*transform: translate(-100%) rotate(-15deg);*/
  white-space: nowrap;
  font-size: 8px;
  margin-left: 20px;
}

.historyerror__detail {
  margin-top: 30px;
  margin-bottom: 30px;
  text-align: left;
  padding: 20px;
  background-color: #ebf2ff;
  margin-left: 20px;
  margin-right: 20px;
  line-height: 24px;
  border-radius: 3px;
}

.historyerror__detail b {
  font-weight: bold;
  min-width: 30px;
  color: black;
  font-size: 16px;
}

/* Scroll */
.betable__errorhistory::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.betable__errorhistory::-webkit-scrollbar {
  width: 3px;
  background-color: #F5F5F5;
}

.betable__errorhistory::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
}

.betable__serviceuri.ibreset {
  max-width: 300px;
  overflow: hidden;
}

/* Chart */
/* adblock__meter */
.adblock__meter1 {
  background: #fff;
}
.adblock__meter2 {
  background: #fff;
}
.adblock__meter3 {
  background: #fff;
}
.adblock__meter4 {
  background: #fff;
}
.meter__body {
  padding: 25px 25px;
  line-height: 20px;
  position: relative;
}
.meter__body .nav-icon {
  position: absolute;
  right: 26px;
  top: 26px;
  color: #d9dfe9;
  font-size: 47px;
}
.meter__number {
  font-size: 25px;
  font-weight: 100;
  margin-bottom: 7px;
}
.meter__number--meter1 {
  color: #3f51b5;
}
.meter__number--meter2 {
  color: #e64a19;
}
.meter__number--meter3 {
  color: #4caf4f;
}
.meter__number--meter4 {
  color: #ffa001;
}
.meter__footer b {
  font-size: 25px;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
  color: #98a6ad;
}
.meter__footer--meter1 {
  background-color: #3f51b5;
}
.meter__footer--meter2 {
  background-color: #e64a19;
}
.meter__footer--meter3 {
  background-color: #4caf4f;
}
.meter__footer--meter4 {
  background-color: #ffa001;
}
.meter__name {
  font-weight: bold;
}
.adblock__meterfooter {
}
.meter__img {
  width: 100%;
  margin-top: 30px;
}
.adblock__number {
  padding: 20px 25px 0px 25px;
  width: 100%;
  margin-top: 70px;
}
.adblock__innercustom {
  margin-left: 0!important;
  margin-right: 0!important;
  position: relative;
  padding: 30px;
}
.chart__item {
  background-color: #ffffff;
  margin-top: 30px;
  border: 1px solid #e2e5e8;
  padding: 30px;
  padding-top: 20px;
  height: 600px;
}
.chart__title {
  height: 36px;
  line-height: 36px;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: condensed;
  letter-spacing: normal;
  color: #1b2e4b;
}
.chart__content {
  margin-top: 30px;
  width: 100%;
}
.chart__select {
  float: right;
  width: 190px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #e0e3f3;
  background-color: #f8f8fc;
}
.chart__select--topic {
  float: right;
  width: 100px;
  height: 36px;
  border-radius: 2px;
  border: solid 1px #e0e3f3;
  background-color: #f8f8fc;
  position: absolute;
  right: 30px;
  z-index: 0;
  top: 50px;
}

/* Navbar scroll */
.beside__navitemsinner::-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #F5F5F5;
}

.beside__navitemsinner::-webkit-scrollbar
{
  width: 3px;
  background-color: #F5F5F5;
}

.beside__navitemsinner::-webkit-scrollbar-thumb
{
  background-color: #aaaaaa;
  border: 2px solid #555555;
}

.rx__sync {
  color: #fff;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
}
.rx__sync.red  {
  background-color: #DB3A34;
  border: solid 1px #DB3A34;
}
.rx__sync.green {
  background-color: #339f58;
  border: solid 1px #339f58;
}
.rx__sync.green.update {
  background-color: #155ca6;
  border: solid 1px #155ca6;
}

/* rxpaging */
.rxpagingwrap {
  width: 100%;
  padding: 25px;
  display: flex;
  align-items: center;
  font-size: 15px;
}
.col-md-4 .rxpagingwrap {
  display: block;
}
.col-md-4 .rxpaging {
  position: relative;
  right: 0;
  justify-content: flex-end;
}
.rxpaging {
  position: absolute;
  right: 25px;
  margin-bottom: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.rxpage {
  float: left;
  cursor: pointer;
  min-width: 30px;
  height: 30px;
  margin-left: 7px;
  background-color: #f0f2f5;
  text-align: center;
  padding: 15px 0px;
  line-height: 0px;
  border-radius: 3px;
}
.rxpage>i {
  line-height: 0px;
}
.rxpage.rxactive {
  background-color: rgba(112, 112, 112, 0.1);
  /* background-color: #1890ff; */
  color: #000;
  font-weight: bold;
}
.rxpagesize {
  float: left;
  margin-right: 5px;
}
.rxpagesize>span {
  margin-right: 5px;
  color: #9599a3;
}
.rxpagesize>select {
  margin-right: 5px;
  margin: 0px;
  padding: 5px;
  font-size: 12px;
  height: 30px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #ffff;
  background-color: #f0f2f5;
  border: none;
}
.rxpagesize>select::-ms-expand {
  display: none; /* Remove default arrow in Internet Explorer 10 and 11 */
}
.rxpagingwrap {
  width: 100%;
  position: relative;
}
.rxpagingcount {
  /*position: absolute;
  top: 10px;*/
  color: #a1a3a9;
}
.rxpagingcount>b {
  font-weight: bold;
  color: #666;
}
.adtable__pagingMore {
  background-color: unset;
}
.adtable__paginfirst, .adtable__paginlast {
    line-height: 0 !important;
    font-size: 20px;
}
.adform__rawedit {
  width: 100% !important;
}
.adform__raweditbtn {
  cursor: pointer;
  color: #fff;
}
.adform__raweditcontent {
  height: 500px !important;
}
.jsoneditor-search input {
  color: #1a1a1a !important;
  width: 120px !important;
  border: none !important;
  outline: none;
  margin: 1px !important;
  line-height: 20px !important;
  height: auto !important;
  padding: 0px !important;
}
.adform__btnedit--raw {
  background-color: #3883f9;
  border: none;
  border-radius: 0px;
  padding: 2px 10px;
  font-size: 12px;
}
.betable__findwrap {
  width: 800px;
  max-width: 100%;
  position: relative;
}
.findexpand {
  margin-top: 10px;
  margin-bottom: 20px;
  border: solid 1px #eee;
  border-radius: 5px;
  min-height: 60px;
}
.findexpand_head {
  height: 25px;
  background-color: #fafafa;
  padding: 15px 15px;
  line-height: 0px;
}
.findexpand_body {
  padding: 25px 15px;
  width: 100%;
  border-radius: 5px;
  border: solid 1px rgba(49, 55, 62, 0.1);
  background-color: #ffffff;
  position: relative;
}
.class_avatar {
  width: 100%;
  max-width: 200px;
  height: 264px;
  background-color: #f8f8f8;
  padding: 20px 0;
}
.form2-header-top .class_avatar {
  float: left;
}
.form2-header-title .header__title {
  position: absolute;
  width: 100%;
}
.admin-avatar {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  object-fit: contain;
}

.form-header {
  width: 100% !important;
}
.form-header>div {
  width: 100% !important;
  display: flex;
  align-items: center;
  position: relative;
}
.text-center.form-avatar-sub {
  font-size: 14px;
  line-height: 0.93;
  text-align: center;
  color: #000000;
  padding-top: 15px;
}
.form-header-right.barcode {
    max-width: 200px;
    height: 264px;
    background-color: #f8f8f8;
    float: right;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 20px;
    position: relative;
}
.title-top {
  font-size: 18px;
  color: #31373e;
}
.title-alias {
  font-size: 15px;
  color: #31373e;
  padding-top: 5px;
  font-style: italic;
}
.title-main {
  padding-top: 40px;
  font-size: 24px;
  font-weight: bold;
  color: #2869b5;
}
.form-header-bottom {
  padding-top: 55px;
  display: flex;
  align-items: center;
  max-width: 500px;
  margin: auto;
}
.form-header-bottom>span {
  margin-right: 5px;
  font-size: 15px;
  font-weight: bold;
  text-align: left;
  color: #000000;
  /*white-space: nowrap;*/
  width: 200px;
}
.form-header-bottom .rxselectbox-wrap {
  width: 100%;
  max-width: 350px;
  background-color: #f8f8f8;
}
#input-search {
  width: 100%;
}
.rx-form__body {
  width: 100% !important;
}
.rx-form__body--title {
    font-size: 18px;
    font-weight: 500;
    text-align: left;
    color: #2869b5;
    padding: 20px;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
}
.rx-form__body--title:before {
  content: '';
  background: #2869b5;
  width: 10px;
  position: absolute;
  left: 0;
  height: 36px;
}
.rx-form__body>div:last-child {
  width: 100%;
  position: relative;
  background-color: #f8f8f8;
  /*padding: 30px;*/
  padding: 20px 0;
}
.rx-form__item {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}
.rx-form__item>div {
  width: 100%;
  /* width: 50%; */
  padding: 10px 30px;
  display: flex;
  align-items: center;
}
.rx-form__item>div>span {
    min-width: 200px;
    max-width: 150px;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    padding-right: 2px;
}
.rx-form__item>div>span.custom_width {
  max-width: 500px;
  width: 400px;
}
span.custom_width p {
  font-size: 10px;
}
.button_action {
  margin-left: 295px;
  margin-top: 15px;
}
strong {
    line-height: 1.5;
}
.tab-list-item {
    display: inline-block;
    padding-right: 15px;
    cursor: pointer;
    background-color: #d2d8e0;
    margin-right: 10px;
    padding: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
}
.tab-list-item.tab-list-active {
    color: #fff;
    background-color: #626f7e;
}
.adform__typecard--body>span {
    min-width: 200px;
}
.adform__typecard--body.view {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.adform__typecard--body.view:nth-child(even) {
  padding: 0 10px;
}
.content.unconfirm {
    color: red;
}
span.icon-bellnum.unconfirm {
    background-color: #4caf50;
}
.rx-form__item .rxselectbox-wrap, .rx-form__item input, .rx-form__item select {
    width: 100%;
    min-width: 100px;
    color: #000;
    /* height: 35px; */
}
.react-datepicker-wrapper {
  width: 100%;
}
.rx-form__select {
    padding: 4px 11px !important;
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #ffffff;
    background-image: none !important;
}
.rx-upload_cmnd {
    width: 150px;
    height: 85px;
    border-radius: 5px;
    cursor: pointer;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #dedede;
    display: flex;
    align-items: center;
}
.admin-product-img-multi {
  width: 150px;
  height: 85px;
  border-radius: 5px;
  cursor: pointer;
  border: solid 1px rgba(112, 112, 112, 0.2);
  background-color: #dedede;
  float: left;
  margin-right: 15px;
  position: relative;
}
.admin-image-add {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.icon-addImg {
  font-size: 50px;
  line-height: 0.32;
  color: #000000;
  margin: auto;
}
.admin-product-img-remove {
    position: absolute;
    padding: 3px;
    border: 1px solid #838383;
    color: #000;
    font-weight: 300;
    width: 20px;
    height: 20px;
    font-size: 12px;
    border-radius: 5px;
    top: 0;
    right: 0;
    text-align: center;
}
.icon-cmnd {
  margin-top: 15px;
  font-size: 32px;
  /* float: left; */
  display: block;
  height: 32px;
  width: 100%;
  text-align: center;
}
.rx-upload_cmnd-title {
    display: block;
    margin-top: 7px;
    font-size: 12px;
    text-align: center;
    color: #000000;
}
.rx-upload_cmnd:first-child {
    margin-right: 20px;
}
.rx-upload_cmnd .admin-product-img-upload, .admin-image-upload {
  width: 100%;
  height: 100%;
  max-height: 85px;
  object-fit: contain;
  padding: 0;
  border: unset;
  border-radius: unset;
}
.rx-form__body .p10 {
    padding: 10px 30px;
}
.rx-form__title--sub {
    padding: 0 30px;
    position: relative;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.4px;
    text-align: left;
    color: rgba(0, 0, 0, 0.6);
    text-transform: uppercase;
    margin: 15px 0;
}
.rx-form__title--sub>span {
    height: 1px;
    position: absolute;
    bottom: 0;
    right: 30px;
    left: 240px;
    background-color: rgba(112, 112, 112, 0.2);
}
.adform__footer .adform__btns {
  width: 100%;
}
.adform__footer .adform__close {
    float: right;
    /* margin-top: 15px; */
}
.css-1wa3eu0-placeholder {
  font-size: 13px;
}

.class_avatar-img {
    height: 190px;
    width: 130px;
    -o-object-fit: contain;
    object-fit: contain;
    margin: auto;
    display: flex;
}
.rxdiff__content {
  border-bottom: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
}
.rxdiff__content table {
    width: 100%;
}
.rxdiff__content .text-center {
  font-size: 25px;
  font-weight: bold;
  color: #000000;
  padding: 5px 15px;
  background-color: #fafafa;
}
.rxdiff__content tr:first-child{
  background-color: #fafafa;
  border: 1px solid #e5e5e5;
}
.rxdiff__content td tr {
  border: 1px solid #e5e5e5;
}
.rxdiff__content td tr td {
  border-right: 1px solid #e5e5e5;
}
.rxdiff__content td {
    padding: 5px 15px;
    background-color: #fff;
}
.rxdiff__content td div {
    padding: 5px 0 5px 40px;
}
.rxdiff__content-number {
    padding: 0;
    width: 50px;
    text-align: center;
    background-color: #fafafa !important;
    border-left: 1px solid #e5e5e5;
    border-right: 1px solid #e5e5e5;
}
.rxdiff__content--old {
    background-color: #fbe9eb !important;
}
.rxdiff__content--new {
    background-color: #ecfdf0 !important;
}
.rxselectbox-wrap {
  min-height: 42px;

}
.rx-table__form {
  padding: 0 30px;
  position: relative;
}
.rx-table__form table, .rx-table__form td {
    /*border: #d3d3d3 1px dotted;*/
    padding: 5px 10px;
    background-color: #fff;
    border: solid 1px rgba(112, 112, 112, 0.2);
}
.rx-table__form td {
  margin: 5px;
}
.rx-table__form input {
  width: 100%;
  border: #d3d3d3 1px dotted;
}
.form-header-code {
  position: absolute;
  bottom: -20px;
  text-align: center;
  width: 100%;
  left: 0;
}
.adbtn__add {
    width: 100px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    background-color: #0f68c4 !important;
    cursor: pointer;
    text-align: center;
}
.adbtn__clear {
    width: 100px;
    font-weight: bold;
    color: #838383 !important;
    cursor: pointer;
    text-align: center;
}
.rx-table__form-header {
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  border: solid 1px #ffffff !important;
  background-color: #31373e !important;
  color: #fff;
  padding: 10px 15px !important;
  line-height: 1.2;
  vertical-align: middle;
}
.rxlist__card-table .rx-table__form-header {
  padding: 5px !important;
  font-size: 18px;
}
.rx-cursor {
    cursor: pointer;
}
.class_avatar img {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  -o-object-fit: contain;
  object-fit: contain;
  display: flex;
  margin: auto;
}

.rx-form__note {
  font-size: 10px;
  font-weight: bolder;
  color: red;
  margin-left: 186px;
}

.react-datepicker__month-select, .react-datepicker__year-select {
    height: 25px !important;
    border: 1px solid #fafafa;
    border-radius: 5px;
}
.react-datepicker {
  border: none;
  box-shadow: 0 1px 5px rgba(0,21,41,.1)
}

.rx--box__title {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #000000;
  width: 75px;
  white-space: nowrap;
}

.rx--btn__search {
  width: 213px;
  height: 40px;
  border-radius: 5px;
  background-color: #626f7e;
}

/* .rx--box__filter {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;
  text-align: left;
  color: #be3e3e;
} */

.rx--box__search {
  display: block;
  /*margin-top: 10px;
  margin-bottom: 10px;
  height: 110px;*/
  cursor: pointer;
}

.rx--btn__clearFilter {
  width: 68px;
  height: 18px;
  font-size: 15px;
  line-height: 0.87;
  text-align: left;
  cursor: pointer;
}

.search_now {
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: 1.5px;
  text-align: center;
  color: #ffffff;
  display: block;
  margin-top: 10px;
}

.clearFilter {
  bottom: -25px;
  margin-left: 10px;
  font-size: 14px;
  line-height: 1.5;
  text-align: left;
  color: #0f68c4;
  display: block;
  position: absolute;
}
.clearFilter:hover {
  text-decoration: underline;
}

.rx-form__body > div > div > p {
  font-size: 14px;
  margin-top: 5px;
  margin-left: 10px;
  line-height: 1.5;
}

.rx-title__table {
  background-color: #fff !important;
}

.rx-title__table > th {
  /*color: #ffffff;*/
}
.rx-table__form-plus {
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #eaeaea;
  position: absolute;
  padding: 8px;
  font-size: 17px;
  right: 30px;
  bottom: 0;
  cursor: pointer;
}
.title-header {
  font-size: 25px;
  font-weight: bold;
}
.title-slug {
  font-weight: bold;
}
.rx-form__info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 20px;
    grid-gap: 5px;
    font-size: 15px;
}
.rxlist__card-table {
    max-width: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: auto;
    position: absolute;
}
.rxlist__card-table .rx-table__form {
  padding: 0;
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
}
.rx-form__header {
  display: block !important;
}
.rxlist__card-table td {
  padding: 8px;
  border: solid 1px rgba(112, 112, 112, 0.2);
  line-height: 1.4;
  font-size: 15px;
}
.iconcustom-require.icon-require {
    color: #be3e3f;
    font-size: 25px;
}
.rx-xursor {
  cursor: pointer;
}
.rx-list__card {
  position: relative;
  height: 358px;
  width: 100%;
  margin-top: 20px;
}
.rx--check__titleForm .rx--check__title .rx--check__time .rx--check__content {
  color: #31373e;
}

.rx--check__title .rx--check__time .rx--check__content {
  font-size: 12px;
}

.rx--check__titleForm {
  font-size: 20px;
  font-weight: bold;
  display: block;
  margin-bottom: 31px;
  display: block;
}

.rx--check__title {
  margin-bottom: 11px;
  display: block;
}

.rx--check__time {
  opacity: 0.6;
  display: block;
  margin-bottom: 5px;
}

.rx--check__content {
  font-weight: bold;
  display: block;
  border-radius: 3px;
  background-color: #f8f8f8;
  height: 100%;
}

.rx--check__content > .content {
  display: block;
  padding-top: 14px;
  padding-left: 5px;
  max-height: 200px;
  overflow-y: scroll;
  height: 100%;
}

.rx--checkIssue {
  display: block;
  padding-bottom: 40px;
  padding-left: 10px;
  position: relative;
  font-size: 14px;
}
.rx--checkIssue__btn {
  position: absolute;
  left: -5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.rx--checkIssue__btn.duyet {
  background: #2869b5;
}
.rx--checkIssue__btn.khongduyet {
  background: #be3e3f;
}
.rx--checkIssue__line {
  position: absolute;
  left: -1px;
  background: #dddddd;
  width: 3px;
  height: 112px;
  top: -112px;
}
.rx-form__sign-check {
    width: 50px !important;
    height: 25px !important;
}
.rx-form__sign__item {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  height: 100px;
}
.formsign__title {
  font-size: 15px;
  color: #31373e;
  font-weight: 500;
  white-space: nowrap;
  text-align: center;
  text-transform: uppercase;
}
.formsign__sign {
  padding-top: 15px;
  text-align: center;
  font-size: 14px;
}
.formsign__time {
  font-size: 14px;
  text-align: center;
}
.rx-form__sign-check {
  width: 25px !important;
  height: 25px !important;
  margin-right: 20px;
  text-align: center;
  border: solid 1px rgba(112, 112, 112, 0.2);
  border-radius: 5px;
}
.rx-form__sign-check.iconcustom-check {
  padding: 2px 3px;
  color: #fff;
  font-weight: bold;
  font-size: 20px;
  background-color: #0f68c4;
}
.rx-marginTop {
  margin-top: -30px;
}
.rx-crud__form {
  width: 100% !important;
}
.rx-padding {
  padding: 10px !important;
}
.rx-form__info span {
  font-size: 16px;
  min-width: 230px;
}
.rx-form__info>div {
  width: 100%;
  display: flex;
  align-items: center;
}

.avatar_print {
  width: 100%;
  max-width: 130px;
  height: 220px;
  padding: 20px 0;
}

.avatar_print img {
  height: 190px;
  width: 130px;
  padding: 0px!important;
  border: 0px;
  border-radius: 0px;
  -o-object-fit: contain;
  object-fit: contain;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
}
.barcode__print {
  position: absolute;
  right: 5px;
  top: 5px;
}

.text-center > P {
  display: block;
}

/* TIMELINE */
.renderTimeline {
    display: block;
    position: relative;
    padding: 15px;
    margin: 15px 0;
    /* opacity: 0.5; */
    border-radius: 20px;
    border: solid 1px rgba(49, 55, 62, 0.1);
}

.progress-bar-order {
    display: block;
}
.progress-bar-item {
     width: 20%;
    display: inline-block;
    font-size: 14px;
    text-align: center;
}
.progress-bar-item2 {
  width: 50%;
  display: inline-block;
  font-size: 14px;
  text-align: center;
}
.right-item{
  float: right;
}
.progress-bar-order .progress-bar-item:first-child .progress-bar-item-line.left {
    background: transparent;
}
.progress-bar-order .progress-bar-item:last-child .progress-bar-item-line.right {
    background: transparent;
}
.progress-bar-item-ico {
    width: 44px;
    height: 44px;
    border-radius: 100%;
    color: #dbdbdb;
    padding: 0;
    border: 3px solid #dbdbdb;
    margin: 0 auto;
    background-color: #fff;
}
.progress-bar-item-ico-in {
    background-image: url(/images/static/MaskGroup2@3x.png);
    background-size: 50%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
.progress-bar-item-progress {
    display: block;
    position: relative;
}
.progress-bar-item-line {
    width: calc(50% - 22px);
    height: 3px;
    background: #ffffff;
    margin: auto 0;
    position: absolute;
    top: 50%;
    z-index: 1;
}
.progress-bar-item-line.right {
    right: 0;
}
.progress-bar-item2.active.right {
  float: right;
}
.progress-bar-item.active .progress-bar-item-line {
    background: #2869b5;
}
.progress-bar-item.active .progress-bar-item-ico {
    color: #2869b5;
    border: 6px solid #2869b5;
}
.progress-bar-item.active .progress-bar-item-ico-in {
    background-image: url(/images/static/mask@3x.png);
}
.progress-bar-item2.active .progress-bar-item-line {
  background: #2869b5;
}
.progress-bar-item2.active .progress-bar-item-ico {
  color: #2869b5;
  border: 6px solid #2869b5;
}
.progress-bar-item2.active .progress-bar-item-ico-in {
  background-image: url(/images/static/mask@3x.png);
}

.progress-bar-item.active.nonactive .progress-bar-item-line {
    background: #F44336;
}
.progress-bar-item.active.nonactive .progress-bar-item-ico {
    color: #F44336;
    border: 6px solid #F44336;
}
.progress-bar-item2.active.nonactive .progress-bar-item-line {
  background: #F44336;
}
.progress-bar-item2.active.nonactive .progress-bar-item-ico {
  color: #F44336;
  border: 6px solid #F44336;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}



.progress-bar-item-step {
    font-size: 12px;
    color: #31373e;
    font-weight: bold;
    line-height: 1.08;
}
.progress-bar-item-title {
    font-size: 16px;
    font-weight: 100;
    padding: 5px;
}
.progress-bar-item-date {
    text-align: center;
    width: 100%;
    display: inline-block;
    opacity: 0.5;
    font-size: 12px;
}
.adform__typecard .rxselectbox-wrap {
    width: 100%;
}
.adform__typecard div {
    font-size: 16px !important;
}
.adform__typecard .rxselectbox-result>div {
  padding: 8px 5px;
}
.adform__typecard--body {
  display: flex;
  align-items: center;
}
.adform__typecard--select {
  width: calc(100% - 150px);
}
.form2-header-title {
  display: block !important;
  width: 100%;
  padding-bottom: 10px;
}
.width-100-percent {
  width: 100%;
}
.form2-header-top {
  width: 100%;
  min-height: 100px;
  position: relative;
}
.header__left {
  float: left;
  width: 40%;
}
.header__left .form-header-bottom {
  padding: 0;
}
.header__right {
  float: right;
}
.header__right .title-top {
    font-weight: 500;
}
.title-sub {
    padding: 30px 0;
    font-size: 16px;
    text-align: center;
}
.fullwidth-frominput .react-datepicker-wrapper {
  width: calc(100% - 150px);
}
.fullwidth-frominput .react-datepicker-wrapper input {  width: 100% !important; }
.header__left .rxselectbox-wrap {
    min-height: 35px;
    width: 100%;
}
.header__left .text-center {
  font-size: 16px;
}
.header__hr {
    margin: 10px auto;
    height: 1px;
    width: 60%;
    background: #f5f4f4;
}
.header__title {
  padding-top: 40px;
  font-size: 20px;
  font-weight: 500;
}
.rx-fixed.rx-image__full {
    background: rgba(0,0,0,.8);
    z-index: 1001;
    display: flex;
    align-items: center;
    top: 70px;
    height: calc(100% - 70px);
}
.rx-image__content {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.rx-image__content-full>div {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 0;
}
.rx-image__content-full {
  display: flex;
  align-items: center;
  overflow: auto;
  position: absolute;
  height: calc(100% - 100px);
}
.rx-image__close {
  position: fixed;
  right: 10px;
  top: 80px;
  font-size: 30px;
  cursor: pointer;
  background: #000;
  color: #fff;
  z-index: 2;
  border-radius: 3px;
}
.rx-image__back {
  left: 0px;
}
.rx-image__next {
  right: 0px;
}
.rx-image__back, .rx-image__next {
  position: fixed;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  cursor: pointer;
  padding: 10px;
}
.adnav__theme {
  display: flex;
  align-items: center;
  padding: 0 50px;
}
.adnav__theme .adnav__itemname {
  padding: 5px;
  cursor: pointer;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border: 1px solid #fff;
}
.adnav__theme .adnav__itemname.black {
  background-color: #31373e;
  margin-right: 5px;
}
.adnav__theme .adnav__itemname.white {
  background-color: #fff;
}
.adnav__theme.white .adnav__itemname {
  border: 1px solid #31373e;
}
.rx-form__body--note {
  border-radius: 6px;
  background-color: #2869b5;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  padding: 10px 20px;
  text-transform: uppercase;
  text-align: center;
  margin-top: -20px;
  margin-left: 25px;
  position: absolute;
  z-index: 1;
}
.rx-form__body.note>div:last-child {
  border-radius: 10px;
  background-color: #e7e8eb;
  margin-top: 50px;
  padding: 30px;
}
.adform__btn--clear {
  border-radius: 3px;
  border: solid 1px #707070;
  background-color: #ffffff;
  color: #000;
  display: flex;
  align-items: center;
}
.adform__btn--clear>i {
  font-size: 20px;
  line-height: 0;
  margin-right: 5px;
}

.table--name_office {
  min-width: 250px;
}
.table--name_office {
  min-width: 100px;
}

.table--name_small{
  min-width: 100px;
}

.card__name {
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
}

.card__image{
  display: inline-block;
  margin-bottom: -5px;
}
.rx-flex {
  display: flex;
  align-items: center;
}
.adform__toggle {
  box-shadow: 0 10px 50px 0 rgba(56, 65, 74, 0.03);
  background-color: #ffffff;
}
.betable__status {
  background-color: rgba(26, 188, 156, 0.18);
  border-radius: 4px;
  padding: 3px 5px;
}
.findexpand_close {
    position: absolute;
    right: 5px;
    cursor: pointer;
    font-size: 14px;
    top: 5px;
    padding: 10px;
    z-index: 10;
}
.findexpand_close>i {
    margin-right: 5px;
}
.rx-filter {
    background-color: #fff;
    padding: 25px;
    border-radius: 5px;
    border: solid 1px rgba(49, 55, 62, 0.1);
    background-color: #ffffff;
    position: relative;
}
.rx-percent-25 {
  width: 25%;
}
.marginRight-35 {
  margin-right: 35px;
}
.rx-form__sign {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 10px;
  padding: 0 10px;
}
.rxselectbox-result>div>div {
  padding: 5px;
  padding-right: 10px;
  border: solid 1px rgba(112, 112, 112, 0.2);
  border-radius: 3px;
  position: relative;
  background-color: #fff;
}
.rxselectbox-result>div>div>b {
  position: relative;
  right: 3px;
  top: 0;
  font-size: 10px;
  color: #737272;
}
.rx-image__list {
    width: 100%;
    height: 100px;
    position: absolute;
    bottom: 0;
}
.rx-image__list>div {
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  margin: auto;
}
.rx-image__list img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    border-radius: 5px;
    margin: 0 5px;
}
.opacity-img {
  opacity: 0.4;
  cursor: pointer;
}
/*.adblock__image {
  width: 607px;
  height: 308px;
}
.print .adblock__image {
  width: 324px;
  height: 204px;
}*/
.betable__img-card {
  width: 80px;
  max-height: 50px;
  object-fit: contain;
}
.img_card .rx-upload_cmnd {
  width: 100px;
  height: 50px;
  margin: 0;
  background-color: unset;
  border: none;
  border-radius: 5px;
}
.img_card .admin-product-img-upload {
  border-radius: 5px;
  border: solid 1px rgba(112, 112, 112, 0.2);
}
.rxloading {
  display: flex;
  align-items: center;
  z-index: 9999;
}
.betable__report>thead>tr {
  background-color: #fff;
}
.betable__report tr {
  border-bottom: 1px solid #ddd;
}
.betable__report td {
  min-width: 80px;
}
.filter__dashboard {
  margin-top: 15px;
}
.filter__dashboard .rx--btn__clearFilter {
  float: left;
  position: relative;
  width: unset;
  height: 100%;
}
.filter__dashboard .clearFilter {
  white-space: nowrap;
}
.betable__btnimport.active {
  background-color: #626f7e;
}
.betable__report thead td {
    border-right: 1px solid #ddd;
}
.betable__report td:first-child {
  display: table-cell;
}
.adform__name-tab {
    margin-bottom: -20px;
}
.adform__title {
  font-size: 32px;
  padding-bottom: 20px;
  font-weight: 300;
}
.adform__title>span {
  font-weight: 500;
}
.rxlist__card-table.print td {
  vertical-align: middle;
}
.rxlist__card-table.print .img_card .admin-product-img-upload {
  width: 100px;
  height: 50px;
  object-fit: contain;
}
.adform__note {
  font-size: 16px;
  font-weight: bold;
}
.adform__note i {
  color: #323232 !important;
  font-weight: bold;
}
.rx-filter__dashboard {
  margin: 10px 0 0 0 !important;
}
.align__right {
  text-align: right;
}
.adform__typecard--item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
}
.adform__typecard--item.view {
  background-color: #fff;
}
.betable__typecard--select {
  width: 200px;
}
.rxlist__card-table.return thead tr th {
  padding: 10px;
  background-color: #31373e;
  color: #fff;
}
.daterangepicker .drp-buttons .btn {
  float: right;
  margin-bottom: 10px;
}
.rx-flex .react-bootstrap-daterangepicker-container {
  width: 100%;
  background-color: #fff;
  border: solid 1px rgba(112, 112, 112, 0.2);
}
.rx-flex .icon {
  float: right;
}
.rxlist__card-table.return table tbody tr td {
    vertical-align: middle;
}
.text-right {
  text-align: right;
  color: #0d47a0;
  font-weight: bold;
}
.rx-noti td {
  padding: 5px;
}
.rx-noti__header {
  background-color:#ccebff !important;
  border-bottom: 1px solid #eee;
}
.dropitem.rx-noti__body {
    background: #fff;
}
.rx-noti__header td {
    padding: 10px 5px;
    font-weight: bold;
    font-size: 16px;
}
.noti__menu__count {
  text-align: right;
  color: red;
}
.rx-noti {
    border: 1px solid #eee;
    margin-bottom: 10px;
}
.align-right{
  text-align : right
}
.gender--item {
    max-width: 370px;
}
.gender--title {
  max-width: 100px !important;
  min-width: 100px !important;
}
.province--item {
    width: 260px;
    max-width: 100%;
}
.LoaiHinhBC {
  vertical-align: middle;
}
.LoaiHinhBC .rxselectbox-wrap {
  width: 100%;
}
.datetime--table {
  display: flex;
  align-items: center;
}
.react-datepicker__month .react-datepicker__month-text, .react-datepicker__month .react-datepicker__quarter-text {
  padding: 5px;
}
.datetime--table .react-datepicker__header {
  padding-bottom: 8px;
}
.rx-sub__header {
  font-size: 16px;
}

.betable__btnDisEdit {
  opacity: 0.3;
}
