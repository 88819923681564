.footer {
    
}

.footer__title {
    margin-top: 80px;
    font-size: 16px;
    text-transform: uppercase;
    margin-bottom: 40px;
    font-weight: bold;
}

.footer__title--center {
    text-align: center;
}

.footer__head {
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 5px;
    color: #333;
}

.footer__block {
    line-height: 18px;
    font-size: 13px;
    color: #9E9E9E;
    margin-bottom: 20px;
}

.footer__blocklast {
    background-color: #fafafa;
    padding-bottom: 120px;
}

.footer__accountitem:first-child {
    margin-top: -9px;
}

.footer__accountitem {
    line-height: 36px;
}

.footer__accountitem--center {
    line-height: 26px;
    text-align: center;
    padding: 0 10px;
}

.footer__accountitem--contact {
    padding: 0 10px;
    margin-top: 20px;
    position: relative;
}

.footer__contactinput {
    width: 100%;
    float: left;
    background: #ffffff;
    padding: 0 10px;
}

.footer__contactbtn {
    position: absolute;
    float: right;
    right: 0px;
    width: 80px;
    padding: 0 10px;
    background-color: #333333;
    color: #fff;
    font-size: 11px;
    text-align: center;
}

.footer__bot {
    background-color: #000000;
    color: #fff;
    height: 42px;
    text-align: center;
    margin-top: -42px;
    position: absolute;
    width: 100%;
}

.footer__copyright {
    padding-top: 6px;
    padding-bottom: 5px;
    font-size: 10px;
}

.footer__dkkd {
    font-size: 9px;
    color: #777;
}

.footer__background {
    width: 100%;
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
    opacity: 0.7;
    border: none;
    box-sizing: border-box;
}