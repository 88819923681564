/* Home Navigation */

.homenav {
    background-color: #ffffff;
    box-shadow: 0 0 1px 0 rgba(61,119,180,0.12), 0 0.25rem 12px 0 rgba(91,139,199,0.24);
    z-index: 100;
    position: fixed;
    width: 100%;
}

.homenav__left {
    float: left;
    width: 500px;
}

.homenav__right {
    float: right;
}

.homenav__item {
    float: left;
    padding: 20px 10px;
    cursor: pointer;
}

.homenav__logoimg {
    float: left;
    margin-top: 3px;
    cursor: pointer;
    max-height: 45px;
    margin-left: -20px;
}

.homenav__item--mobile {
    border: solid 1px #E91E63;
    background-color: #E91E63;
    color: #fff;
    padding: 6px 15px;
    border-radius: 2px;
    margin-top: 13px;
}

.homenav__item--login {
    border: solid 1px #E91E63;
    background-color: #ffffff;
    color: #E91E63;
    padding: 6px 15px;
    border-radius: 2px;
    margin-top: 13px;
    margin-left: 10px;
    cursor: pointer;
    box-sizing: border-box;
    margin-bottom: 10px;
}

.homenav__item--login>a {
    color: #E91E63;
}

.homenav__cartico {
    font-size: 16px;
    margin-top: -4px;
    font-weight: bold;
    margin-right: 5px;
    position: relative;
}

.homenav__carttxt {
    font-size: 12px;
    margin-left: 5px;
}

.homenav__cartnum {
    position: absolute;
    top: 17px;
    left: -17px;
    color: #fff;
    background-color: #333;
    height: 22px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 22px;
    padding: 6px 3px;
    text-align: center;
    border-radius: 50%;
    -webkit-box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
    box-shadow: 1px 1px 5px rgba(0,0,0,0.2);
}

.homenav__cartlite {
    position: absolute;
    width: 350px;
    padding: 20px;
    top: 55px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #fff;
    -webkit-box-shadow: 0px 2px 15px rgba(0,0,0,0.2);
    box-shadow: 0px 2px 40px rgba(0,0,0,0.2);
    right: -110px;
    height: 400px;
    border-top: solid 3px #333;
}

.homenav__cartlite:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #333;
    top: -10px;
    left: 46%;
}

.homenav__cartbuy {
    text-align: center;
    background-color: #157efb;
    padding: 15px;
    color: #fff;
    text-transform: uppercase;
    border-radius: 3px;
    font-size: 16px;
    cursor: pointer;
}

.homenav__cartcontinue {
    font-size: 13px;
    text-align: center;  
    margin-bottom: 8px;
    font-weight: normal;
    background-color: #eee;
    border-radius: 3px;
    line-height: 30px;
    height: 30px;
    cursor: pointer;
}

.homenav__cartitem {
    margin-bottom: 15px;
    height: 50px;
    position: relative;
}

.homenav__cartitem--lg {
    height: 80px;
}

/* Cart */

.cartitem__holder {
    height: 280px;
    overflow-y: auto;
}

.cartitem__pic img {
    width: 100%;
}

.cartitem__pic {
    width: 50px;
    height: 50px;  
    border-radius: 2px;
    position: absolute;
}

.cartitem__nam {
    width: 100%;
    padding-left: 58px;
    padding-right: 58px;
    overflow: hidden;
    font-weight: lighter;
    font-size: 13px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 18px;
}

.cartitem__pic--lg {
    width: 80px;
    height: 80px;  
}

.cartitem__nam--lg {
    padding-left: 90px;
    font-size: 16px;
    margin-bottom: 5px;
}

.cartitem__pricehold {
    width: 100%;
    padding-left: 58px;
    padding-right: 58px;
    float: left;
    margin-top: 3px;
    font-size: 12px;
    line-height: 24px;
}

.cartitem__pricehold--lg {
    padding-left: 90px;
}

.cartitem__num {
    float: left;
    font-weight: normal;
    width: 30px;
    text-align: center;
    background-color: #eee;
    margin-right: 5px;
    border-radius: 3px;
}

.cartitem__price {
    float: left;
    font-weight: normal;
    margin-right: 5px;
}

.cartitem__total {
    float: left;
    font-weight: bold;
}

.cartitem__del {
    position: absolute;
    right: 0px;
    background-color: #333;
    color: #fff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    padding: 3px;
    font-size: 17px;
    font-weight: lighter;
    cursor: pointer;
    top: 15px;
    margin-right: 10px;
}

.cartitem__empty {
    line-height: 23px;
    font-size: 13px;
    text-align: center;
    font-weight: normal;
}