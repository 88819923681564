.content__2--body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    position: relative;
    width: 100%;
}
.content__1--right img {
	width: 500px;
	object-fit: contain;
}
.content__1--left {
    display: flex;
    align-items: center;
    padding: 30px;
}
.content__1--logo {
    height: 80px;
    margin-bottom: 45px;
    object-fit: contain;
}
.content__1--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    font-family: 'Gilroy', sans-serif;
}
.content__1--desc {
    font-size: 24px;
    font-weight: normal;
    line-height: 1.42;
    text-align: left;
    color: #464646;
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: 'Gilroy', sans-serif;
}
.content__1--download {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 10;
}
.content__1--download img {
    height: 80px;
    width: 240px;
    object-fit: contain;
    cursor: pointer;
}
.download__play {
    margin-left: 30px;
}
.content__2--right {
    position: relative;
    width: 100%;
    height: 657px;
    margin-top: 50px;
}
.content__2--right img {
    position: absolute;
    left: 60px;
    width: 566px;
    height: 100%;
    object-fit: contain;
}
.content__2--head {
    display: flex;
    align-items: center;
    position: relative;
}
.content__2--icon {
    width: 120px;
    height: 120px;
    object-fit: contain;
}
.content__2--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    padding-left: 45px;
}
.content__2--desc {
    padding-top: 80px;
}
.content__2--desc div {
    margin-bottom: 35px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    display: flex;
}
.content__2--desc img {
    width: 27px;
    height: 20px;
    object-fit: contain;
    margin-right: 30px;
}
.content__3--title {
    font-size: 60px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: -1.5px;
    text-align: left;
    color: #363636;
    font-family: 'Gilroy', sans-serif;
}
.content__3--desc {
    width: 100%;
    max-width: 850px;
    padding-top: 40px;
}
.item__title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 10px 0;
    cursor: pointer;
    font-family: 'Gilroy', sans-serif;
}
.item__title img {
    position: absolute;
    right: 0;
    width: 16px;
    height: 10px;
    object-fit: contain;
}
.content__3--item {
    /*padding: 20px 0;*/
    border-bottom: 1px solid #eee;
}
.item__content {
    display: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    padding-bottom: 10px;
    font-family: 'Gilroy', sans-serif;
}
.footer {
    padding: 10px 240px;
    width: 100%;
    display: flex;
    left: 0;
    bottom: 0;
    position: absolute;
    align-items: center;
}
.footer__logo {
    width: 120px;
    height: 40px;
    object-fit: contain;
}
.footer__copy {
    /*width: 170px;
    margin: auto;*/
    width: 100%;
    text-align: center;
    color: rgba(54, 54, 54, 0.85);
    font-size: 16px;
    font-family: 'Gilroy', sans-serif;
}
.rx-mobile {
    display: none;
}
.content__1--right {
    position: relative;
    display: flex;
}
.rx-toggle_1 {
    position: absolute;
    bottom: 50px;
    left: -85px;
    height: 65px !important;
    width: 84px !important;
}
.rx-toggle_2 {
    position: relative;
    margin-top: 80%;
    width: 84px !important;
    height: 65px !important;
}
.rx-toggle_3 {
    position: absolute;
    bottom: 30px;
    left: 532px !important;
    /* right: 100px; */
    width: 84px !important;
    height: 65px !important;
}
.rx-toggle_4 {
    position: absolute;
    right: 0px;
    bottom: -40px;
    height: 65px !important;
    width: 84px !important;
}
#current_year {
    font-family: 'Gilroy', sans-serif;
    font-size: 16px;
    color: rgba(54, 54, 54, 0.85);
}
.rx-toggle_5 {
    width: 84px;
    height: 65px;
    position: absolute;
    left: 120px;
}
.rx-toggle_6 {
    position: absolute;
    width: 117px;
    height: 90px;
    right: 23%;
    bottom: 40%;
}
.content__term--body {
    padding: 15px 240px;
    position: relative;
}
.content__term--body .footer {
    position: relative;
    padding: 15px 0;
}
.content__term--desc, .content__term--desc li, .content__term--desc ul, .content__term--desc a {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.42;
    text-align: left;
    color: #464646;
    font-family: 'Gilroy', sans-serif;
}

.content__term--header {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;
    text-align: left;
    color: rgba(54, 54, 54, 0.85);
    text-transform: uppercase;
    font-family: 'Gilroy', sans-serif;
}
.content__faq .content__3--body {
    background-image: unset;
    padding: 50px 150px;
}
.content__faq .content__3--desc {
    padding: 0;
    max-width: unset;
}
.icon-content {
    height: 30px;
    object-fit: contain;
    margin-top: -10px;
}
.footer__right {
    text-align: center;
    width: 100%;
}
.footer__term {
    font-size: 16px;
    font-weight: normal;
    line-height: 1.6;
    text-align: center;
    color: rgba(54, 54, 54, 0.85);
    padding-bottom: 10px;
    font-family: 'Gilroy', sans-serif;
}
.ul {
    display: list-item;
    list-style: inside;
    margin-left: 1.5em;
}
.footer__term:hover {
    text-decoration: underline;
}
@media only screen and (min-width: 1025px) and (max-width: 1440px){
    .content__1--body {
        padding: 140px 110px 80px;
    }
    .content__1--logo {
        height: 65px;
        margin-bottom: 40px;
    }
    .content__1--title {
        font-size: 50px;
    }
    .content__1--right img {
        width: 380px;
    }
    .content__1--desc {
        font-size: 20px;
    }
    .content__1--left {
        padding: 10px;
    }
    .content__1--download img {
        height: 65px;
        width: 200px;
    }
    .content__2--item {
        padding: 80px 110px;
    }
    .content__2--right img {
        left: 0;
        width: 510px;
        height: 590px;
    }
    .content__2--icon {
        width: 100px;
        height: 100px;
    }
    .content__2--title {
        font-size: 50px;
        padding-left: 30px;
    }
    .content__2--desc img {
        margin-right: 20px;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 20px;
        margin-bottom: 25px;
    }
    .content__2--background {
        width: 40%;
        height: calc(100% + 160px);
        top: 80px;
        bottom: -240px;
    }
    .content__3--body {
        padding: 80px 110px 30px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 50px 110px;
    }
    .content__3--title {
        font-size: 50px;
    }
    .footer {
        padding: 30px 0 0;
        position: relative;
    }
    .content__term--body {
        padding: 15px 110px;
        position: relative;
    }
}
@media only screen and (min-width: 769px) and (max-width: 1024px){
    .content__1--body {
        padding: 120px 60px 60px;
    }
    .content__1--logo {
        height: 65px;
        margin-bottom: 30px;
    }
    .content__1--title {
        font-size: 40px;
    }
    .content__1--right img {
        width: 340px;
    }
    .content__1--desc {
        font-size: 16px;
    }
    .content__1--left {
        padding: 10px;
    }
    .content__1--download img {
        height: 60px;
        width: 180px;
    }
    .download__play {
        margin-left: 20px;
    }
    .content__2--item {
        padding: 60px;
    }
    .content__2--right img {
        left: 40px;
        width: 355px;
        height: 410px;
    }
    .content__2--icon {
        width: 80px;
        height: 80px;
    }
    .content__2--title {
        font-size: 40px;
        padding-left: 25px;
    }
    .content__2--desc img {
        margin-right: 20px;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 16px;
        margin-bottom: 20px;
    }
    .content__2--background {
        width: 40%;
        height: calc(100% + 160px);
        top: 80px;
        bottom: -240px;
    }
    .content__3--body {
        padding: 20px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 20;
    }
    .content__3--title {
        font-size: 40px;
    }
    .item__title {
        font-size: 18px;
    }
    .item__desc {
        font-size: 16px;
    }
    .footer {
        padding: 20px;
        position: relative;
        padding-bottom: 0;
    }
    .content__term--body {
        padding: 20px;
        position: relative;
    }
    .content__term--desc {
        font-size: 16px;
    }
    .content__term--header {
        font-size: 18px;
    }
}
@media only screen and (max-width: 768px){
    .rx-toggle_1 {
        display: none;
    }
    .rx-toggle_2 {
        display: none;
    }
    .rx-toggle_3 {
        display: none;
    }
    .rx-toggle_4 {
        display: none;
    }
    .rx-toggle_5 {
        display: none;
    }
    .rx-toggle_6 {
        display: none;
    }
    .content__1--body {
        padding: 20px 15px;
        display: block;
        background-position: right -60px top;
        background-size: 50%;
    }
    .content__1--logo {
        height: 40px;
        margin-bottom: 10px;
    }
    .content__1--title {
        font-size: 30px;
    }
    .rx-mobile {
        display: block;
    }
    .phone_1 {
        width: 165px;
        margin: 10px auto;
    }
    .content__1--right {
        display: none;
    }
    .content__1--desc {
        font-size: 14px;
    }
    .content__1--left {
        padding: 0px;
    }
    .content__1--download img {
        height: 45px;
        width: 137px;
    }
    .download__play {
        margin-left: 15px;
    }
    .content__2--item {
        padding: 20px 15px;
        padding-top: 100px;
    }
    .content__2--background {
        width: 40%;
        height: 130px;
        top: -30px;
        bottom: unset;
    }
    .content__2--right {
        display: none;
    }
    .content__1--download {
        position: relative;
    }
    .phone_2 {
        width: 297px;
        margin: 40px auto 10px;
    }
    .content__2--body {
        display: block;
    }
    .content__2--icon {
        width: 64px;
        height: 64px;
    }
    .content__2--title {
        font-size: 25px;
        padding-left: 30px;
    }
    .content__2--desc img {
        margin-right: 13px;
        width: 16px;
        height: 10px;
        object-fit: contain;
    }
    .content__2--desc {
        padding-top: 40px;
    }
    .content__2--desc div {
        font-size: 14px;
        margin-bottom: 15px;
    }
    .content__3--body {
        padding: 20px 15px;
        background-size: 35%;
    }
    .content__faq .content__3--body {
        background-image: unset;
        padding: 15px;
    }
    .content__3--title {
        font-size: 24px;
    }
    .content__3--desc {
        padding-top: 20px;
    }
    .content__faq .content__3--desc {
        padding: 0;
    }
    .item__title {
        font-size: 16px;
        padding-right: 20px;
    }
    .item__content {
        font-size: 14px;
    }
    .footer {
        padding: 0;
        padding-top: 25px;
        padding-bottom: 0px;
        display: grid;
        position: relative;
    }
    .footer__logo {
        width: 70px;
        height: 24px;
        margin: auto;
    }
    .content__term--body {
        padding: 15px;
    }
    .content__term--desc {
        font-size: 14px;
    }
    .content__term--header {
        font-size: 16px;
    }
}