.react-datepicker-wrapper input {
    width: 100%;
}
.rxselectbox-wrap {
    position: relative;
    width: calc(100% - 150px);
    display: inline-block;
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.2);
    background-color: #f8f8f8;
}
.rx-form__item .rxselectbox-wrap {
    background-color: #fff;
}

.rxselectbox-filterinput {
    padding: 0 10px;
    margin-left: 10px !important;
    margin-right: 10px !important;
    width: 90% !important;
}

.rxselectbox-toggleholder {
    position: relative;
    width: 100%;
    height: 235px;
}

.rxselectbox-toggle {
    position: absolute;
    background-color: #fff;
    width: 100%;
    box-shadow: 1px 1px 20px rgba(0,0,0,0.2);
    box-sizing: border-box;
    padding-top: 10px;
    padding-bottom: 10px;
    /*min-height: 150px;*/
    z-index: 10001;
    /*display: none;*/
    max-height: 300px;
    overflow-y: auto;
}

.rxselectbox-wrap:hover .rxselectbox-toggle {
    /*display: block;*/
}

.rxselectbox-toggle>div {
    padding: 10px 10px;
    cursor: pointer;
}

.rxselectbox-toggle>div:first-child {
    cursor: pointer;
}

.rxselectbox-filter input {
    width: 100%;
    margin: 0px;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: 100% -21px !important;
}

.rxselectbox-toggle>div:hover {
    background-color: #2196F3;
    color: #fff;
}

.rxselectbox-optionholder {
    height: 230px;
    overflow-y: scroll;
}

.rxselectbox-optionitem {
    padding: 10px;
    width: 100%;
    border-bottom: solid 1px #dfdfdf;
}

.rxselectbox-toggle>div:first-child:hover {
    background-color: #ffffff;
    color: #000;
}

.rxselectbox-filter {
    /*position: absolute;*/
    left: 0px;
    /*top: -37px;*/
    width: 100%;
    /*display: none;*/
    position: relative;
}

.rxselectbox-result {
    min-height: 33px;
    /*background-color: #fff;*/
    /*border: 1px solid #eee;*/
    /*margin-top: 5px;*/
    margin-bottom: 0;
    /* padding: 10px 5px; */
    padding-bottom: 0px;
    box-sizing: border-box;
    width: 100%;
    border-radius: 4px;
    cursor: pointer;
}

.rxselectbox-result>div, span.tags {
    /*margin-right: 5px;*/
    /* margin-bottom: 5px; */
    padding: 13px 10px;
    padding-right: 30px;
    float: left;
    /*border: 1px solid #eee;*/
    border-radius: 3px;
    cursor: pointer;
    /*background-color: #fff;*/
    position: relative;
}

.rxselectbox-result>div>b, a.tagsremove {
    padding: 1px 4px;
    margin-left: 3px;
    border-radius: 15px;
    background-color: #000000;
    color: #fff;
    font-weight: 100;
    display: inline-block;
    width: 14px;
    height: 14px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    text-align: center;
    margin-top: 0px;
    position: absolute;
    top: 3px;
    right: 3px;
    line-height: 10px;
    font-size: 9px;
}

.rxselectbox-resultempty {
    position: absolute;
    top: 13px;
    left: 15px;
    font-style: italic;
    color: #aaa;
}
.rx-fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.rx-dropdown {
    /*float: right;*/
    position: absolute;
    right: 0;
    margin: 11px;
    font-size: 20px;
}
.rx-dropdown:before {
    color: #000 !important;
}
.rx-search-icon {
    display: inline-block;
    width: 18px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    border-left: 1px solid #cccccc;
}
.rx-dropdown>span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: 0px 5px;
}
.rx-dropdown.up>span {
    display: block;
    width: 100%;
    height: 100%;
    background-image: url(/images/static/select2x2.png) !important;
    background-repeat: no-repeat !important;
    background-size: 60px 40px !important;
    background-position: -18px 3px;
}
.rx-no-result {
    background-color: #fcf8e3;
    color: #8a6d3b;
}
.rxselectbox-result>div, .rxselectbox-toggle>div {
    color: #000;
    font-size: 14px;
    line-height: 14px;
    text-align: left;
}
.margin-15 {
    margin: 0 -15px;
}
.margin-10 {
    margin: 0 -10px;
}
.betable__filter .rxselectbox-wrap {
    min-width: 100%;
}

.rxcheckbox-wrap {
  width: 100%;
  max-height: 700px;
  overflow-y: auto;
}
.checkbox__toggle {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
}
.checkbox__item {
  padding: 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
}
.checkbox__item input {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}
.rs-checkbox-payment-small {
  width: 20px;
  height: 20px;
  border: 1px solid #aaa;
  border-radius: 2px;
  cursor: pointer;
  margin-right: 10px;
  position: relative;
}
.rs-checkbox-payment-small.active {
  background-color: #2869b5;
}
.rs-checkbox-payment-small.unactive {
    background-color: #be3e3f;
}
.rs-checkbox-payment-small.active>span, .rs-checkbox-payment-small.unactive>span {
  position: absolute;
  top: 2px;
  left: 2px;
  font-size: 15px;
  color: #fff;
  font-weight: bold;
}
.rx--box__search {
    position: relative;
}
.checkbox__filter {
  margin-bottom: 20px;
}
.checkbox__filter input {
  width: 200px;
}
.checkbox__filter>span {
  left: 250px;
}
.checkbox__item:last-child {
    border-right: 1px solid #ddd;
}
.rx--box__header {
    display: flex;
    align-items: center;
}
.rx--box__import {
    margin-left: 20px;
    display: flex;
    align-items: center;
}
.rx--box__import input {
    margin-top: 0 !important;
}
.rx--box__search .searchbox__toggle-icon {
    right: 10px;
    left: unset;
}
.addetail_card {
    padding-top: 70px;
    position: absolute;
    width: calc(100% - 270px);
    /* height: 100%; */
    z-index: 10001;
}
.addetail_card .adform__footer .adform__btns {
    width: calc(100% - 330px);
}
.rx-inline {
    float: left;
}
.rxSelectbox__addbtn {
    color: #fff;
    text-align: center !important;
    margin: 0 10px;
    justify-content: center;
}
.rxtoggle__phase.rx-fixed {
    background-color: rgba(0,0,0,.8);
    z-index: 10001;
    display: flex;
    align-items: center;
}
.rxtoggle__phase--body {
    height: 70%;
    width: 70%;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    max-width: 90%;
    margin: auto;
    padding: 30px;
}
.rxtoggle__phase--body .actions {
    position: absolute;
    bottom: 50px;
    right: 30px;
}
.rxtoggle__phase--body .rx-crud__form>div {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.rxtable__card {
    padding: 20px;
    background-color: #f8f8f8;
    display: block !important;
}
.rxtable__card span:first-child {
    padding-right: 15px;
}
.rxtable__card table {
    border: 1px solid #eee;
}
.rxtable__card table tbody {
    border-bottom: unset;
}

