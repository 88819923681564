.admin-product-img-upload {
  width: 112px;
  height: 112px;
  border: solid 1px #eee;
  padding: 3px;
  border-radius: 3px;
}
.rx-image-footer {
    position: absolute;
    left: 15px;
    width: 50px;
    border: 1px solid red;
    bottom: 15px;
    margin: 0 auto;
    width: 100%;
}
.rx-image-footer button {
    text-align: center;
    margin-left: 50%;
}
.imageRotate {
  transform-origin: top left;
  /* IE 10+, Firefox, etc. */
  -webkit-transform-origin: top left;
  /* Chrome */
  -ms-transform-origin: top left;
  /* IE 9 */
}
.imageRotate button {
    padding: 25px 18px;
    border-radius: 10px;
    border: 2px solid #fff;
    background: #2196F3;
    color: #fff;
    cursor: pointer;
}
.rotate90 {
  transform: rotate(90deg) translateY(-100%);
  -webkit-transform: rotate(90deg) translateY(-100%);
  -ms-transform: rotate(90deg) translateY(-100%);
}
.imageRotate.rotate90 {
  transform: rotate(180deg) translate(-100%, -100%);
  -webkit-transform: rotate(180deg) translate(-100%, -100%);
  -ms-transform: rotate(180deg) translateX(-100%, -100%);
}
.imageRotate.rotate90 {
  transform: rotate(270deg) translateX(-100%);
  -webkit-transform: rotate(270deg) translateX(-100%);
  -ms-transform: rotate(270deg) translateX(-100%);
}
