.popups {
    background-color: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
    opacity: 0.8;
}
.popup {
    /* height: 525px;
    width: 400px;
    border-radius: 5px;
    background-color: #E2E4E7; */
    position: fixed;
    /* max-width: 90%; */
    z-index: 1111;
}
.popups-transparent {
    background-color: transparent;
}
.popup__header {
    height: 65px;
    width: 100%;
    border-radius: 5px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #069616;
    position: absolute;
    top: 0;
}

.popup__title {
    width: 100%;
    font-size: 21px;
    font-weight: 600;
    color: #fff;
    background: transparent;
    text-align: center;
    padding: 20px;
}

.popup__close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 9;
}

.popup__close-delete {
    font-size: 25px;
    color: #fff;
}

.popup__body {
    /* width: 50%; */
    width: 70%;
    height: 450px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: 65px;
    background-color: transparent;
}

.popup__main {
    background-color: #ffffff;
    width: 100%;
    /* height: 435px; */
    height: auto;
    border-radius: 6px;
    padding: 30px;
    margin-top: 10px;
    min-height: 435px;
}

.popup__tabs {
    display: flex;
    justify-content: space-between;
}

.popup__tab {
    width: 50%;
    height: 44px;
    border-radius: 6px;
    background-color: #3d4145;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: 600;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
}

.popup__form-socials {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    background: transparent;
}
.popup__form-socials span {
  background: transparent;
}
.rxhide {
  display: block;
}

.popup__button {
    width: 100%;
    height: 46px;
    border-radius: 23px;
    background-color: #0072bd;
    font-family: Gilroy;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.5px;
    text-align: center;
    color: #ffffff;
    border: none;
    cursor: pointer;
    position: relative;
    outline: none;
    user-select: none;
    margin-bottom: 20px;
    margin-top: 5px;
  }

  .popup__button:after {
    content: '';
    width: 20px;
    height: 20px;
    position: absolute;
    /*background: url(/images/icons/next@2x.png);*/
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    background-size: contain;
  }

.popup__facebook {
    width: 144px;
    height: 50px;
    border-radius: 23px;
    background-color: #054c90;
    border: none;
    cursor: pointer;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.53px;
    text-align: center;
    color: #ffffff;
    outline: none;
    user-select: none;
}

.popup__google {
    width: 144px;
    height: 50px;
    border-radius: 23px;
    background-color: #d0021b;
    border: none;
    cursor: pointer;
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.53px;
    text-align: center;
    color: #ffffff;
    outline: none;
    user-select: none;
}

.popup__forgot {
    font-size: 14px;
    text-align: center;
    color: #7c838a;
    cursor: pointer;
    margin: 20px 0;
}

.popup__message {
    font-size: 14px;
    text-align: center;
    color: #3d4145;
    padding-bottom: 10px;
}

.popup__message-link {
    color: #0072bd;
    font-size: 14px;
    cursor: pointer;
}
.popup__message-link a {
    color: #0072bd;
    font-size: 14px;
    cursor: pointer;
}

.popup__form-label {
    font-size: 14px;
    font-weight: 600;
    color: #3d4145;
    display: block;
    margin-bottom: 15px;
    margin-top: 15px;
    background: transparent;
}
.popup__message {
  display: none;
}

.popup__form-group {
    margin-bottom: 20px;
    background: transparent;
}

.popup__form-input {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 8px 0;
    border-bottom: 1px solid #e2e4e7;
    font-family: Gilroy;
    font-size: 18px;
    color: #3d4145;
    width: 100%;
    padding-left: 15px;
}

.popup__form-input.input-partitioned {
    padding-left: 10px;
    letter-spacing: 34px;
    border: 0;
    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);
    background-position: bottom;
    background-size: 43px 1px;
    background-repeat: repeat-x;
    background-position-x: 30px;
    width: 250px;
}

.popup__form-input::placeholder {
    color: #c5c9d0;
}

.popup__select {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    padding: 7px 0;
    border-bottom: 1px solid #e2e4e7;
    font-family: Gilroy;
    font-size: 18px;
    color: #3d4145;
    width: 100%;
}

.popup__inline-form-group {
    display: flex;
    align-items: center;
}

/* .popup__inline-form-group div:first-child {
    margin-right: 24px;
}

.popup__inline-form-group div:last-child {
    flex: 1;
} */

.popup__tab--active {
    background-color: white;
    color: #3d4145;
    position: relative;
}

.popup__tab--active:after {
    content: ' ';
    display: block;
    position: absolute;
    width: 100px;
    height: 3px;
    border-radius: 1.5px;
    background-image: linear-gradient(268deg, #2b8845, #2b8845);
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.popup__back {
    position: absolute;
    left: 15px;
    top: 22px;
    cursor: pointer;
    z-index: 9;
}

.falert {
    text-align: center;
    height: 5px;
    font-size: 15px;
    color: red;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 10%;
    background: #fff;
    z-index: 100;
}

.iconiread-close.btn-close_popup:before {
    color: #fff;
}

.popup__message-err {
    color: red;
    font-size: 17px;
    text-align: center;
}

.popup__areaButton {
    height: 30px;
}

.popup_titleCheck {
    font-size: 18px;
    font-weight: 700;
}