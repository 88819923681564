.col--rightborder {
    border-right: solid 1px #eee;
}

.ppayment__cart {
    min-height: 400px;
}

.ppayment__info {
    max-width: 400px;
    display: block;
    margin: auto;
}
.ppayment__info label {
    width: 100%;
    display: block;
    font-weight: bold;
    margin-bottom: 7px;
    margin-top: 20px;
    font-size: 14px;
}

.ppayment__info label b {
    color: #E91E63;
    font-size: 20px;
    position: absolute;
    margin-left: 5px;
    margin-top: -5px;
}

.ppayment__info input {
    width: 100%;
    height: 40px;
    padding: 7px;
    font-size: 14px;
    border-radius: 3px;
    box-shadow: none;
    border: solid 1px #ddd;
}

.ppayment__done {
    background-color: #26c685;
    color: #fff;
    text-transform: uppercase;
    font-size: 16px;
    padding: 15px;
    text-align: center;  
    border-radius: 3px;
    cursor: pointer;
}

.ppayment__error {
    margin-top: 20px;
    height: 35px;
    line-height: 17px;
    color: #E91E63;
}

.pppayment__success {
    min-height: 500px;
    text-align: center;
    font-size: 24px;
    color: #4CAF50;
    padding-top: 30px;
}

.pcate {
    min-height: 1000px;
}

.pcate__search {  
    min-height: 1000px;
    border-right: solid 1px #eee;
}

.pcate__products {
    padding-top: 30px;
}

.pcate__slide {
    min-height: 200px;
    width: 100%;
    background-color: #fafafa;
    margin-top: 50px;
    margin-bottom: 30px;
}

.searchfield__name {
    width: 100%;
    margin: auto;
    height: 31px;
    border-radius: 3px;
    border: solid 1px #eee;
    padding-left: 10px;
    font-size: 12px;
}

.searchfield__namewrap {
    position: relative;
    width: 90%;
    cursor: pointer;
}

.searchfield__nameico {
    position: absolute;
    right: 0px;
    top: 0px;
    font-size: 15px;
    padding: 8px 7px;
    color: #abb7cc;
    box-sizing: border-box;
}

.searchfield__filter {
    padding-top: 30px;
    padding-bottom: 30px;
}

.searchfield__cate label {
    font-size: 12px;
    color: #888;
    display: block;
    width: 100%;
    margin-bottom: 10px;
}

.searchfield__cateitem {
    position: relative;
    padding: 7px 13px;
    cursor: pointer;
}

.searchfield__cateitem:before {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-left: 4px solid black;
    left: 0px;
    top: 10px;
}

.block__parallax {
    padding-top: 180px;
    position: relative;
    min-height: 550px;
    background-image: url('/images/static/parallaximg.jpg');
    height: 100%; 
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.block__parallaxstroketop {
    width: 100%;
    position: absolute;
    top: 0px;
    background-image: url('/images/static/stroketop.png');
    background-repeat: repeat-x;
    height: 100px;
    background-size: contain;
    background-position-y: 0%;
}

.block__parallaxstrokebot {
    width: 100%;
    position: absolute;
    bottom: 0px;
    background-image: url('/images/static/strokebot.png');
    background-repeat: repeat-x;
    height: 100px;
    background-size: contain;
    background-position-y: 100%;
}

.block__parallaxtitle {
    font-size: 24px;
    line-height: 30px;
    padding-top: 17px;
    text-align: center;
    margin-bottom: 10px;  
    color: #1fb0dc;
}

.block__parallaxdesc {
    padding-left: 40px;
    padding-right: 30px;
    text-align: center;
    line-height: 24px;
    font-size: 14px;
    color: #333333;
    margin-bottom: 10px;
}

.block__parallaxcontent {
    margin: auto;
    width: 80%;
    max-width: 600px;
    min-height: 140px;
    background-color: #ffffffd1;
    box-shadow: 0 0 40px rgba(0,0,0,0.3);
    border: solid 1px #ffff;
    padding: 10px;
    padding-top: 0px;
}