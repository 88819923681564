/* adtopbar */
.adtopbar {
  height: 70px;
  /*box-shadow: 0 1px 4px rgba(0,21,41,.08);*/
  background-color: #147ce1;
  border-bottom: solid 3px #014391;
  width: 100%;
  z-index: 10001;
  position: fixed;
  top: 0px;
  left: 0px;
  background-image: url('/images/static/bg_hd.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: left;
  display: flex;
  align-items: center;
}
.adtopbar__left {
    display: flex;
    align-items: center;
    margin-left: 15px;
}
.adtopbar__left img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
}
.adtopbar__logo--sub {
    color: #fff;
    font-size: 18px;
    line-height: 1.4;
}
.adtopbar__logo--title {
    color: #fff;
    font-size: 20px;
    /* line-height: 0.54; */
    letter-spacing: 0.48px;
    font-weight: 500;
    line-height: 1.2;
}
.adtopbar__togglesidebar {
  left: 0px;
  /* position: fixed; */
  padding: 23px;
  font-size: 25px;
  color: #aaa;
  line-height: 0px;
  top: 0px;
  background: transparent;
  z-index: 101;
}
.adtopbar__togglesidebar.rxsideactive {
  left: 0;
}
.adtopbar__right {
  float: right;
  display: flex;
  align-items: center;
  height: 100%;
  position: absolute;
  right: 0;
}
.adtopbar__center {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    text-align: center;
    justify-content: center;
}
.adtopbar__right>div {
  float: left;
  height: 100%;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  width: 70px;
  /*border-left: 1px solid #e7eaef;*/
  /*padding: 23px;*/
}
.adminnav__notify {
  padding: 23px !important;
  color: #fff;
}
.adtopbar__addcard.hotline {
  background: #127ce1;
  border-right: 1px solid;
  display: block;
  padding: 25px 10px;
}
.hotline {
  display: block;
  text-align: left;
  color: #fff;
  /* padding: 3px 0px; */
}
.hotline p {
  display: inline-block;
}
.p1 {
  width: 38px;
  font-size: 14px;
}
.adtopbar__right .adminnav__avatar {
  width: 200px;
  padding: 0;
  background-color: #295a93;
  color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
}
.adminnav__avatar>div:first-child {
  width: 100%;
  height: 100%;
  background-color: #295a93;
  color: #fff;
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px;
}
.adminnav__avatar>div>span {
  font-size: 14px;
  line-height: 1.29;
}
.adminnav__avatar-icon {
    font-size: 25px !important;
    line-height: 0 !important;
    position: absolute;
    margin-top: 5px;
    right: 0px;
    padding: 0 25px;
}
.adtopbar__right .adminnav__avatar>span {
  /*position: absolute;
  width: 36px;
  left: 0px;
  height: 36px;
  top: 0px;
  padding: 18px 0px;
  line-height: 0px;*/
  text-align: center;
  font-size: 32px;
}
.topnav-icon {
  /*line-height: 0px;*/
  cursor: pointer;
  font-size: 23px;
  color: #ffffff;
}

/* beside */
.beside {
  position: fixed;
  /*width: 230px;*/
  width: 270px;
  height: 2060px;
  z-index: 100;
  top: 70px;
  box-shadow: 0 0 35px 0 rgba(154, 161, 171, 0.15);
  background-color: #1c67af;
  /*box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;*/
}
.beside.rxsideactive {
  display: none;
}
.beside__navitems {
}
.beside__navitemsinner {
  position: absolute;
  width: 100%;
  /*top: 50px;*/
  padding-top: 0px;
  padding-bottom: 200px;
  overflow-y: auto;
  height: 100%;
  background-image: url('/images/static/bg@3x.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}
.beside__logo {
  height: 60px;
  width: 100%;
  text-align: center;
  line-height: 0px;
  padding: 30px;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 2px;
  color: #fff;
  background-image: linear-gradient(180deg, #000000, #00142a);
}
.beside__logoversion {
  font-size: 10px;
  margin-top: 25px;
  position: absolute;
}
.beside__logoicon {
  float: left;
  color: #E91E63;
  font-weight: bold;
}
.adnav__groupwrap {
  position: relative;
  display: none;
  padding: 10px 0;
  background-color: #24527f;
}
.adnav__groupwrap--card {
  padding: 2px 0;
  background-color: #24527f;
  overflow-x: auto;
}
.adnav__groupwrap.rxactive {
  display: block;
}
/*.adnav__groupwrap:before {
  content: '';
  width: 1px;
  height: 100%;
  background: rgba(255,255,255, 0.7);
  display: block;
  position: absolute;
  left: 29px;
  top: -7px;
}*/
.adnav__groupname:before {
  font-weight: normal;
  font-size: 20px;
  position: absolute;
  right: 20px;
  /*top: 20px;*/
}
.adnav__groupname2:before {
  color: #000000 !important;
  font-weight: normal;
  font-size: 30px;
  position: absolute;
  right: 20px;
  /*top: 20px;*/
}
.adnav__groupname.expand, .adnav__groupname.rxactive {
  /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);*/
  background-color: #147ce1;
  color: #fff;
}
.adnav__groupname.expand>span, .adnav__groupname.expand>span>i {

  color:#fff;
}
.adnav__itemname.rxactive .adnav__groupname {
  background-color: #be3e3e;
}
.adnav__groupname2.expand {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #a6a6a6;
  color: black;
}
.adnav__groupname2.rxactive {
  display: block;
  background-color: #f0f2f5;
  border-bottom: #fff ;
}
.adnav__groupname2.expand>span, .adnav__groupname2.expand>span>i {
  color:black;
}
.adnav__itemname.rxactive .adnav__groupname2 {
  background-color: #be3e3e;
}
.adnav__groupname--link:after {
  display: none;
}
.adnav__groupname {
  background-color: #165ca6;
  position: relative;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 1px solid #5387c4;
}
.adnav__groupname2 {
  background-color:#dddd;
  position: relative;
  height: 55px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  cursor: pointer;
  border-bottom: 2px solid #bfbfbf;
}
.adnav__groupname>a {
  padding: 25px 50px;
  color: #ffffff;
  letter-spacing: 2.1px;
  display: block;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.adnav__groupname2>a {
  padding: 25px 50px;
  color: #444;
  letter-spacing: 2.1px;
  display: block;
  font-size: 15px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
}
.adnav__groupname>a>i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0px;
  margin-top: 6px;
  margin-left: -30px;
  position: absolute;
  color: #ffffff;
  font-weight: bold;
}
.adnav__groupname2>a>i {
  margin-right: 10px;
  font-size: 18px;
  line-height: 0px;
  margin-top: 6px;
  margin-left: -30px;
  position: absolute;
  color: black;
  font-weight: bold;
}
.adnav__groupname>span {
  color: #ffffff;
  font-size: 17px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2.4;
  font-weight: bold;
}
.adnav__groupname2>span {
  color: #000000;
  font-size: 20px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  line-height: 2.4;
  font-weight: bold;
}
.adnav__groupname>span>i {
  margin-right: 10px;
  font-size: 20px;
  float: left;
  margin-top: 7px;
  color: #015bb4;
  font-weight: bold;
}
.adnav__groupname2>span>i {
  margin-right: 10px;
  font-size: 20px;
  float: left;
  margin-top: 7px;
  color: #015bb4;
  font-weight: bold;
}
.adnav__groupname--expand>a {
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>a {
  /*background-color: #1890ff;*/
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>a>i {
  /*color: #ffffff;*/
}
.adnav__groupname--expand>span {
  /*color: #ffffff;*/
}
.adnav__groupname.rxactive>span {
  /*background-color: #1890ff;*/
  /*color: black;*/
}
.adnav__groupname.rxactive>span>i {
  color: black;
}
.adnav__groupname2.rxactive>span {
  /*background-color: #1890ff;*/
  color: black;
}
.adnav__groupname2.rxactive>span>i {
  color: #ffffff;
}
.adnav__groupname--first {
  /*margin-top: 40px;*/
}
.adnav__groupname--last {
  position: fixed;
  bottom: -1px;
  width: 270px;
  padding: 0;
  /*background-color: #010f1e;*/
}
.adnav__groupname2--last {
  position: fixed;
  bottom: -1px;
  width: 270px;
  padding: 0;
  /*background-color: #010f1e;*/
}
/*.adnav__groupname:hover {
  color: rgba(255,255,255,1);
}
.adnav__groupname:hover>a {
  color: #ffffff;
}*/
.adnav__itemname {
  line-height: 0px;
}
.adnav__itemname>a {
  /*height: 30px;*/
  padding: 15px 50px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 0px;
  display: block;
  width: 100%;
  white-space: nowrap;
  /*background: #0072C6;
  border-bottom: solid 1px #015bb4;*/
}
.adnav__itemname.rxactive>a {
  background-color: #074391;
  color: #ffffff;
}
.adnav__itemname>span {
  /*height: 30px;*/
  padding: 15px 50px;
  color: #ffffff;
  text-decoration: none;
  font-size: 16px;
  line-height: 0px;
  display: block;
  width: 100%;
  white-space: nowrap;
  /*background: #0072C6;
  border-bottom: solid 1px #015bb4;*/
}
.adnav__itemname.rxactive>span {
  background-color: #074391;
  color: #ffffff;
}
/*.adnav__itemname>a:before {
  content: '';
  background: rgba(255,255,255, 0.7);
  height: 1.1px;
  width: 5px;
  position: absolute;
  left: 30px;
}*/
.rxhide {
  display: none;
}
.rxshow {
  display: block;
}
.rxfull {
  width: 100%;
}
/*.adnav__itemname:hover {
  background-color: rgba(255,255,255,0.1);
}*/
.adnav__itemname .nav-icon {
  margin-right: 10px;
  line-height: 0px;
  color: #ffffff;
  font-size: 20px;
}
.adnav__itemname_lv2 .nav-icon, .adnav__itemname_lv3 .nav-icon {
    color: #b3b3b3;
}
.adnav__itemname .nav-icon.icon-graph {
  display: none;
}
/* bemain */
.bemain {
  padding-left: 270px;
  min-height: 2000px;
  /*overflow: hidden;*/
  background-color: #f0f2f5;
}
.bemain.rxsideactive {
  padding-left: 0px;
}
.bemain__wrap {
  padding: 30px;
}
.avatar_name {
    font-size: 11px;
    color: #ffffff;
    font-style: italic;
}
.rx-font-14 {
  font-size: 14px;
}
.rx-require {
    color: red;
}

.adtopbar__logo>a {
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 30px;
}
.adtopbar__logo {
    width: 270px;
    height: 100%;
    float: left;
    border-right: 2px solid #f5f4f4;
}
.adtopbar__title {
    /*float: left;*/
    color: #fff;
    /*display: flex;*/
    /*align-items: center;*/
    /*height: 100%;*/
    /*padding-left: 100px;*/
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    font-size: 24px;
    font-weight: bold;
    line-height: 0.54;
    letter-spacing: 0.48px;
    text-transform: uppercase;
}
.rx-authpage {
  position: absolute;
  width: 100%;
  height: calc(100% - 70px);
  top: 70px;
  left: 0;
  background-image: url('/images/static/around.png');
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  /*background: radial-gradient(ellipse at center, rgba(255,255,255,1) 0%, rgba(129,214,254,1) 100%);*/
}

input#HoSoID-All {
    padding-left: 20px;
    width: 16px;
    height: 16px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 5px;
    margin-left: -5px;
}
.css-checkboxShow {
  border: solid 1px #adb5bd;
  border-radius: 4px;
  background-color: #fff;
  width: 16px;
  height: 16px;
}
label.css-label {
    margin-left: 5px;
}
input#IDHoSo-233 {
    padding-left: 20px;
    width: 16px;
    height: 16px;
    display: inline-block;
    line-height: 20px;
    background-repeat: no-repeat;
    background-position: 0 0;
    vertical-align: middle;
    cursor: pointer;
    padding-right: 5px;
    margin-left: -5px;
    margin-right: 5px;
    border: solid 1px #adb5bd;
    border-radius: 4px;
    background-color: #fff;
}
.col-md-12_manualtxt1 {
    color: #000080;
    font-size: 25px;
    font-family: Tahoma;
    font-weight: bold;
    float: left;
}
p.col-md-12_manualtxt2 {
    text-align: left;
    font-size: 22px;
}
h1.col-md-12_manualtxt2 {
  text-align: left;
  font-size: 26px;
  color: #e60000 !important;
  font-weight: bold;

}

.body_manual {
    width: 100%;
    margin-top: 15px;
}
img.manual__img {
    height: auto;
    display: block;
    margin: auto;
    vertical-align: middle;
    max-width: 100%;
}
.manual_content {
    background: white;
    color: #000000;
    font-size: 16px;
    font-family: Tahoma;
    margin-top: 15px;
}
h1.content_note1 {
    font-weight: bold;
    font-size: 29px;
    text-align: left;
    color: #000000;
}
p.content_note2 {
    color: #e60000;
    font-weight: 900;
    text-align: left;
    font-size: 22px;
}
p.content_note1 {
    text-align: left;
}
strong.content_note1 {
    color: #e60000;
    font-weight: bold;
    text-align: left;
    font-size: 22px;
}
strong.content_note2 {
    color: #0000b3 !important;
    text-align: left;
    font-weight: bold;
    font-size: 25px;
}

.rxpopup-addnew-cus__.print {
    position: relative;
    top: 0;
    left: 0;
    width: 494px;
    height: 317px;
    z-index: 99999;
}
.print .adblock--print {
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 12px;
}
.print .adblock__image {
  background-image: url(/images/static/background_card.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 100%;
  height: 100%;
}
.rxprint__sign--img {
  height: 60px;
  width: 100%;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rxprint__sign--img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
/*.print { display: none !important; }*/
._print_form_add {
  font-weight: normal;
}
._print_width_50 {
  width: 50%;
  display: inline-block;
  padding-right: 10px;
  font-size: 8px;
}
@media print {
  html, body {
    font-size: 5.25pt !important;
    /* height: 100%; */
    width: 100%;
    /* position: fixed; */
    /* position: absolute; */
    overflow: hidden;
    line-height: 0.5 !important;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    /* margin: 0;
    padding: 0; */
  }
  .adblock__head,.footer, .adform__btns.rx-stickybot, .betable,
  .adtopbar, .beside,.adform__typecard, .adform__header, .adform__body.col-md-2, .adform__footer,
  .rx-crud__form, .adform {display: none !important; height: 0 !important; min-height: unset !important; padding: 0 !important;}
  .bemain {min-height: unset !important; padding: 0 !important}
  .main-container {min-height: unset !important;}
  .adblock__body, .adblock {padding: 0 !important;}
  .print {
    display: block !important;
    z-index: 1000;
  }
  .rxprint__info>p {
    line-height: 1.2 !important;
  }
  .adblock__label {
    /* width: 325px; */
    /* height: 205px; */
    /* margin: auto; */
    position: relative;
    overflow: visible;
  }
  .rxprint__title {
    padding-top: 12pt;
    text-align: center;
  }
  .rxprint__title-main {
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    font-size: 11.5pt;
    font-weight: bold;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #ed3237;
  }
  .rxprint__title-sub {
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    font-size: 10pt;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #373435;
  }
  .print .adblock__inner { box-shadow: unset !important; }
  .print .adblock--print { padding: 0; }
  .print .avatar_print {
    height: 85pt !important;
    width: 56.7pt !important;
    max-width: 56.7pt !important;
    padding: 0;
    object-fit: cover;
    /* margin-left: 25mm; */
    /* margin-top: -10pt !important; */
  }
  .print .avatar_print img {
    height: 85pt !important;
    width: 56.7pt !important;
    max-width: 56.7pt !important;
    padding: 0;
    object-fit: cover;
    position: absolute;
    top: 14.6pt;
    left: 12.6pt;
  }
  .print .form-header {
    padding-top: 105px;
    padding-left: 157px;
    padding-right: 12.6pt;
  }

  .textCenter__print {
    display: inline-block;
    width: 100%;
    font-size: 5.25pt !important;
    font-weight: 600;
  }

  .textName___print {
    font-weight: bold !important;
  }

  .textOrganization___print {
    white-space: pre-wrap;
  }

  .barcode__print {
    position: absolute;
    right: 16.2pt;
    top: 5.9pt;
    width: 36.9pt;
    height: 36.9pt;
  }

  .text__print {
    font-size: 5.25pt !important;
    font-weight: bold;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Times New Roman', sans-serif;
    display: flex;
  }
  .text__print--info {
    font-size: 12px !important;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #373435;
  }
  .text__print--info span {
    font-weight: bold;
    font-family: 'Times New Roman', sans-serif;
  }
  .text__print--sign span {
    font-family: 'Times New Roman', sans-serif;
  }
  .text__print--sign {
    text-align: center;
    font-size: 11px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 13px;
    letter-spacing: normal;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    color: #373435;
  }
  .text-center > P {
    display: block;
  }

  .text__print > span {
    font-size: 12px !important;
    /* font-family: 'TimeNew', sans-serif; */
    font-family: 'Times New Roman', sans-serif;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 18px;
    letter-spacing: normal;
    text-align: left;
    color: #373435;
  }
  .rxprint__info {
    position: absolute;
    top: 220px;
    left: 27px;
  }
  .rxprint__sing-name {
      /* position: absolute; */
      width: 195pt;
      font-weight: bold;
      font-size: 5.25pt !important;
      text-align: center;
      /*font-family: 'TimeNew', sans-serif;*/
      font-family: 'Times New Roman', sans-serif;
  }
  .rxprint__sign {
    width: 210px;
    position: absolute;
    right: 30px;
    top: 197px;
  }
  .rxprint__sign>div {
    white-space: nowrap;
  }
}
.rxprint__info>p {
  line-height: 1.2 !important;
}
.adblock__label {
  /* width: 325px; */
  /* height: 205px; */
  /* margin: auto; */
  position: relative;
  overflow: visible;
}
.rxprint__title {
  padding-top: 20pt;
  width: 100%;
  text-align: center;
}
.rxprint__title-main {
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 23px;
  font-weight: bold;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #ed3237;
}
.rxprint__title-sub {
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  text-align: center;
  color: #373435;
}
.print .adblock__inner { box-shadow: unset !important; }
.print .adblock--print { padding: 0; }
.print .avatar_print {
  padding: 0;
  object-fit: cover;
  position: absolute;
  height: 170px !important;
  width: 111px !important;
  max-width: 111px !important;
  padding: 0;
  object-fit: cover;
  position: absolute;
  top: 34px;
  left: 28px;
}
.print .avatar_print img {
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
  padding: 0;
  object-fit: cover;
}
.print .form-header {
  padding-top: 25px;
  padding-left: 153px;
  padding-right: 12.6pt;
}

.textCenter__print {
  display: inline-block;
  width: 100%;
  font-size: 5.25pt !important;
  font-weight: 600;
}

.textName___print {
  font-weight: bold !important;
}

.textOrganization___print {
  white-space: pre-wrap;
}

.barcode__print {
  position: absolute;
  right: 37px;
  top: 18px;
}
.barcode__print img {
  width: 100%;
  height: 100%;
}

.text__print {
  font-size: 5.25pt !important;
  font-weight: bold;
  /*font-family: 'TimeNew', sans-serif;*/
  font-family: 'Roboto', sans-serif;
  display: flex;
  width: 100%;
}

.text__print--info {
  font-size: 12px !important;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: #373435;
}
.text__print--info span {
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
}
.text__print--sign span {
  font-family: 'Roboto', sans-serif;
}

.text__print--sign {
  text-align: center;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 13px;
  letter-spacing: normal;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  color: #373435;
}

.text-center > P {
  display: block;
}

.text__print > span {
  font-size: 12px !important;
  /* font-family: 'TimeNew', sans-serif; */
  font-family: 'Roboto', sans-serif;
  /*font-weight: normal;*/
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: normal;
  text-align: left;
  color: #373435;
}
.rxprint__info {
  position: absolute;
  top: 220px;
  left: 27px;
}
.rxprint__sing-name {
    /* position: absolute; */
    width: 195pt;
    font-weight: bold;
    font-size: 5.25pt !important;
    text-align: center;
    /*font-family: 'TimeNew', sans-serif;*/
    font-family: 'Roboto', sans-serif;
}
.rxprint__sign {
  width: 210px;
  position: absolute;
  right: 30px;
  top: 207px;
}
.rxprint__sign>div {
  white-space: nowrap;
}
.chart__total {
  color: #98a6ad;
  text-align: center;
  font-size: 14px;
  padding-top: 25px;
  line-height: 1.14;
  font-weight: bold;
}
.chart__total-number {
  font-size: 30px;
  line-height: 1.17;
  text-align: center;
  color: #343a40;
}
.chart__filter {
  display: flex;
  align-items: center;
  float: right;
}
.chart__filter-left .rxselectbox-wrap {
  width: 300px;
  margin-right: 10px;
}
.chart__filter-right {

}
.react-bootstrap-daterangepicker-container {
  float: right;
  padding: 9px 15px;
  font-size: 15px;
  cursor: pointer;
  color: #4c5b69;
  border-radius: 3px;
  border: solid 1px #e6edf4;
  background-color: #f9fbfd;
}
.chart__filter-item {
  padding: 7px 10px;
  border: 1px solid #eee;
  margin-left: 5px;
  border-radius: 3px;
  font-size: 14px;
  background-color: #f3f7f9;
  cursor: pointer;
}
.chart__filter-item.active {
  background-color: #6c757d;
  color: #fff;
}
.authform__btnsubmit.redirect {
  margin-top: 10px;
  letter-spacing: normal;
  background: #f2f2f2;
  color: #323232;
  font-weight: 500;
}
.authpage__toggle {
    width: 600px;
    margin: auto;
    background: #fff;
    border: 1px solid #eee;
    padding: 20px;
    /* height: 300px; */
}
.authpage__toggle .authform__head {
  text-align: center;
}
.authpage__toggle-body {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
}
.authpage__item {
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15);
  padding: 20px;
  display: grid;
}
.authpage__item img {
  width: 80px;
  height: 80px;
  margin: auto;
  object-fit: contain;
}
.authpage__item div {
  font-size: 14px;
  padding: 10px 0;
  font-weight: 400px;
  text-align: center;
}
.adtopbar__addcard {
  display: flex;
  align-items: center;
  width: unset !important;
  color: #fff;
  background-color: #295a93;
  padding: 10px 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 1px;
  font-weight: bolder;
}
.adtopbar__addcard:hover {
  background-color: #3e6a9d;
}
.adtopbar__addcard>i {
  font-size: 20px;
}
.noti__menu {
  display: flex;
  align-items: center;
}
.noti__menu>div:first-child {
  position: relative;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  width: 100%;
  display: flex;
  align-items: center;
}
.noti__menu .betable__typecard--select {
  width: calc(100% - 200px);
  position: absolute;
  right: 0px;
  /*border-bottom: 1px solid #eee;
  padding-bottom: 10px;*/
}
.noti__menu .rxselectbox-wrap {
  width: 100%;
}
.authform-register {
  padding: 30px;
  max-width: 1200px;
  margin: auto;
  width: 100%;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  box-shadow: 0 0 40px 0 rgba(0, 0, 0, 0.1), 0 5px 5px 0 rgba(0, 0, 0, 0.1);
}
.authform__title {
  background: #e7e7e7;
  padding: 10px 20px;
}
.authform__title span {
  padding: 5px;
  font-size: 18px;
  background-color: #fff;
  border: 1px solid #d1d9da;
  border-bottom: none;
  color: #3a88cb;
}
.authform-register .rx-form__item>div {
  padding: 10px 0;
}
.authform-register .rx-form__item_button>div{
  padding: 10px 0;
  margin-left: 295px;
  margin-top: 15px;
  width: 100%;
}

.authform__btnsubmit.register {
    width: 100%;
    /* background: #2d82cd; */
    letter-spacing: normal;
    font-weight: 400;
    padding: 10px 15px;
    margin-right: 15px;
}

.authform__btnsubmit.print {
  width: 100%;
  /* background: #2d82cd; */
  letter-spacing: normal;
  font-weight: 400;
  padding: 10px 15px;
}
.text__name {
  text-transform: uppercase;
}
.text__subname {
  text-transform: capitalize;
}
.sign__img {
  font-size: 60px;
  display: flex;
  justify-content: center;
  margin-left: -10px;
}
.sign__img1 {
  display: flex;
  justify-content: center;
  margin-left: 14px;
  height: 60px;
  margin-top: -5pt;
  font-size: 71px;
  transform: rotate(5deg);
  margin-bottom: 6pt;
}

@media (max-width: 890px){
  .authform-register .rx-form__item_button>div{
    padding: 10px 0;
    margin-left: 0px !important;
    margin-top: 15px;
    width: 100%;
  }
  .authform__btnsubmit.register{
    max-width:100%;
    float: left;
    text-align: center;
    }

  .authform__btnsubmit.print{
    max-width:100%;
    float: left;
    margin-top: 10px;
    margin-left: 0px !important;
    text-align: center;
    }
}