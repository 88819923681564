@font-face {
  font-family: 'icomooncustom';
  src:  url('fonts/icomooncustom.eot?mq5egi');
  src:  url('fonts/icomooncustom.eot?mq5egi#iefix') format('embedded-opentype'),
    url('fonts/icomooncustom.ttf?mq5egi') format('truetype'),
    url('fonts/icomooncustom.woff?mq5egi') format('woff'),
    url('fonts/icomooncustom.svg?mq5egi#icomooncustom') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconcustom-"], [class*=" iconcustom-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomooncustom' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconcustom-cmnd:before {
  content: "\e90b";
}
.iconcustom-Group-2:before {
  content: "\e90c";
}
.iconcustom-uniE90D:before {
  content: "\e90d";
}
.iconcustom-uniE90E:before {
  content: "\e90e";
}
.iconcustom-record:before {
  content: "\e90f";
  color: #ffffff;
}
.iconcustom-find:before {
  content: "\e910";
}
.iconcustom-home:before {
  content: "\e911";
  color: #ffffff;
}
.iconcustom-card:before {
  content: "\e912";
  color: #ffffff;
}
.iconcustom-acc:before {
  content: "\e913";
}
.iconcustom-system:before {
  content: "\e914";
  color: #ffffff;
}
.iconcustom-setting:before {
  content: "\e915";
  color: #ffffff;
}
.iconcustom-down:before {
  content: "\e916";
  color: #ffffff;
}
.iconcustom-up:before {
  content: "\e917";
}
.iconcustom-require:before {
  content: "\e918";
}
.iconcustom-avatar:before {
  content: "\e919";
}
.iconcustom-Mask-Group-2-1:before {
  content: "\e900";
  color: #fff;
}
.iconcustom-plus:before {
  content: "\e901";
}
.iconcustom-Mask-Group-2-3:before {
  content: "\e902";
  color: #be3e3e;
}
.iconcustom-Mask-Group-2-4:before {
  content: "\e903";
}
.iconcustom-Mask-Group-2-5:before {
  content: "\e904";
}
.iconcustom-Mask-Group-2-6:before {
  content: "\e905";
  color: #000;
}
.iconcustom-Mask-Group-2-7:before {
  content: "\e906";
  color: #fff;
}
.iconcustom-Mask-Group-2-8:before {
  content: "\e907";
}
.iconcustom-check:before {
  content: "\e908";
}
.iconcustom-Mask-Group-2-10:before {
  content: "\e909";
  color: #dbdbdb;
}
.iconcustom-Mask-Group-2:before {
  content: "\e90a";
}
.iconcustom-active:before {
  content: "\e91a";
}
.iconcustom-close:before {
  content: "\e91b";
  color: #919191;
}
.iconcustom-create:before {
  content: "\e91c";
  color: #fff;
}
.iconcustom-date:before {
  content: "\e91d";
}
.iconcustom-delete:before {
  content: "\e91e";
}
.iconcustom-export:before {
  content: "\e91f";
}
.iconcustom-export2:before {
  content: "\e920";
  color: #fff;
}
.iconcustom-import:before {
  content: "\e921";
}
.iconcustom-print:before {
  content: "\e922";
  color: #dbdbdb;
}
.iconcustom-sort .path1:before {
  content: "\e923";
  color: rgb(49, 55, 62);
}
.iconcustom-sort .path2:before {
  content: "\e924";
  margin-left: -1em;
  color: rgba(49, 55, 62, 0.3);
}
.iconcustom-sign:before {
  content: "\e925";
  color: #0f348b;
}
.iconcustom-sign1:before {
  content: "\e926";
  color: #0f348b;
}
.icon-images:before {
  content: "\e914";
}
