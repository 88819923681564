tr:nth-child(odd) {
    background: #f3f7f9;
}
td.rxsticky {
    position: sticky;
    right: -25px;
    background: #e6f6ff;
}

html {
    font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
}

small {
    font-size: 90%;
    opacity: 0.8;
}

td small {
    font-size: 100%;
    opacity: .8;
    max-height: 45px;
    overflow: hidden;
    display: inline-block;
}

input:focus {
    border-color: #40a9ff;
    border-right-width: 1px!important;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(24,144,255,.2);
}

input:hover {
    border-color: #40a9ff;
    border-right-width: 1px!important;
}

.rxwidth--100 {
    width: 100px;
}

.rxwidth--120 {
    width: 120px;
}

.rxwidth--150 {
    width: 150px;
}

.tableFloatingHeaderOriginal {
    z-index: 100 !important;
    top: 20px !important;
}

.ibreset b {
    font-weight: bold;
}

.ibreset i {
    font-weight: lighter;
    opacity: 0.4;
}

.btn {
    color: #fff;
    background-color: #339f58;
    border: solid 1px #339f58;
    /*text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);*/
    padding: 10px 20px;
    border-radius: 3px;
    line-height: 1.25;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.btn:hover {
    /*color: #fff;
    background-color: #339f58;
    border-color: #339f58;*/
}

.btn--default {
    color: rgba(0,0,0,.65);
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    text-shadow: 0 -1px 0 rgba(0,0,0,.12);
    -webkit-box-shadow: 0 2px 0 rgba(0,0,0,.045);
    box-shadow: 0 2px 0 rgba(0,0,0,.045);
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 3px;
    line-height: 30px;
    cursor: pointer;
}

.btn--findexpand {
    margin-right: 5px;
}

.btn--default:hover {
    color: #40a9ff;
    background-color: #fff;
    border-color: #40a9ff;
}

.betable__btnimport {
    background-color: #fff;
    border-color: #31373e;
    color: #31373e ;
}
.betable__btnadd>i {
    font-size: 20px;
    line-height: 0;
    margin-top: -3px;
}

.betable__btnexport {
    border-radius: 3px;
    border: solid 1px #31373e;
    background-color: #626f7e;
    color: #fff;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.2;
}

.betable__btnimport > i {
    margin-right: 5px;
    font-size: 20px;
    line-height: 0;
    margin-top: -3px;
}

.betable__btnexport > i {
    margin-right: 5px;
    font-size: 20px;
    line-height: 0;
}


.show-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 101;
    background: rgba(0,0,0,.8);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal {
    offset-rotate: 0deg;
    offset-path: path("M 250,100 S -300,500 -700,-200");
}
.modal {
    width: 500px;
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    border-radius: 5px;
}
.modal h2 {
    border-bottom: 1px solid #ccc;
    padding: 20px;
    margin: 0;
    font-size: 19px;
    text-align: center;
}
.modal .content {
    padding: 15px;
}
.modal .content textarea {
    width: 100%;
    min-height: 100px;
}
.modal .actions {
    border-top: 1px solid #ccc;
    background: #eee;
    padding: 35px 15px;
}
.rx-upload_cmnd111 {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0.7;
    z-index: 1111;

}
.clearfix.bg-showfull {
    position: relative;
    width: 1000px;
    height: 1000px;
    z-index: 2222;
}
.clearfix.bg-showfull .fullsize img {
    width: 100%;
    height: 100%;
}
.modal__contact {
    background: white;
    border: 1px solid #ccc;
    transition: 1.1s ease-out;
    box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
    filter: blur(0);
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    border-radius: 5px;
}
.modal__contact-title {
    padding: 15px;
    background-color: #2869b5;
    color: #fff;
    font-size: 18px;
}
.modal__contact .content {
    padding: 15px;
}
.modal__contact .actions {
    border-top: 1px solid #ccc;
    padding: 25px 15px;
}
.modal__contact .content>div {
    padding: 5px;
    border: 1px solid #dddddd;
    border-bottom: none;
    background-color: #f8f8f8;
}
.modal__contact .content>div:last-child {
    background-color: #fff;
    border-bottom: 1px solid #dddddd;
}
.textuppercase {
    text-transform: uppercase;
}
