/* RXEXPENDABLE */
.rxexpand-wrap {
  margin-top: 5px;
  margin-bottom: 25px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}

.rxexpand-toggle {
  position: absolute;
  height: 20px;
  width: 8%;
  left: 46%;
  display: block;
  bottom: -3px;
  background: #fff;
  -webkit-box-shadow: 0 0 10px rgba(0,0,0,.2);
  box-shadow: 0 0 15px rgba(0,0,0,.3);
  border-radius: 3px;
  opacity: 0.7;
}

.rxexpand-toggle:after {
  content: ".....";
  display: block;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 100;
  line-height: 11px;
  color: #999;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.rxexpand-toggle.rx-active:after {
}

/* RXDRAGDROP*/
.rxdragdrop-wrap {
  width: 300px;
  height: 500px;
  overflow-y: scroll;
}
.rxdragdrop-container {
  display: block;
  padding: 13px;
  border: solid 1px #eee;
}
.rxdragdrop-container>div:first-child {
  margin-top: 10px;
}

div.rxdragdrop-placeholder {
  background: rgb(255,240,120);
}

div.rxdragdrop-placeholder:before {
  content: "Drop here";
  color: rgb(225,210,90);
}

.rx-sort-asc {
  position: relative;
}

.rx-sort-asc:after {
  content: '>';
  display: block;
  position: absolute;
  top: 13px;
  right: 10px;
  font-weight: 100;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  font-size: 12px;
  background-color: #dddddd;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  line-height: 0px;
  padding: 8px 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

.rx-sort-desc {
  position: relative;
}

.rx-sort-desc:after {
  content: '>';
  display: block;
  position: absolute;
  top: 13px;
  right: 10px;
  font-weight: 100;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  font-size: 12px;
  background-color: #dddddd;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  line-height: 0px;
  padding: 8px 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 16px;
  height: 16px;
}

/* .admin-product-img-upload {
  width: 112px;
  height: 112px;
  border: solid 1px #eee;
  padding: 3px;
  border-radius: 3px;
} */

.slide__itemgif {
  position: absolute;
  right: 50px;
  top: -40px;
  width: 550px;
  -webkit-transform: rotate(-18deg);
  -ms-transform: rotate(-18deg);
  transform: rotate(289deg);
  z-index: 0;
  opacity: 0.3;
}

.container--relative {
  position: relative;
}

.rx-cat-title {
  text-align: center;
  padding: 10px;
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #2869b5;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

.loader {
  width: 50px;
  height: 50px;
  margin: auto;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0,21,41,.1);
}

.loaders {
  margin: auto;
  display: flex;
  align-items: center;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
