/*
  http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  /* font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;*/
  font-family: 'Roboto', 'Encode Sans', 'Source Sans Pro', 'Roboto Slab', 'Source Serif Pro', sans-serif;
}
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600&amp&display=swap;subset=vietnamese');
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap;");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");

@font-face {
   font-family: Gilroy;
   src: url(fonts/Gilroy-Regular1.ttf);
}

@font-face {
   font-family: Gilroy;
   src: url(fonts/Gilroy-Bold1.ttf);
   font-weight: bold;
}

a {
  color: #242628;
  text-transform: none;
  text-decoration: none;
}
b {
    font-weight: bold;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input {
  width: 100%;
  margin: auto;
  min-height: 42px;
  border-radius: 3px;
  border: 1px solid #C4D8F8;
  padding-left: 10px;
  font-size: 16px;
  color: #242628;
}
.progress-bar:before {
  content: '';
  position: fixed;
  z-index: 999;
  left: 0px;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.3);
  width: 100%;
  height: 100%;
}
.progress-bar {
  height: 7px;
  background-color: rgba(5, 114, 206, 0.2);
  width: 100%;
  overflow: hidden;
  position: fixed;
  z-index: 1000;
  bottom: 0px;
  z-index: 1001;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #63b2ff;
  animation: indeterminateAnimation 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
.loader {
  margin: 10px auto;
  font-size: 5px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(0, 0, 0, 0.1);
  border-right: 1.1em solid #63b2ff;
  border-bottom: 1.1em solid rgba(0, 0, 0, 0.1);
  border-left: 1.1em solid #63b2ff;
  transform: translateZ(0);
  animation: load8 0.5s infinite linear;
  position: fixed;
  bottom: 20px;
  /* background-color: #000; */
  right: 20px;
  display: block;
  z-index: 1000;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
