@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?mk3tvk');
  src:  url('fonts/icomoon.eot?mk3tvk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?mk3tvk') format('truetype'),
    url('fonts/icomoon.woff?mk3tvk') format('woff'),
    url('fonts/icomoon.svg?mk3tvk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icomoon-"], [class*=" icomoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-uniE900:before {
  content: "\e900";
}
.icomoon-uniE901:before {
  content: "\e901";
}
.icomoon-uniE902:before {
  content: "\e902";
}
.icomoon-uniE903:before {
  content: "\e903";
}
.icomoon-uniE904:before {
  content: "\e904";
}
.icomoon-uniE905:before {
  content: "\e905";
}
.icomoon-uniE906:before {
  content: "\e906";
}
.icomoon-uniE907:before {
  content: "\e907";
}
.icomoon-uniE908:before {
  content: "\e908";
}
.icomoon-uniE909:before {
  content: "\e909";
}
.icomoon-uniE90A:before {
  content: "\e90a";
}
.icomoon-uniE90B:before {
  content: "\e90b";
}
.icomoon-uniE90C:before {
  content: "\e90c";
}
.icomoon-uniE90D:before {
  content: "\e90d";
}
.icomoon-uniE90E:before {
  content: "\e90e";
}
.icomoon-uniE90F:before {
  content: "\e90f";
}
.icomoon-uniE910:before {
  content: "\e910";
}
.icomoon-uniE911:before {
  content: "\e911";
}
.icomoon-uniE912:before {
  content: "\e912";
}
.icomoon-uniE913:before {
  content: "\e913";
}
.icomoon-uniE914:before {
  content: "\e914";
}
.icomoon-uniE915:before {
  content: "\e915";
}
.icomoon-uniE916:before {
  content: "\e916";
}
.icomoon-uniE917:before {
  content: "\e917";
}
.icomoon-uniE918:before {
  content: "\e918";
}
.icomoon-uniE919:before {
  content: "\e919";
}
.icomoon-uniE91A:before {
  content: "\e91a";
}
.icomoon-uniE91B:before {
  content: "\e91b";
}
.icomoon-uniE91C:before {
  content: "\e91c";
}
.icomoon-uniE91D:before {
  content: "\e91d";
}
.icomoon-uniE91E:before {
  content: "\e91e";
}
.icomoon-uniE91F:before {
  content: "\e91f";
}
.icomoon-uniE920:before {
  content: "\e920";
}
.icomoon-uniE921:before {
  content: "\e921";
}
.icomoon-uniE922:before {
  content: "\e922";
}
.icomoon-uniE923:before {
  content: "\e923";
}
.icomoon-uniE924:before {
  content: "\e924";
}
.icomoon-uniE925:before {
  content: "\e925";
}
.icomoon-uniE926:before {
  content: "\e926";
}
.icomoon-uniE927:before {
  content: "\e927";
}
.icomoon-uniE928:before {
  content: "\e928";
}
.icomoon-uniE929:before {
  content: "\e929";
}
.icomoon-uniE92A:before {
  content: "\e92a";
}
.icomoon-uniE92B:before {
  content: "\e92b";
}
.icomoon-uniE92C:before {
  content: "\e92c";
}
.icomoon-uniE92D:before {
  content: "\e92d";
}
.icomoon-uniE92E:before {
  content: "\e92e";
}
.icomoon-uniE92F:before {
  content: "\e92f";
}
.icomoon-uniE930:before {
  content: "\e930";
}
.icomoon-uniE931:before {
  content: "\e931";
}
.icomoon-uniE932:before {
  content: "\e932";
}
.icomoon-uniE933:before {
  content: "\e933";
}
.icomoon-uniE934:before {
  content: "\e934";
}
.icomoon-uniE935:before {
  content: "\e935";
}
.icomoon-uniE936:before {
  content: "\e936";
}
.icomoon-uniE937:before {
  content: "\e937";
}
.icomoon-uniE938:before {
  content: "\e938";
}
.icomoon-uniE939:before {
  content: "\e939";
}
.icomoon-uniE93A:before {
  content: "\e93a";
}
.icomoon-uniE93B:before {
  content: "\e93b";
}
.icomoon-uniE93C:before {
  content: "\e93c";
}
.icomoon-uniE93D:before {
  content: "\e93d";
}
.icomoon-uniE93E:before {
  content: "\e93e";
}
.icomoon-uniE93F:before {
  content: "\e93f";
}
.icomoon-uniE940:before {
  content: "\e940";
}
.icomoon-uniE941:before {
  content: "\e941";
}
.icomoon-uniE942:before {
  content: "\e942";
}
.icomoon-ArrowBack:before {
  content: "\e943";
}
.icomoon-ArrowNext:before {
  content: "\e944";
}
.icomoon-Bar:before {
  content: "\e945";
}
.icomoon-Cart:before {
  content: "\e946";
}
.icomoon-Check:before {
  content: "\e947";
}
.icomoon-Close:before {
  content: "\e948";
}
.icomoon-Icon1:before {
  content: "\e949";
}
.icomoon-Icon2:before {
  content: "\e94a";
}
.icomoon-Icon3:before {
  content: "\e94b";
}
.icomoon-Icon4:before {
  content: "\e94c";
}
.icomoon-Facebook:before {
  content: "\e94d";
}
.icomoon-Google:before {
  content: "\e94e";
}
.icomoon-Shopee:before {
  content: "\e94f";
}
.icomoon-Youtube:before {
  content: "\e950";
}
.icomoon-Zalo:before {
  content: "\e951";
}
.icomoon-Star:before {
  content: "\e952";
}
.icomoon-StarFull:before {
  content: "\e953";
}
.icomoon-StarHalf:before {
  content: "\e954";
}
.icomoon-Email:before {
  content: "\e955";
}
.icomoon-FAQ:before {
  content: "\e956";
}
.icomoon-Info:before {
  content: "\e957";
}
.icomoon-Order:before {
  content: "\e958";
}
.icomoon-Return:before {
  content: "\e959";
}
.icomoon-Ship:before {
  content: "\e95a";
}
.icomoon-uniE95B:before {
  content: "\e95b";
}
.icomoon-uniE95C:before {
  content: "\e95c";
}
.icomoon-uniE95D:before {
  content: "\e95d";
}
.icomoon-uniE95E:before {
  content: "\e95e";
}
.icomoon-uniE95F:before {
  content: "\e95f";
}
.icomoon-uniE960:before {
  content: "\e960";
}
.icomoon-uniE961:before {
  content: "\e961";
}
.icomoon-uniE962:before {
  content: "\e962";
}
.icomoon-Delete:before {
  content: "\e963";
}
.icomoon-Left:before {
  content: "\e964";
}
.icomoon-Right:before {
  content: "\e965";
}
.icomoon-Down:before {
  content: "\e966";
}
.icomoon-Up:before {
  content: "\e967";
}
.icomoon-Start:before {
  content: "\e968";
}
.icomoon-End:before {
  content: "\e969";
}
.icomoon-Minus:before {
  content: "\e96a";
}
.icomoon-Plus:before {
  content: "\e96b";
}
.icomoon-Home:before {
  content: "\e96c";
}
.icomoon-Profile:before {
  content: "\e96d";
}
.icomoon-Heart:before {
  content: "\e96e";
}
.icomoon-HeartSolid:before {
  content: "\e96f";
}
.icomoon-Rectangle:before {
  content: "\e970";
}
.icomoon-Search:before {
  content: "\e971";
}
.icomoon-Zoom:before {
  content: "\e972";
}
.icomoon-Write:before {
  content: "\e973";
}
.icomoon-Edit:before {
  content: "\e974";
}
.icomoon-Like:before {
  content: "\e975";
}
.icomoon-phone:before {
  content: "\e976";
}
.icomoon-Reply:before {
  content: "\e977";
}
.icomoon-Send:before {
  content: "\e978";
}
.icomoon-Share:before {
  content: "\e979";
}
.icomoon-Shield:before {
  content: "\e97a";
}