.rxpopup-addnew-cus-- {
    position: relative;
    width: 324px;
    height: 204px;
    z-index: 9999999;
}
.rxpopup-addnew-cus--.printer--all {
    page-break-after: always;
    position: relative;
    z-index: 999999999;
}
/*.avatar_print--new {
    width: 70.5pt;
    height: 82.5pt;
    overflow: hidden;
    position: relative;
    margin-top: 19pt;
    margin-left: 19pt;
    z-index: 9999999;
}*/
.avatar_print--new {
    width: 55.7pt;
    height: 82.5pt;
    overflow: hidden;
    position: absolute;
    margin-top: 18.5pt;
    margin-left: 16.5pt;
    z-index: 9999999;
}
.avatar_print--new img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    z-index: 9999999;
}
.form-header--new {
	display: flex;
	width: 100% !important;
	position: relative;
}
/*.textCenter__print--new {
    padding-left: 6pt;
    padding-top: 46pt;
    margin-right: 12pt;
    max-width: calc(100% - 89.5pt);
}*/
.textCenter__print--new {
    padding-left: 77pt;
    padding-top: 46pt;
    margin-right: 12pt;
}
.text__print--new, .text__print--coquan {
    display: flex;
}
.text__print--coquan1 {
    display: block;
    line-height: 9px;
}
.text__print--coquan1 span {
    float: unset;
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 10px;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--coquan span {
    line-height: 11px !important;
}
.text__print--new span, .text__print--coquan span {
	float: left;
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.4;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--new span:first-child {
    /*font-weight: normal !important;*/
}
.textName___print--new {
    text-transform: uppercase;
}
.text__subname {
    text-transform: capitalize
}
.form-header-qrcode {
	position: absolute;
    top: 9.6pt;
    right: 14.2pt;
    z-index: 9999999;
}
.form-header-qrcode canvas {
    position: relative;
    z-index: 9999999;
}
.rxprint__info--new {
    position: absolute;
    left: 12.6pt;
    top: 108pt;
}
.rxprint__sign--new {
    position: absolute;
    top: 96pt;
    right: 10pt;
    width: 135pt;
}
.adblock--new {
    width: 100%;
    height: 100%;
    position: relative;
}
.adblock__label--new {
    width: 100%;
    height: 100%;
    /*background-image: url(/images/static/background_card.png);
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;*/
}
.text__info {
	font-size: 9px;
    font-weight: normal;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__info span {
	font-weight: bold;
	font-size: 9px;
	/*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__sign {
	text-align: center;
	font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    white-space: nowrap;
    line-height: 1.2;
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.img__sign2 {
    display: flex;
    justify-content: center;
    margin-left: 48px;
    height: 38px;
    font-size: 36px;
}
.img__sign1 {
    display: flex;
    justify-content: center;
    margin-left: 22px;
    height: 38px;
    font-size: 38px;
}
.img__sign {
    display: flex;
    justify-content: center;
    margin-left: 65px;
    height: 42px;
    margin-top: -6pt;
    font-size: 55px;
    transform: rotate(10deg);
    margin-bottom: 7pt;
}
.img__sign img {
    height: 100%;
    width: auto;
    object-fit: contain;
    position: relative;
    z-index: 9999999;
}
.textOrganization___print--new {
    white-space: pre-wrap !important;
}
.textOrganization___print--nowrap {
    white-space: nowrap !important;
}
.textOrganization___print--coquan {
    white-space: nowrap !important;
    font-size: 8px;
}
.form-header-qrcode canvas {
    position: relative;
    z-index: 9999999;
    opacity: 1;
}
.text__print--pre {
    font-size: 9px;
    font-weight: bold;
    /*font-family: 'Times New Roman', sans-serif;*/
    font-family: 'Roboto', sans-serif;
    line-height: 1;
    position: relative;
    z-index: 9999999;
    opacity: 1;
    text-align: justify;
}
.rxprint__dau {

}

@media print{
    .toast-container, .betable.adblock__inner .fixed {
        z-index: 1;
    }
    .progress-bar {
        display: none !important;
        z-index: 1;
    }
}
